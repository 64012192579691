// src/components/healthcare/users/AssignPractitionersTab.tsx

import React, { useEffect, useState } from "react";
import { assignPractitioners, unassignPractitioner, getAssignedPractitioners, fetchPractitioners, Practitioner, getPractitionerTypes, PractitionerType } from "../../../../api/healthcare/practitionerApi";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

interface AssignPractitionersTabProps {
	patientId: number;
}

interface OptionType {
	value: any;
	label: string;
}

const animatedComponents = makeAnimated();

const AssignPractitionersTab: React.FC<AssignPractitionersTabProps> = ({ patientId }) => {
	const [assignedPractitioners, setAssignedPractitioners] = useState<Practitioner[]>([]);
	const [availablePractitioners, setAvailablePractitioners] = useState<Practitioner[]>([]);
	const [selectedPractitioner, setSelectedPractitioner] = useState<OptionType | null>(null);
	const [selectedRole, setSelectedRole] = useState<OptionType[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [pracTypes, setPracTypes] = useState<PractitionerType[]>([]);

	// Fetch assigned and available practitioners on component mount
	useEffect(() => {
		fetchAssignedPractitioners();
		fetchAvailablePractitioners();
		fetchPractitionerTypes();
	}, [patientId]);

	const fetchAssignedPractitioners = async () => {
		try {
			const data = await getAssignedPractitioners(patientId);
			setAssignedPractitioners(data);
		} catch (err: any) {
			setError('Failed to fetch assigned practitioners.');
			console.error(err);
		}
	};

	const fetchAvailablePractitioners = async () => {
		const user = JSON.parse(sessionStorage.getItem('user') || "{}")
		try {
			const data = await fetchPractitioners(1, 30, user.company, "");
			setAvailablePractitioners(data.data);
		} catch (err: any) {
			setError('Failed to fetch available practitioners.');
			console.error(err);
		}
	};

	const fetchPractitionerTypes = async () => {
		try {
			const result = await getPractitionerTypes();
			setPracTypes(result);
		} catch (err) {
			console.error(err);
		}
	}

	const handleAssign = async () => {
		if (!selectedPractitioner || !selectedRole) {
			setError('Please select both a practitioner and a role.');
			return;
		}

		setLoading(true);
		setError(null);
		try {
			let rolesAssigned: string[] = [];
			for (let i = 0; i < selectedRole.length; i++){
				rolesAssigned.push(selectedRole[i].value)
			}

			await assignPractitioners(patientId, selectedPractitioner.value, rolesAssigned);

			// Refresh the lists
			await fetchAssignedPractitioners();
			await fetchAvailablePractitioners();
			setSelectedPractitioner(null);
			setSelectedRole([]);
		} catch (err: any) {
			setError(err.message || 'Failed to assign practitioners.');
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleUnassign = async (practitionerId: number) => {
		setLoading(true);
		setError(null);

		try {
			await unassignPractitioner(patientId, practitionerId);
			await fetchAssignedPractitioners();
			await fetchAvailablePractitioners();
		} catch (err: any) {
			setError(err.message || 'Failed to unassign practitioner.');
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	// Prepare options for react-select
	const practitionerOptions: OptionType[] = availablePractitioners.map(practitioner => ({
		value: practitioner.practitioner.id,
		label: `${practitioner.first_name} ${practitioner.last_name}`,
	}));

	const practitionerTypesOptions: OptionType[] = pracTypes.map(type => ({
		value: type.title,
		label: type.title,
	}));

	return (
		<div className="p-6">

			{/* Assigned Practitioners Table */}
			<div className="mb-6">
				<h2 className="text-xl font-semibold mb-4">Assigned Practitioners</h2>
				{assignedPractitioners.length === 0 ? (
					<p>No practitioners assigned.</p>
				) : (
					<table className="min-w-full bg-white border">
						<thead>
							<tr>
								<th className="py-2 px-4 border">Name</th>
								<th className="py-2 px-4 border">Role</th>
								<th className="py-2 px-4 border">Actions</th>
							</tr>
						</thead>
						<tbody>
							{assignedPractitioners.map((practitioner) => (
								<tr key={practitioner.id}>
									<td className="py-2 px-4 border">{practitioner.first_name} {practitioner.last_name}</td>
									<td className="py-2 px-4 border">{practitioner.pivot?.role}</td>
									<td className="py-2 px-4 border">
										{practitioner.id > 0 && ( // Only allow unassigning existing practitioners
											<button
												onClick={() => handleUnassign(practitioner.practitioner.id)}
												className="text-red-500 hover:text-red-700"
											>
												Unassign
											</button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>

			{/* Assign Practitioners */}
			<div className="mb-6">
				<h2 className="text-xl font-semibold mb-4">Assign New Practitioners</h2>
				<div className="flex items-center space-x-4">
					{/* Dropdown to select existing practitioners */}
					<div className="flex-1">
						<Select
							components={animatedComponents}
							
							options={practitionerOptions}
							value={selectedPractitioner}
							onChange={(selected) => setSelectedPractitioner(selected as OptionType)}
							placeholder="Select practitioners..."
						/>
					</div>
					{/* Dropdown for the role of the practitioner with the patient */}
					<div className="flex-1">
						<Select
							components={animatedComponents}
							options={practitionerTypesOptions}
							isMulti
							value={selectedRole}
							onChange={(selected) => setSelectedRole(selected as OptionType[])}
							placeholder="Select role..."
						/>
					</div>

					{/* Assign Button */}
					<div>
						<button
							onClick={handleAssign}
							disabled={loading || !selectedPractitioner || !selectedRole}
							className={`px-4 py-2 bg-blue-500 text-white rounded ${loading || !selectedPractitioner || !selectedRole
									? 'opacity-50 cursor-not-allowed'
									: 'hover:bg-blue-600'
								}`}
						>
							{loading ? 'Assigning...' : 'Assign'}
						</button>
					</div>
				</div>
				{/* Display Errors */}
				{error && <div className="text-red-500 mb-4">{error}</div>}
			</div>
		</div>
	);
};

export default AssignPractitionersTab;
