import React from "react";
import { User } from "../../../../api/admin/userApi";
import { Company } from "../../../../api/admin/companyApi";

interface CompanyTabProps {
    updatedUser: User;
    setUpdatedUser: React.Dispatch<React.SetStateAction<User>>;
    companies: Company[];
}

const CompanyTab: React.FC<CompanyTabProps> = ({ updatedUser, setUpdatedUser, companies }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setUpdatedUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <div className="grid grid-cols-1">
            {/* Column */}
            <div className="p-6">
                <div>
                    <label htmlFor="company" className="block text-sm">
                        Company
                    </label>
                    <select
                        id="company"
                        name="company"
                        value={updatedUser.company || ""}
                        onChange={handleChange}
                        className="w-full p-2 border rounded"
                        required
                    >
                        <option value="" disabled>
                            Select Company
                        </option>
                        {companies.map((company) => (
                            <option key={company.name} value={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="company_code" className="block text-sm">
                        Company Code
                    </label>
                    <select
                        id="company_code"
                        name="company_code"
                        value={updatedUser.company_code || ""}
                        onChange={handleChange}
                        className="w-full p-2 border rounded"
                        required
                    >
                        <option value="" disabled>
                            Select Company Code
                        </option>
                        {companies.map((company) => (
                            <option key={company.name} value={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="panel_key" className="block text-sm">
                        Panel Key
                    </label>
                    <input
                        type="text"
                        id="panel_key"
                        name="panel_key"
                        value={updatedUser.panel_key || ""}
                        onChange={handleChange}
                        placeholder="Enter panel key"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="reagents" className="block text-sm">
                        Reagents
                    </label>
                    <input
                        type="text"
                        id="reagents"
                        name="reagents"
                        value={updatedUser.reagents || ""}
                        onChange={handleChange}
                        placeholder="Enter reagents"
                        className="w-full p-2 border rounded"
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyTab;
