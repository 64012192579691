import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; 
import Layout from "../../components/Layout";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import { fetchBatch, fetchBatches, Batch } from '../../api/labiq/labiqApi'; 

//Import the batch page components
import BatchTable from '../../components/labiq/batchManagement/BatchTable';
import ActionButtons from '../../components/labiq/batchManagement/BatchManagementButtons'; 
import NewBatchModal from '../../components/labiq/batchManagement/BatchModal';
import Pagination from "../../components/Pagination";

const BatchManagementPage: React.FC = () => {
	const [batches, setBatches] = useState<Batch[]>([]);
	const [totalBatches, setTotalBatches] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [sortBy, setSortBy] = useState<string>('batch_id');
	const [sortOrder, setSortOrder] = useState<string>('desc');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isShowAll, setIsShowAll] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedBatches, setSelectedBatches] = useState<number[]>([]);
	const [error, setError] = useState<string | null>(null);

	const { id } = useParams<{ id: string }>();

	const BATCHES_PER_PAGE = 30;

	// Fetch batch data and handle modal open logic
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				if (id) {
					const batchId = parseInt(id, 10);
					if (!isNaN(batchId)) {
						const batch = await fetchBatch(batchId);
						setBatches([batch]);
						handleEditClick(batchId); // Open the modal with that batch ID
					}
				} else {
					await fetchBatchData(false);
				}
			} catch (err) {
				console.error("Error fetching data:", err);
				setError("Failed to load batches.");
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [id, currentPage, sortBy, sortOrder]); // Remove 'batches' from the dependency array to prevent re-render loops

	const fetchBatchData = async (isShowAll: boolean) => {

		setError(null);
		try {
			let company: string | "";
			if (isShowAll) {
				company = "";
				setIsShowAll(true);
			} else {
				const userInfo = JSON.parse(sessionStorage.getItem('user') || '{}');
				company = userInfo.company;
				setIsShowAll(false);
			}
			
			const { data, total } = await fetchBatches(currentPage, BATCHES_PER_PAGE, company, sortBy, sortOrder);
			setBatches(data);
			setTotalBatches(total);
		} catch (err) {
			console.error("Error fetching batches:", err);
			setError("Failed to load batches.");
		} 
	};

	// Handle sorting of the page data
	const handleSort = (column: string) => {
		if (column === sortBy) {
			setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortBy(column);
			setSortOrder('asc');
		}
	};

	// Page functions
	const handleEditClick = (batchID: number) => {
		setSelectedBatches([batchID]);
		setIsModalOpen(true);
	};

	const handleNewBatchClick = () => {
		setSelectedBatches([0]);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedBatches([]);
		setIsModalOpen(false);
	};

	const handleFormSubmission = () => {
		// Update user in the list
		fetchBatchData(isShowAll);
	};

	return (
		<Layout>
			<div className="p-6">
				<h1 className="text-2xl font-bold mb-4">Batch Tracking</h1>
				{/* Action Buttons */}
				<ActionButtons selectedBatches={selectedBatches} refreshBatches={fetchBatchData} isShowAll={isShowAll} editClick={handleEditClick} newBatch={handleNewBatchClick} />
				{/* Batch Table */}
				{isLoading ? (
					<LoadingSpinner size={60} color="#354396" />
				) : error ? (
					<p className="text-red-500">{error}</p>
				) : (
					<>
						<BatchTable batches={batches} selectedBatches={selectedBatches} setSelectedBatches={setSelectedBatches} onSort={handleSort} sortBy={sortBy} onEditBatch={handleEditClick}/>
						<Pagination currentPage={currentPage} totalItems={totalBatches} itemsPerPage={BATCHES_PER_PAGE} onPageChange={setCurrentPage} />
					</>
				)}

				{/* Show the modal to edit or create a batch */}
				{isModalOpen && selectedBatches.length === 1 && (
					<NewBatchModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						batch_id={selectedBatches[0] === 0 ? 0 : selectedBatches[0]}
						onFormSubmission={handleFormSubmission}
					/>
				)}

			</div>
		</Layout>
	);
};

export default BatchManagementPage;
