// ActionButtons.tsx
import React from "react";
import { deleteBatch } from "../../../api/labiq/labiqApi";
import axios from "axios";

interface BatchManagementButtonsProps {
	selectedBatches: number[];
	refreshBatches: (isShowAll: boolean) => void;
	isShowAll: boolean;
	editClick: (batch_id: number) => void;
	newBatch: () => void;
}

const BatchManagementButtons: React.FC<BatchManagementButtonsProps> = ({ selectedBatches, refreshBatches, isShowAll, editClick, newBatch }) => {
	const handleDeleteBatch = async () => {
		try{
			for (let i = 0; i < selectedBatches.length; i++) {
				
				await deleteBatch(selectedBatches[i]);
			}
			refreshBatches(isShowAll);
		} catch (error) {
			console.error("Error deleting batch:", error);
			if (axios.isAxiosError(error)) {
				alert(`Error deleting batch: ${(error as any).response?.data?.error || (error as any).message}`);
			} else {
				alert(`Error deleting batch: ${error}`);
			}
		}

	};

	const handleViewHistory = () => {
		// Logic to view batch history
	};

	const handleExportToExcel = () => {
		// Logic to export table data to Excel
	};

	return (
		<div className="flex space-x-2 mb-4">
			<button onClick={newBatch} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
				New Batch
			</button>
			<button
				onClick={() => editClick(selectedBatches[0])}
				disabled={selectedBatches.length !== 1}
				className={`${selectedBatches.length !== 1 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-green-500 text-white hover:bg-green-600"} px-4 py-2 rounded`}
			>
				Edit Batch
			</button>
			<button onClick={handleDeleteBatch} disabled={selectedBatches.length === 0} className={`${selectedBatches.length === 0 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-red-500 text-white hover:bg-red-600"} px-4 py-2 rounded`}>
				Delete Batch
			</button>
			
			{/* These buttons currently dont have a function */}
			{/* <button onClick={handleViewHistory} disabled={selectedBatches.length !== 1} className={`${selectedBatches.length !== 1 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-purple-500 text-white hover:bg-purple-600"} px-4 py-2 rounded`}>
				View History
			</button>

			<button onClick={handleExportToExcel} className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600">
				Export to Excel
			</button> */}

		</div>
	);
};

export default BatchManagementButtons;
