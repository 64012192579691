import React from "react";
import { useReactTable, ColumnDef, getCoreRowModel, getSortedRowModel, SortingState } from "@tanstack/react-table";
import { Batch } from "../../../api/labiq/labiqApi";

interface BatchTableProps {
	batches: Batch[];
	selectedBatches: number[];
	setSelectedBatches: React.Dispatch<React.SetStateAction<number[]>>;
	onSort: (column: string) => void;
	sortBy: string;
	onEditBatch: (batchId: number) => void;
}

const BatchTable: React.FC<BatchTableProps> = ({ batches, selectedBatches, setSelectedBatches, onSort, sortBy, onEditBatch }) => {

	
	const columns = React.useMemo<ColumnDef<Batch>[]>(
		() => [
			{
				id: "select",
				header: ({ table }) => (
					<input
						type="checkbox"
						checked={selectedBatches.length === batches.length}
						onChange={() => {
							if (selectedBatches.length === batches.length) {
								setSelectedBatches([]);
							} else {
								setSelectedBatches(batches.map((batch) => batch.batch_id));
							}
						}}
						aria-label="Select all batches"
					/>
				),
				cell: ({ row }) => (
					<input
						type="checkbox"
						checked={selectedBatches.includes(row.original.batch_id)}
						onChange={() => handleSelectBatch(row.original.batch_id)}
						aria-label={`Select batch ${row.original.batch_id}`}
					/>
				),
			},
			{ accessorKey: "batch_client_id", header: "Client Batch ID" },
			{ accessorKey: "batch_delivery_option", header: "Delivery Option" },
			{ accessorKey: "batch_create_date", header: "Create Date" },
			{ accessorKey: "batch_submission", header: "Submitted" },
			{ accessorKey: "batch_user_id", header: "User ID" },
			{ accessorKey: "batch_company", header: "Company" },
			{ accessorKey: "batch_sample_count", header: "Sample Count" },
		],
		[selectedBatches, batches, setSelectedBatches]
	);

	const [sorting, setSorting] = React.useState<SortingState>([]);

	const table = useReactTable({
		data: batches,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		state: { sorting },
		onSortingChange: setSorting,
	});

	const handleSelectBatch = (batch_id: number) => {
		if (selectedBatches.includes(batch_id)) {
			setSelectedBatches(selectedBatches.filter((id) => id !== batch_id));
		} else {
			setSelectedBatches([...selectedBatches, batch_id]);
		}
	};

	return (
		<table className="min-w-full bg-white border">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th
								key={header.id}
								className="py-2 px-3 border-b text-left cursor-pointer"
								onClick={() => onSort(header.column.id)}
							>
								<>
									{header.column.columnDef.header as React.ReactNode}
									{sortBy === header.column.id ? (
										<span className="ml-2">🔽</span>
									) : null}
								</>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id} 
					className="hover:bg-gray-100 cursor-pointer"
					onClick={() => {
						if (selectedBatches.length === 0) {
							onEditBatch(row.original.batch_id);
						}
					}}>
						{row.getVisibleCells().map((cell) => (
							<td key={cell.id} className="py-2 px-3 border-b">
								{cell.column.id === "select" ? (
									<input type="checkbox" checked={selectedBatches.includes(row.original.batch_id)} onChange={() => handleSelectBatch(row.original.batch_id)} aria-label={`Select batch ${row.original.batch_id}`} />
								) : (
									cell.column.id === "batch_submission" ? (
										row.original.batch_submission === 0 ? "Draft" : "Submitted"
									) : cell.column.id === "batch_delivery_option" ? (
										row.original.batch_delivery_option === 0 ? "Internal" : "Courier"
									) : (
										cell.renderValue() as React.ReactNode
									)
								)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default BatchTable;
