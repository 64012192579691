///////////////////////////////////////////////////////////////////////////////////////////////////////// Kit management
import axios from "axios";
import {Product} from "./productApi";

const API_BASE_URL = process.env.REACT_APP_COMMERCE_SERVER;

export interface Kit{
	'kit_id': number,
    'stock_id': number,
    'kit_code': string,
    'status': string,
    'assigned_to_type': string,
    'assigned_to_id': number,
    'assigned_at': Date,

}


//This endpoint specificaly gets the kits from the system
export const fetchKits = async (page: number = 1, perPage: number = 20, company: string, search?: string): Promise<{ data: Product[]; total: number }> => {
	const response = await axios.get(`${API_BASE_URL}/kits/products`, {
        params: {
            page,
            per_page: perPage,
			company,
            ...(search ? { search } : {}),
            
        },
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return {
        data: response.data.data,
        total: response.data.total,
    };
};

export const fetchAvailableKitIds = async (companyId: number) => {
	const response = await axios.get(`${API_BASE_URL}/kits/available/${companyId}`,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        }
    );
    return response.data.data;
}

export const assignBatchToCompany = async (totalToAssign: number, companyId: string, stockId: number, newStockId: number) => {
    const response = await axios.post(`${API_BASE_URL}/kits/assign`,
        {
			'stock_id': stockId,
            'total_kits_to_assign': totalToAssign,
            'assignee_id': companyId,
			'new_stock_id': newStockId,
        },
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        }
    );
    return response.data;
}

export const unassignBatchFromCompany = async (totalToUnassign: number, companyId: string, stockId: number) => {
    const response = await axios.post(`${API_BASE_URL}/kits/unassign`,
        {
			'stock_id': stockId,
            'total_kits_to_unassign': totalToUnassign,
            'assignee_id': companyId,
        },
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        }
    );
    return response.data;
}

export const assignKitToPatient = async (kitCode: string, assignee_id: number): Promise<Kit> =>{
	const response = await axios.post(`${API_BASE_URL}/kits/${kitCode}/assign`,
        {
			'assignee_type': "user",
            'assignee_id': assignee_id,
        },
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
            },
        }
    );
    return response.data;
}