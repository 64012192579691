// src/api/stockAssignmentApi.ts

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_COMMERCE_SERVER;

export interface StockAssignment {
    assignment_id: number;
    stock_id: number;
    assignee_type: string;
    assignee_id: string; 
    quantity_assigned: number;
    assigned_at?: string;
    status?: string;
    notes?: string;
    created_at?: string;
    updated_at?: string;
}

export const fetchStockAssignments = async (page: number = 1, perPage: number = 20, filters?: { stock_id?: number; assignee_type?: string; assignee_id?: string; status?: string }): Promise<{ data: StockAssignment[]; total: number }> => {
    const response = await axios.get(`${API_BASE_URL}/stock-assignments`, {
        params: {
            page,
            per_page: perPage,
            ...filters,
        },
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return {
        data: response.data.data,
        total: response.data.total,
    };
};

export const fetchStockAssignment = async (assignment_id: number): Promise<StockAssignment> => {
    const response = await axios.get(`${API_BASE_URL}/stock-assignments/${assignment_id}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};
export const createStockAssignment = async (assignmentData: Partial<StockAssignment>, company: string) => {
	const response = await axios.post(`${API_BASE_URL}/stock-assignments`, {
		...assignmentData,
		company_name: company,
	}, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return {
        newStock: response.data.newStock,
        assignment: response.data.assignment,
    };
};


export const updateStockAssignment = async (assignment_id: number, assignmentData: StockAssignment): Promise<StockAssignment> => {
    const response = await axios.put(`${API_BASE_URL}/stock-assignments/${assignment_id}`, assignmentData, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const deleteStockAssignment = async (assignment_id: number): Promise<void> => {
    await axios.delete(`${API_BASE_URL}/stock-assignments/${assignment_id}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
};
