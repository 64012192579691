import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import Pagination from "../../components/Pagination";
import LoadingSpinner from '../../components/icons/LoadingSpinner';

import {Company, fetchCompanies} from "../../api/admin/companyApi"


import CompanyTable from "../../components/admin/company/CompanyTable";
import EditCompanyModal from "../../components/admin/company/EditCompanyModal";
import ActionButtons from "../../components/admin/company/ActionButtons";


const CompanyManagementPage: React.FC = () => {
	const [companies, setCompanies] = useState<Company[]>([]);
	const [totalCompanies, setTotalCompanies] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const companiePerPage = 30;

	//load the page and set the state
    useEffect(() => {
		loadCompanies();
    }, []);

	const loadCompanies = async () => {
		setIsLoading(true);
		try{
			const response = await fetchCompanies(currentPage, companiePerPage, "");
			if (response){
				setCompanies(response.data);
				setTotalCompanies(response.total)
				
			}
		} catch (err){
			console.error(err);
		}
		setIsLoading(false);
	}

	const searchCompanies = async ( searchTerm: string) => {
		try{
			const response = await fetchCompanies(currentPage, companiePerPage, searchTerm);
			if (response){
				setCompanies(response.data);
				setTotalCompanies(response.total)
				
			}
		} catch (err){
			console.error(err);
		}
	}

	const handleCreateClick = async () => {
		const createEmptyCompany = (): Company => ({
			id: 0,
			name: "",
			address: "",
			email: "",
			phone: "",
			created_at: "",
			updated_at: ""
		});
		setSelectedCompany(createEmptyCompany());
		setIsModalOpen(true);
	}

	const handleEditClick = async (company: Company) => {
		setSelectedCompany(company);
		setIsModalOpen(true);
	}

	const hendleCloseModal = async () => {
		setSelectedCompany(null);
		setIsModalOpen(false);
	}

	const handleCompanyUpdate = async () => {
        await loadCompanies();
    };

	
    return (
        <Layout>
            <h1 className="text-2xl font-bold mb-4">Company Management</h1>
            {isLoading ? (
                <LoadingSpinner size={60} color="#354396" />
            ) : (
                <>
					<ActionButtons createCompany={handleCreateClick} searchCompanies={searchCompanies} reloadCompanies={loadCompanies} />
                    <CompanyTable companies={companies} onEditClick={handleEditClick} />
                    <Pagination currentPage={currentPage} totalItems={totalCompanies} itemsPerPage={companiePerPage} onPageChange={setCurrentPage} />
                </>
            )}
			{isModalOpen && (<EditCompanyModal isOpen={isModalOpen} onClose={hendleCloseModal} company={selectedCompany} onCompanyUpdated={handleCompanyUpdate}/>)}
        </Layout>
    );
};

export default CompanyManagementPage;
