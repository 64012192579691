import React from "react";
import { Sample } from "../../../../../api/labiq/labiqApi";

interface SidebarProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    sample: Sample;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, sample }) => {
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    return (
        <div className="bg-gray-800 text-white w-64 min-h-full flex flex-col justify-between p-4">
            {/* Profile Section */}
            <div className="flex flex-col items-center mb-6">

                <span className="mt-2 font-medium">
                    {sample.sampleID}
                </span>
            </div>

            {/* Navigation Links */}
            <div className="flex-1">
                <button
                    onClick={() => setActiveTab("Sample")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Sample" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Sample Info</span>
                </button>

				<button
                    onClick={() => setActiveTab("Lab")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Lab" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Lab Info</span>
                </button>

				<button
                    onClick={() => setActiveTab("Other")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Other" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Other Info</span>
                </button>

				<button
                    onClick={() => setActiveTab("Gene.Codes")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Gene.Codes" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Gene.Codes Access</span>
                </button>

				
            </div>

            
        </div>
    );
};

export default Sidebar;
