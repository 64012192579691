import React, { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import UsersIcon from "./icons/mainSidebar/UsersIcon";
import ApplicationsIcon from "./icons/mainSidebar/ApplicationsIcon";
import SubscriptionsIcon from "./icons/mainSidebar/SubscriptionsIcon";
import PlansIcon from "./icons/mainSidebar/PlansIcon";
import SettingsIcon from "./icons/mainSidebar/SettingsIcon";
import DashboardIcon from "./icons/mainSidebar/DashboardIcon";
import LogoutIcon from "./icons/mainSidebar/LogoutIcon";
import RoleManagementIcon from "./icons/mainSidebar/RoleManagementIcon";
import PatientManagementIcon from "./icons/healthcare/PatientsIcon";
import GeneticSampleTrackingIcon from "./icons/labiq/SampleManagementIcon";
import BatchManagementIcon from "./icons/labiq/BatchManagementIcon";
import OrdersIcon from "./icons/inventory/OrdersIcon";
import SuppliersIcon from "./icons/inventory/SuppliersIcon";
import ProductsIcon from "./icons/inventory/ProductsIcon";
import CompanyIcon from "./icons/mainSidebar/CompanyIcon";
import HealthcareProfessionalIcon from './icons/healthcare/HealthcareProfessionals';

const Sidebar: React.FC = () => {
    const [dropdowns, setDropdowns] = useState<{ [key: string]: boolean }>({});
    const location = useLocation();

    // Retrieve the user object
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    const toggleDropdown = (section: string) => {
        setDropdowns((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    // Map each section to its routes
    const sectionRoutes: { [key: string]: string[] } = {
        adminManagement: ["/users", "/roles", "/applications", "/subscriptions", "/plans", "/companies"],
        healthcare: ["/healthcare/patients", "/healthcare/professionals"],
        labiq: ["/labiq/sample-tracking", "/labiq/batch-management"],
        inventory: ["/inventory/products", "/inventory/ordering", "/inventory/suppliers", "/inventory/kits", "/inventory/stock/"],
    };

    // Determine if a dropdown is open
    const isDropdownOpen = (section: string) => {
        return dropdowns[section] || sectionRoutes[section]?.some((route) => location.pathname.startsWith(route));
    };

    return (
        <div className="bg-gray-800 text-white w-64 min-h-screen flex flex-col justify-between">
            <div className="mt-4">
                {/* Profile Section */}
                <div className="flex flex-col items-center px-4 py-4">
                    <Link to="/profile">
                        <img className="w-32 h-32 rounded-full object-cover" src={user.profile_picture || "/assets/images/default-profile.png"} alt={`${user.first_name} ${user.last_name}`} />
                        <span className="mt-2 text-white font-medium">
                            {user.first_name} {user.last_name}
                        </span>
                    </Link>
                </div>
                <hr className="my-4 border-gray-700" />

                <NavItem to="/" icon={<DashboardIcon className="w-6 h-6" />} label="Dashboard" />

                {/* Admin Management */}
                {user.roles?.some((role: any) => role.name === "User Management") && (
                    <div className="mt-4">
                        <h2 className="flex items-center text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider cursor-pointer hover:text-white" onClick={() => toggleDropdown("adminManagement")}>
                            Admin Management
                            <svg className={`w-4 h-4 ml-2 transition-transform duration-200 ${isDropdownOpen("adminManagement") ? "transform rotate-90" : ""}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </h2>
                        <div className={`ml-4 overflow-hidden transition-all duration-200 ease-in-out ${isDropdownOpen("adminManagement") ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
                            <NavItem to="/users" icon={<UsersIcon className="w-6 h-6" />} label="User Management" />
							<NavItem to="/companies" icon={<CompanyIcon className="w-6 h-6" />} label="Company Management" />
                            <NavItem to="/roles" icon={<RoleManagementIcon className="w-6 h-6" />} label="Role Management" />
                            <NavItem to="/applications" icon={<ApplicationsIcon className="w-6 h-6" />} label="Application Management" />
                            <NavItem to="/subscriptions" icon={<SubscriptionsIcon className="w-6 h-6" />} label="Subscription Management" />
                            <NavItem to="/plans" icon={<PlansIcon className="w-6 h-6" />} label="Plan Management" />
                        </div>
                    </div>
                )}

                {/* Healthcare */}
                {user.roles?.some((role: any) => role.name === "Practitioner") && (
                    <div className="mt-4">
                        <h2 className="flex items-center text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider cursor-pointer hover:text-white" onClick={() => toggleDropdown("healthcare")}>
                            Healthcare
                            <svg className={`w-4 h-4 ml-2 transition-transform duration-200 ${isDropdownOpen("healthcare") ? "transform rotate-90" : ""}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </h2>
                        <div className={`ml-4 overflow-hidden transition-all duration-200 ease-in-out ${isDropdownOpen("healthcare") ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
                            <NavItem to="/healthcare/patients" icon={<PatientManagementIcon className="w-6 h-6" />} label="Patients" />
							<NavItem to="/healthcare/professionals" icon={<HealthcareProfessionalIcon className="w-6 h-6" />} label="Healthcare Professionals" />

                        </div>
                    </div>
                )}

                {/* LabIQ */}
                {user.roles?.some((role: any) => role.name === "Lab Management" || role.name === "Practitioner") && (
                    <div className="mt-4">
                        <h2 className="flex items-center text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider cursor-pointer hover:text-white" onClick={() => toggleDropdown("labiq")}>
                            LabIQ
                            <svg className={`w-4 h-4 ml-2 transition-transform duration-200 ${isDropdownOpen("labiq") ? "transform rotate-90" : ""}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </h2>
                        <div className={`ml-4 overflow-hidden transition-all duration-200 ease-in-out ${isDropdownOpen("labiq") ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
                            <NavItem to="/labiq/sample-tracking" icon={<GeneticSampleTrackingIcon className="w-6 h-6" />} label="Sample Tracking" />
                            <NavItem to="/labiq/batch-management" icon={<BatchManagementIcon className="w-6 h-6" />} label="Batch Management" />
                        </div>
                    </div>
                )}

                {/* Inventory */}
                {user.roles?.some((role: any) => role.name === "Inventory Management") && (
                    <div className="mt-4">
                        <h2 className="flex items-center text-gray-400 ml-4 text-xs uppercase font-bold tracking-wider cursor-pointer hover:text-white" onClick={() => toggleDropdown("inventory")}>
                            Inventory
                            <svg className={`w-4 h-4 ml-2 transition-transform duration-200 ${isDropdownOpen("inventory") ? "transform rotate-90" : ""}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </h2>
                        <div className={`ml-4 overflow-hidden transition-all duration-200 ease-in-out ${isDropdownOpen("inventory") ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
							<NavItem to="/inventory/products" icon={<ProductsIcon className="w-6 h-6" />} label="Products" />
							{(user.company === "Mygene" || user.company === "DNAIQ") && (
								<NavItem to="/inventory/kits" icon={<ProductsIcon className="w-6 h-6" />} label="Kits" />
							)}
							<NavItem to="/inventory/ordering" icon={<OrdersIcon className="w-6 h-6" />} label="Ordering" />
                            <NavItem to="/inventory/suppliers" icon={<SuppliersIcon className="w-6 h-6" />} label="Suppliers" />
                        </div>
                    </div>
                )}
            </div>

            <div className="mb-4">
                <NavItem to="/settings" icon={<SettingsIcon className="w-6 h-6" />} label="Settings" />
                <NavItem to="/logout" icon={<LogoutIcon className="w-6 h-6" />} label="Logout" />
            </div>
        </div>
    );
};

interface NavItemProps {
    to: string;
    icon: React.ReactNode;
    label: string;
}

const NavItem: React.FC<NavItemProps> = ({ to, icon, label }) => {
    return (
        <NavLink to={to} className={({ isActive }) => `flex items-center px-4 py-2 transition-colors duration-200 ${isActive ? "bg-gray-700" : "hover:bg-gray-700"}`}>
            {icon}
            <span className="ml-3">{label}</span>
        </NavLink>
    );
};

export default Sidebar;
