//Updating for the new kit system in the inventory
//System will now get all available kits in the inventory for your company, mygene will get all unassigned

import React, { useState, useEffect } from "react";
import { fetchReagentList, createSample, Sample, SampleFormData } from "../../../../api/labiq/labiqApi";
import { assignSampleToPatient, Patient, sampleCountOnPatient } from "../../../../api/healthcare/patientApi";

//inventory imports
import { fetchAvailableKitIds, Kit, assignKitToPatient } from "../../../../api/inventory/kitApi";
import { Company, fetchSingleCompanyByName } from "../../../../api/admin/companyApi";
import {createStockAssignment, StockAssignment} from "../../../../api/inventory/stockAssignmentApi";

interface CreateSampleSimpleModalProps {
    isOpen: boolean;
    onClose: () => void;
    setSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
	patient: Patient;
}

const CreateSampleSimpleModal: React.FC<CreateSampleSimpleModalProps> = ({ isOpen, onClose, setSamples, patient }) => {
    const [formData, setFormData] = useState<SampleFormData>({
        client_sampleID: "",
        analysis_requested: [],
        delivery: "",
        tracking_number: "",
        delivery_vendor: "",
    });

    const [reagentList, setReagentList] = useState<string[]>([]);
	const [kitsAvailable, setKitsAvailable]= useState<Kit[]>([])

    useEffect(() => {
        const fetchReagents = async () => {
            try {
                const reagents = await fetchReagentList();
                setReagentList(reagents);
            } catch (err) {
                console.error("Error fetching reagents:", err);
            }
        };
		//Fetch available kits
		const fetchAvailableKits = async () => {
			//get the company id of the logged in user
			let user = JSON.parse(sessionStorage.getItem("user") || "{}");
			let company:Company = await fetchSingleCompanyByName(user.company)

			let ids:Kit[] = [];
			ids = await fetchAvailableKitIds(company.id);
			setKitsAvailable(ids);
		}

        if (isOpen) {
            fetchReagents();
			fetchAvailableKits();
        }
    }, [isOpen]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (reagent: string, checked: boolean) => {
        setFormData((prev) => {
            const analysisRequested = prev.analysis_requested ? [...prev.analysis_requested] : [];
            if (checked) {
                if (!analysisRequested.includes(reagent)) {
                    analysisRequested.push(reagent);
                }
            } else {
                const index = analysisRequested.indexOf(reagent);
                if (index > -1) {
                    analysisRequested.splice(index, 1);
                }
            }
            return { ...prev, analysis_requested: analysisRequested };
        });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        const user = JSON.parse(sessionStorage.getItem("user") || "{}");
        const sampleData: SampleFormData = {
            client_sampleID: formData.client_sampleID,
            analysis_requested: formData.analysis_requested,
            delivery: formData.delivery,
            client: user.company,
            ...(formData.delivery === "courier" && {
                tracking_number: formData.tracking_number,
                delivery_vendor: formData.delivery_vendor,
            }),
        };

        try {
            const newSample = await createSample(sampleData);
            setSamples((prevSamples) => [...prevSamples, newSample]);

            // Assign sample to patient
            assignSampleToPatient(patient.email, sampleData.client_sampleID || "");

			//Increment the count of sample on the patient
			sampleCountOnPatient(patient.email, "gene", "increment");

			//assign the kit to the patient in the inventory system and mark it as used
			let kit = await assignKitToPatient(sampleData.client_sampleID || "", patient.id)

			//Mark the kit as assigned in inventory
			let stockAssignment:StockAssignment = {
				stock_id: kit.stock_id,
				assignment_id: 0,
    			assignee_type: "user",
    			assignee_id: patient.id.toString(),
    			quantity_assigned: 1,
			}
			createStockAssignment(stockAssignment, patient.company);

            onClose();
        } catch (err) {
            console.error("Error creating sample:", err);
            // Handle errors appropriately
        }
    };

    if (!isOpen) return null;

    return (

		
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">Assign Kit To Patient</h2>
                {/* CLient Sample Id
					Now has the ability to chose from a kit in inventory or to create a new kit id not in the inventory
				*/}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-1">Kit ID</label>
					<select name="client_sampleID" id="kitIds" value={formData.client_sampleID} onChange={handleSelectChange} required className="w-full border px-3 py-2 rounded">
						<option value="0">Select from an available kit in your inventory</option>
						{kitsAvailable.map((kit) => (
								<option value={kit.kit_code} id={kit.kit_code}>{kit.kit_code}</option>
						))}
					</select>
					<input type="text" name="client_sampleID" value={formData.client_sampleID || ""} onChange={handleInputChange} required className="w-full border px-3 py-2 rounded mt-3" placeholder="Or enter a new kit id"/>
				</div>
                {/* Test Types */}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-1">Test Types</label>
                    {reagentList.map((reagent) => (
                        <div key={reagent} className="flex items-center mb-2">
                            <input type="checkbox" id={reagent} value={reagent} checked={formData.analysis_requested?.includes(reagent) || false} onChange={(e) => handleCheckboxChange(reagent, e.target.checked)} className="mr-2" />
                            <label htmlFor={reagent} className="text-gray-700">
                                {reagent}
                            </label>
                        </div>
                    ))}
                </div>
                {/* Delivery Method */}
                <div className="mb-4">
                    <label className="block text-gray-700 font-medium mb-1">Delivery Method</label>
                    <select name="delivery" value={formData.delivery || ""} onChange={handleSelectChange} required className="w-full border px-3 py-2 rounded">
                        <option value="">Select an option</option>
                        <option value="courier">Courier</option>
                        <option value="internal">Internal</option>
                    </select>
                </div>
                {/* Conditional Fields for delivery if courier is select4ed */}
                {formData.delivery === "courier" && (
                    <>
                        {/* Tracking Number */}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-1">Tracking Number</label>
                            <input type="text" name="tracking_number" value={formData.tracking_number || ""} onChange={handleInputChange} required className="w-full border px-3 py-2 rounded" />
                        </div>
                        {/* Delivery Vendor */}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-medium mb-1">Delivery Vendor</label>
                            <select name="delivery_vendor" value={formData.delivery_vendor || ""} onChange={handleSelectChange} required className="w-full border px-3 py-2 rounded">
                                <option value="">Select an option</option>
                                <option value="FedEX">FedEX</option>
                                <option value="UPS">UPS</option>
                            </select>
                        </div>
                    </>
                )}
                {/* Buttons */}
                <div className="flex justify-end">
                    <button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
                        Cancel
                    </button>
                    <button type="button" onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateSampleSimpleModal;
