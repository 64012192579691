import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { Practitioner, updatePractitioner, createPractitioner, defaultPractitionerInfo } from "../../../api/healthcare/practitionerApi";

import Sidebar from "./modalComponents/Sidebar";
import ProfileTab from "./modalComponents/ProfileTab";
import PractitionerInfoTab from "./modalComponents/PractitionerInfoTab";
import AvailabilityTab from "./modalComponents/AvailabilityTab";
import Educationtab from "./modalComponents/EducationTab";


interface EditPatientModalProps {
	isOpen: boolean;
	onClose: () => void;
	practitioner: Practitioner;
	onPractitionerUpdate: () => void;
}

const EditPatientModal: React.FC<EditPatientModalProps> = ({ isOpen, onClose, practitioner, onPractitionerUpdate }) => {
	const [updatedPractitioner, setUpdatedPractitioner] = useState<Practitioner>({ ...practitioner });
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<any[]>([])
	const [activeTab, setActiveTab] = useState("Personal");


	useEffect(() => {
		setUpdatedPractitioner({
			...practitioner,
			practitioner: practitioner.practitioner || defaultPractitionerInfo,
		});

		if (practitioner.company === "") {
			const userCompany = JSON.parse(sessionStorage.getItem("user") || "{}").company;
			setUpdatedPractitioner((prevPractitioner) => ({
				...prevPractitioner,
				company: userCompany,
				company_code: userCompany,
			}));
		}
	}, [practitioner]);

	// Update patient information
	const updatePatientInfo = async () => {
		setIsSubmitting(true);
		try {
			if (!updatedPractitioner.first_name || !updatedPractitioner.last_name || !updatedPractitioner.email || !updatedPractitioner.username) {
				setErrorMessages(["First name, last name, email, and username are required."]);
				setIsSubmitting(false);
				return;
			}
			if(updatedPractitioner.id !== 0){
				await updatePractitioner(practitioner.id, updatedPractitioner);

			}else{
				await createPractitioner(updatedPractitioner);
			}

			onPractitionerUpdate();
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-center justify-center min-h-screen px-4">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black opacity-60" />
					</Transition.Child>

					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="bg-white rounded z-20 w-[80vw] h-[80vh] flex">
							{/* Sidebar for NavButtons */}
							<div >
								<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} practitioner={updatedPractitioner} />
							</div>

							{/* Main Content */}
							<div className="pl-4 flex flex-col p-6" style={{width : '-webkit-fill-available'}}>

								<div className="flex-1 overflow-y-auto">
									{/* Render tabs based on activeTab */}
									{activeTab === "Personal" && (
										<ProfileTab updatedPractitioner={updatedPractitioner} setUpdatedPractitioner={setUpdatedPractitioner} />
									)}
									{activeTab === "Practitioner" && (
										<PractitionerInfoTab updatedPractitioner={updatedPractitioner} setUpdatedPractitioner={setUpdatedPractitioner}  />
									)}
									{activeTab === "Availability" && (
										<AvailabilityTab updatedPractitioner={updatedPractitioner} setUpdatedPractitioner={setUpdatedPractitioner}  />
									)}
									{activeTab === "Education" && (
										<Educationtab updatedPractitioner={updatedPractitioner} setUpdatedPractitioner={setUpdatedPractitioner}  />
									)}

								</div>

								{/* Display errors to the user */}
								{errorMessages.length > 0 && (
									<div className="error-container">
										{errorMessages.map((msg, idx) => (
											<div key={idx} className="text-red-500">
												{msg}
											</div>
										))}
									</div>
								)}
								<div className="flex justify-end space-x-2 mt-6">

									<button
										type="button"
										onClick={onClose}
										className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
									>
										Cancel
									</button>
									<button
										onClick={updatePatientInfo}
										disabled={isSubmitting}
										className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
											}`}
									>
										{isSubmitting ? (practitioner.id !== 0 ? "Saving..." : "Creating...") : "Save Changes"}
									</button>
								</div>
							</div>
						</div>

					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default EditPatientModal;
