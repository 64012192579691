import React from "react";
import { Company } from "../../../../api/admin/companyApi";

interface DetailsTabProps {
	company: Company;
	setUpdatedCompany: React.Dispatch<React.SetStateAction<Company>>;
}

const DetailsTab: React.FC<DetailsTabProps> = ({ company, setUpdatedCompany }) => {

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedCompany((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <div className="grid grid-cols-1">
            {/* Column 1 */}
            <div className="p-6">
                <div>
                    <label htmlFor="name" className="block text-sm">
                        Name
                    </label>
                    <input type="text" id="name" name="name" value={company.name || ""} onChange={handleChange} placeholder="Enter company name" className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label htmlFor="address" className="block text-sm">
                        Address
                    </label>
                    <input type="text" id="address" name="address" value={company.address || ""} onChange={handleChange} placeholder="Enter address" className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm">
                        Email
                    </label>
                    <input type="email" id="email" name="email" value={company.email || ""} onChange={handleChange} placeholder="Enter email" className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label htmlFor="phone" className="block text-sm">
                        Phone
                    </label>
                    <input type="text" id="phone" name="phone" value={company.phone || ""} onChange={handleChange} placeholder="Enter phone number" className="w-full p-2 border rounded" />
                </div>
                
            </div>              
                

            
        </div>
    );
};

export default DetailsTab;
