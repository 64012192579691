// src/components/icons/BloodWithNumber.tsx

import React from 'react';
import { FaTint } from 'react-icons/fa'; // Font Awesome blood drop icon

// Define the props interface
interface BloodDropIconWithNumberProps {
	count: number;
}

// Define the component with typed props
const BloodDropIconWithNumber: React.FC<BloodDropIconWithNumberProps> = ({ count }) => {
	return (
		<div className="relative inline-block">
			{count > 0 ? (
				<div>
					<FaTint className="text-blue-500 w-6 h-6" aria-label="Blood Samples" />

					<span className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
						{count}
					</span>
				</div>
			) : (
				<FaTint className="text-grey-500 w-6 h-6" aria-label="Blood Samples" />

			)}
		</div>
	);
};

export default BloodDropIconWithNumber;
