import React from 'react';
import { User } from "../../api/admin/userApi";

interface UserProfileCompanyProps {
    user: User;
    isEditing: boolean;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileCompany: React.FC<UserProfileCompanyProps> = ({ user, isEditing, setUserData  }) => {
    return (
        <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">Company Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <p className="text-sm text-gray-600">Company</p>
                    <p className="text-base text-gray-800">{user.company}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-600">Company Code</p>
                    <p className="text-base text-gray-800">{user.company_code}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-600">Panel Key</p>
                    <p className="text-base text-gray-800">{user.panel_key}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-600">Reagents</p>
                    <p className="text-base text-gray-800">{user.reagents}</p>
                </div>
            </div>
        </div>
    );
};

export default UserProfileCompany;




// const UserProfileCompany: React.FC<UserProfileCompanyProps> = ({ user, isEditing, setUserData }) => {
//     const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         const { name, value } = e.target;
//         setUserData((prevUser) => ({ ...prevUser, [name]: value }));
//     };

//     return (
//         <div>
//             <h2 className="text-xl font-medium text-gray-700 mb-4">Company Information</h2>
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                 {['company', 'company_code', 'panel_key', 'reagents'].map((field) => (
//                     <div key={field}>
//                         <p className="text-sm text-gray-600">{field.replace('_', ' ').toUpperCase()}</p>
//                         {isEditing ? (
//                             <input
//                                 type="text"
//                                 name={field}
//                                 value={user[field as keyof User] as string}
//                                 onChange={handleChange}
//                                 className="w-full border-b border-gray-300 focus:outline-none"
//                             />
//                         ) : (
//                             <p className="text-base text-gray-800">
//                                 {user[field as keyof User] as string}
//                             </p>
//                         )}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default UserProfileCompany;