import React, { ChangeEvent, useState, useEffect } from "react";
import { Sample, Batch } from "../../../../api/labiq/labiqApi";
import { Company } from "../../../../api/admin/companyApi";



interface BatchDetailsProps {
	formData: Partial<Batch>;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
	setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
	setFormData: React.Dispatch<React.SetStateAction<Partial<Batch>>>;
	companies: Company[];
}

const BatchDetails: React.FC<BatchDetailsProps> = ({ formData, handleChange, handleSelectChange, setErrorMessages, setFormData, companies }) => {


	return (
		<div className="grid grid-cols-1">
			{/* col 1 */}
			<div className="p-6">
				<div className="p-2">
					<label htmlFor="batch_client_id" className="block text-sm">
						Batch ID
					</label>
					<input
						type="text"
						id="batch_client_id"
						name="batch_client_id"
						value={formData.batch_client_id || ""}
						onChange={handleChange}
						placeholder="Enter batch ID"
						className="w-full p-2 border rounded"
						required
					/>
				</div>

				{/* If the companies list length is 0 then the client is set by default as the user is not logged in as dnaiq or mygene company */}
				{companies.length !== 0 && (
					<div className="p-2">
						<label htmlFor="batch_company" className="block text-sm">
							Client
						</label>
						<select id="batch_company" name="batch_company" value={formData.batch_company || ""} onChange={handleSelectChange} className="w-full p-2 border rounded" required>
							<option value="" disabled>
								Select Company
							</option>
							{companies.map((company) => (
								<option key={company.name} value={company.name}>
									{company.name}
								</option>
							))}
						</select>
					</div>
				)}

				<div className="p-2">
					<label htmlFor="batch_delivery_option" className="block text-sm">
						Delivery Options
					</label>
					<select id="batch_delivery_option" name="batch_delivery_option" value={formData.batch_delivery_option?.toString() || ""} onChange={handleSelectChange} className="w-full p-2 border rounded" required>
						<option value="" disabled>
							Select Delivery Option
						</option>
						<option value="0">Internal</option>
						<option value="1">Courier</option>
					</select>
				</div>
				{formData.batch_delivery_option?.toString() === "1" && (
					<div>
						<div className="p-2">
							<label htmlFor="batch_tracking_no" className="block text-sm">
								Tracking Number
							</label>
							<input
								type="text"
								id="batch_tracking_no"
								name="batch_tracking_no"
								value={formData.batch_tracking_no || ""}
								onChange={handleChange}
								placeholder="Enter tracking number"
								className="w-full p-2 border rounded"
								required
							/>
						</div>
						<div className="p-2">
							<label htmlFor="batch_vendor" className="block text-sm">
								Delivery Vendor
							</label>
							<select id="batch_vendor" name="batch_vendor" value={formData.batch_vendor || ""} onChange={handleSelectChange} className="w-full p-2 border rounded">
								<option value="" disabled>
									Select Delivery Vendor
								</option>
								<option value="fedex">FedEx</option>
								<option value="ups">UPS</option>
								<option value="auspost">Australia Post</option>
								<option value="tnt">TNT</option>
							</select>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default BatchDetails;
