import React from "react";
import { User } from "../../../../api/admin/userApi";

interface PersonalTabProps {
    updatedUser: User;
    setUpdatedUser: React.Dispatch<React.SetStateAction<User>>;
}

const PersonalTab: React.FC<PersonalTabProps> = ({ updatedUser, setUpdatedUser }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <div className="grid grid-cols-3">
            {/* Column 1 */}
            <div className="p-6">
                <div>
                    <label htmlFor="first_name" className="block text-sm">
                        First Name
                    </label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={updatedUser.first_name || ""}
                        onChange={handleChange}
                        placeholder="Enter first name"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="last_name" className="block text-sm">
                        Last Name
                    </label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={updatedUser.last_name || ""}
                        onChange={handleChange}
                        placeholder="Enter last name"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={updatedUser.email || ""}
                        onChange={handleChange}
                        placeholder="Enter email"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="username" className="block text-sm">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={updatedUser.username || ""}
                        onChange={handleChange}
                        placeholder="Enter username"
                        className="w-full p-2 border rounded"
                    />
                </div>
                {updatedUser.id === 0 && (
                    <div>
                        <div>
                            <label htmlFor="password" className="block text-sm">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={updatedUser.password || ""}
                                onChange={handleChange}
                                placeholder="Enter password"
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label htmlFor="password_confirmation" className="block text-sm">
                                Password Confirmation
                            </label>
                            <input
                                type="password"
                                id="password_confirmation"
                                name="password_confirmation"
                                value={updatedUser.password_confirmation || ""}
                                onChange={handleChange}
                                placeholder="Confirm password"
                                className="w-full p-2 border rounded"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Column 2 */}
            <div className="p-6">
                <div>
                    <label htmlFor="address" className="block text-sm">
                        Address
                    </label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={updatedUser.address || ""}
                        onChange={handleChange}
                        placeholder="Enter address"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="city" className="block text-sm">
                        City
                    </label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={updatedUser.city || ""}
                        onChange={handleChange}
                        placeholder="Enter city"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="state" className="block text-sm">
                        State
                    </label>
                    <input
                        type="text"
                        id="state"
                        name="state"
                        value={updatedUser.state || ""}
                        onChange={handleChange}
                        placeholder="Enter state"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="country" className="block text-sm">
                        Country
                    </label>
                    <input
                        type="text"
                        id="country"
                        name="country"
                        value={updatedUser.country || ""}
                        onChange={handleChange}
                        placeholder="Enter country"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="postal_code" className="block text-sm">
                        Post Code
                    </label>
                    <input
                        type="text"
                        id="postal_code"
                        name="postal_code"
                        value={updatedUser.postal_code || ""}
                        onChange={handleChange}
                        placeholder="Enter post code"
                        className="w-full p-2 border rounded"
                    />
                </div>
            </div>

            {/* Column 3 */}
            <div className="p-6">
                <div>
                    <label htmlFor="phone" className="block text-sm">
                        Phone Number
                    </label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={updatedUser.phone || ""}
                        onChange={handleChange}
                        placeholder="Enter phone number"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="date_of_birth" className="block text-sm">
                        Date of Birth
                    </label>
                    <input
                        type="date"
                        id="date_of_birth"
                        name="date_of_birth"
                        value={
                            updatedUser.date_of_birth instanceof Date
                                ? updatedUser.date_of_birth.toISOString().substring(0, 10)
                                : updatedUser.date_of_birth || ""
                        }
                        onChange={handleChange}
                        placeholder="Enter date of birth"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label htmlFor="gender" className="block text-sm">
                        Gender
                    </label>

                    <input
                        type="text"
                        id="gender"
                        name="gender"
                        value={updatedUser.gender || ""}
                        onChange={handleChange}
                        placeholder="Enter gender"
                        className="w-full p-2 border rounded"
                    />
                </div>
{/* Sex */}
				<div>
                    <label htmlFor="sex" className="block text-sm">
                        Sex
                    </label>

                    <input
                        type="text"
                        id="sex"
                        name="sex"
                        value={updatedUser.sex || ""}
                        onChange={handleChange}
                        placeholder="Enter sex (male, Female)"
                        className="w-full p-2 border rounded"
                    />
                </div>
				
            </div>
        </div>
    );
};

export default PersonalTab;
