import React, { ReactNode, useMemo } from 'react';
import { useReactTable, ColumnDef, getCoreRowModel, SortingState } from '@tanstack/react-table';
import { Patient } from '../../../api/healthcare/patientApi';
import HelixIconWithNumber from '../../icons/healthcare/HelixWithNumber';
import BloodDropIconWithNumber from '../../icons/healthcare/BloodWithNumber';

interface PatientTableProps {
	patients: Patient[];
	onEditClick: (patient: Patient) => void;
	sorting: SortingState;
	setSorting: (updater: SortingState | ((old: SortingState) => SortingState)) => void;
}

const PatientTable: React.FC<PatientTableProps> = ({ patients, onEditClick, sorting, setSorting }) => {
	const columns = useMemo<ColumnDef<Patient>[]>(
		() => [
			{
				accessorKey: 'first_name',
				header: 'First Name',
				cell: info => info.getValue(),
			},
			{
				accessorKey: 'last_name',
				header: 'Last Name',
				cell: info => info.getValue(),
			},
			{
				accessorKey: 'phone',
				header: 'Phone',
				cell: info => info.getValue(),
			},
			{
				accessorKey: 'email',
				header: 'Email',
				cell: info => info.getValue(),
			},
			{
				accessorKey: 'total_gene_samples',
				header: 'Gene Samples',
				cell: info => <HelixIconWithNumber count={info.getValue<number>()} />,
			},
			{
				accessorKey: 'total_blood_samples',
				header: 'Blood Samples',
				cell: info => <BloodDropIconWithNumber count={info.getValue<number>()} />,
			},
			{
				accessorKey: 'is_active',
				header: 'Is Active',
				cell: info =>
					info.getValue<boolean>()
						? <span className="text-green-500 font-bold">✔</span>
						: <span className="text-red-500 font-bold">✖</span>,
			},
		],
		[]
	);

	const table = useReactTable({
		data: patients || [],
		columns,
		state: { sorting },
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		// We are now doing manual sorting on the server:
		manualSorting: true,
	});

	return (
		<div className="overflow-x-auto">
			<table className="min-w-full bg-white border">
				<thead className="border-b">
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id} className="text-left">
							{headerGroup.headers.map(header => {
								const isSortable = header.column.getCanSort();
								const sortingState = header.column.getIsSorted();
								return (
									<th
										key={header.id}
										className="py-2 px-4 border-b cursor-pointer select-none"
										onClick={isSortable ? () => header.column.toggleSorting() : undefined}
									>
										<div className="flex items-center">
											{header.column.columnDef.header as React.ReactNode}
											{sortingState === 'asc' && <span className="ml-2">🔼</span>}
											{sortingState === 'desc' && <span className="ml-2">🔽</span>}
											{!sortingState && isSortable && <span className="ml-2 text-gray-400">⇅</span>}
										</div>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => {
						const patient = row.original;
						return (
							<tr
								key={row.id}
								className="text-left hover:bg-gray-100 cursor-pointer"
								onClick={() => onEditClick(patient)}
							>
								{row.getVisibleCells().map(cell => {
									if (cell.column.id === "total_gene_samples") {
										return (
											<td key={cell.id} className="py-2 px-4 border-b">
												<HelixIconWithNumber count={row.original.total_gene_samples} />
											</td>
										);
									} else if (cell.column.id === "total_blood_samples") {
										return (
											<td key={cell.id} className="py-2 px-4 border-b">
												<BloodDropIconWithNumber count={row.original.total_blood_samples} />
											</td>
										);
									} else if (cell.column.id === "is_active") {
										return (
											<td key={cell.id} className="py-2 px-4 border-b">
												{row.original.is_active
													? <span className="text-green-500 font-bold">✔</span>
													: <span className="text-red-500 font-bold">✖</span>}
											</td>
										);
									} else {
										return (
											<td key={cell.id} className="py-2 px-4 border-b">
												{cell.renderValue() as ReactNode}
											</td>
										);
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default PatientTable;
