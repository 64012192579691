/**
 * @file This file contains the user API functions.
 * @module api/userApi
 */


import axios from "axios";

//fixed a dumb error here, why i went out of the folder to go back in i dont know... 
import { Role } from "./rolesApi";

const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;

export interface User {
    id: number;
    uuid: string;
    username: string;
    email: string;
    password: string;
    password_confirmation: string;
    first_name: string;
    last_name: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
    profile_picture: string;
    date_of_birth: Date;
    gender: string;
	sex: string;
    preferences: string;
    is_active: boolean;
    created_at: Date;
    updated_at: Date;
    company: string;
    panel_key: string;
    company_code: string;
    reagents: string;
    roles: Role[];
}


//Admin user management routes
export const fetchUsers = async (
    page: number,
    perPage: number,
	search?: string,
	role?: string
): Promise<{
    data: User[];
    total: number;
}> => {
    const response = await axios.get(`${API_BASE_URL}/admin/users`, {
        params: {
            page,
            per_page: perPage,
			...(search ? { search } : {}),
			...(role ? { role } : {}),
        },
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return {
        data: response.data.data,
        total: response.data.pagination.total_items,
    };
};

export const fetchSingleUserByUuid = async (
	uuid: string
): Promise<{
	data: User;
}> => {
	const response = await axios.get(`${API_BASE_URL}/admin/usersSimpleUuid/${uuid}`, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const updateUser = async (userId: number, userData: Partial<User>) => {
    const response = await axios.put(`${API_BASE_URL}/admin/users/${userId}`, userData, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const activateUser = async (userId: number) => {
    const response = await axios.put(`${API_BASE_URL}/admin/users/${userId}/activate`,{}, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const deactivateUser = async (userId: number) => {
    const response = await axios.delete(`${API_BASE_URL}/admin/users/${userId}`, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};

export const assignUserRole = async (userId: number, role: string) => {
	const response = await axios.post(`${API_BASE_URL}/admin/users/${userId}/assign-role`, { role }, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const removeUserRole = async (userId: number, role: string) => {
	const response = await axios.post(`${API_BASE_URL}/admin/users/${userId}/remove-role`, { role }, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const createUser = async (userData: Partial<User>) => {
	const response = await axios.post(`${API_BASE_URL}/admin/users`, userData, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};

export const logout = async () => {
	const response = await axios.post(`${API_BASE_URL}/logout`, {}, {
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
		},
	});
	return response.data;
};



//Profile management routes for a user
export const updateProfile = async (userData: User) => {
    const response = await axios.put(`${API_BASE_URL}/user`, userData, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiKey")}`,
        },
    });
    return response.data;
};