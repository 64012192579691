import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { createSample, updateSample, Sample, SampleFormData } from "../../../api/labiq/labiqApi";
import { fetchSinglePatientSimple, fetchSamplesPatient, assignSampleToPatient, removeSampleFromPatient, Patient } from "../../../api/healthcare/patientApi";
import { fetchCompanies, Company } from "../../../api/admin/companyApi";

// Import modal components
import Sidebar from "./sampleModalComponents/Sidebar";
import SampleDetails from "./sampleModalComponents/SampleDetails";
import SampleLab from "./sampleModalComponents/SampleLab";
import SampleOther from "./sampleModalComponents/SampleOther";
import SamplePatienttab from "./sampleModalComponents/SamplePatientTab";

// Define the interface for formData

interface SampleModalProps {
	isOpen: boolean;
	onClose: () => void;
	sample: Sample;
	onFormSubmission: () => void;
}

const SampleModal: React.FC<SampleModalProps> = ({ isOpen, onClose, sample, onFormSubmission }) => {
	const [formData, setFormData] = useState<SampleFormData>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<any[]>([])
	const [activeTab, setActiveTab] = useState("Details");

	// User data for patient assignment
	const [users, setUsers] = useState<Patient[]>([]);
	const [linkedUser, setLinkedUser] = useState<number>(0);
	const [linkedUserEmail, setLinkedUserEmail] = useState<string>("");

	const [companies, setCompanies] = useState<Company[]>([]);

	// Initialize formData when modal opens
	useEffect(() => {
		const getCompanies = async () => {
			try {
				const data = await fetchCompanies(1, 100, "");
				setCompanies(data.data);
			} catch (error:unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages); // State to store an array of error messages
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); // Fallback to general error message
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			}
		};
		if (isOpen) {
			const { sampleID: _, ...sampleOAData } = sample.sampleOA || {};

			//MOved the user company handling up a component level to help solve conflicts in the system
			if(sample.client === ""){

				//check the logged in users company and either set it by default or get the list
				const userCompany = JSON.parse(sessionStorage.getItem("user") || "{}").company;
				if (userCompany === "Mygene" || userCompany === "DNAIQ") {
					getCompanies().then(() => {
						setFormData((prev) => ({ ...prev, client: userCompany }));
					});
				} else {
					setFormData((prev) => ({ ...prev, client: userCompany }));
				}
			}else{
				setFormData((prev) => ({ ...prev, client: sample.client }));
			}
			setFormData((prev) => ({
				...prev, id: sample.id,
				sampleID: sample.sampleID,
				...sampleOAData,
			}));

		}
	}, [isOpen, sample]);


	// Load patients
	const getPatients = async () => {
		setActiveTab("Patient");
		try {
			const clientSampleID = formData.client_sampleID || "";
			const samplesPatient = await fetchSamplesPatient(clientSampleID);

			if (samplesPatient.length > 0) {
				const patient = await fetchSinglePatientSimple(samplesPatient[0].user_email);

				const patientData = Array.isArray(patient.data) ? patient.data : [patient.data];
				setUsers(patientData);
				setLinkedUser(patientData[0].id);
				setLinkedUserEmail(patientData[0].email);
			} else {
				setUsers([]);
				setLinkedUser(0);
				setLinkedUserEmail("");
			}
		} catch (error:unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages); // State to store an array of error messages
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); // Fallback to general error message
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			
			setUsers([]);
		}
	};

	const removeUserFromSample = async () => {
		try {
			await removeSampleFromPatient(linkedUserEmail, formData.client_sampleID || "");
			setLinkedUser(0);
			setLinkedUserEmail("");
			getPatients();
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		}
	};

	// Event handlers
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = e.target;
		const val = type === "checkbox" ? checked : value;
		setFormData((prev) => ({ ...prev, [name]: val }));
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Form submission
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);
		if(!formData.client){
			setErrorMessages(["THere is no client selected"]);
			return;
		}
		try {
			if (sample.id !== 0) {
				// Update existing sample
				await updateSample(sample.id, formData);
			} else {
				// Create new sample
				await createSample(formData);
			}

			// Assign sample to patient if applicable
			if (linkedUser !== 0 && formData.client_sampleID) {
				await assignSampleToPatient(linkedUserEmail, formData.client_sampleID);
			}

			onFormSubmission();
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-center justify-center min-h-screen px-4">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black opacity-60" />
					</Transition.Child>

					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="bg-white rounded z-20 w-[80vw] h-[80vh] flex">
							{/* Sidebar for NavButtons */}
							<div >
								<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} sample={sample} />
							</div>
							<form onSubmit={handleSubmit} className="pl-4 flex flex-col p-6" style={{ width: '-webkit-fill-available' }}>
								<div className="flex-1 overflow-y-auto">
									{/* Active Tab Content */}
									{activeTab === "Details" && <SampleDetails formData={formData} handleChange={handleChange} handleSelectChange={handleSelectChange} setErrorMessages={setErrorMessages} setFormData={setFormData} companies={companies}/>}

									{activeTab === "Lab" && <SampleLab formData={formData} handleChange={handleChange} handleSelectChange={handleSelectChange} />}

									{activeTab === "Other" && <SampleOther formData={formData} handleChange={handleChange} handleTextAreaChange={handleTextAreaChange} />}

									{JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Practitioner") && activeTab === "Patient" && (
										<SamplePatienttab users={users} linkedUser={linkedUser} setLinkedUser={setLinkedUser} setLinkedUserEmail={setLinkedUserEmail} removeUserFromSample={removeUserFromSample} isSubmitting={isSubmitting} />
									)}
								</div>

								{/* Display errors to the user */}
								{errorMessages.length > 0 && (
									<div className="error-container">
										{errorMessages.map((msg, idx) => (
											<div key={idx} className="text-red-500">
												{msg}
											</div>
										))}
									</div>
								)}

								<div className="flex justify-end space-x-2 mt-6">
									<button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
										Cancel
									</button>
									<button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
										{isSubmitting ? (sample.id !== 0 ? "Saving..." : "Creating...") : sample.id !== 0 ? "Save Changes" : "Create Sample"}
									</button>
								</div>
							</form>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default SampleModal;
