import React from "react";
import { User } from "../../api/admin/userApi";

interface UserProfileDetailsProps {
    user: User;
    isEditing: boolean;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileDetails: React.FC<UserProfileDetailsProps> = ({ user, isEditing, setUserData }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserData((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevUser) => ({
            ...prevUser,
            [name]: value ? new Date(value) : null,
        }));
    };

    return (
        <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">Personal Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <p className="text-sm text-gray-600">Username</p>
                    {isEditing ? <input type="text" name="username" value={user.username} onChange={handleChange} className="w-full border-b border-gray-300 focus:outline-none" /> : <p className="text-base text-gray-800">{user.username}</p>}
                </div>
                <div>
                    <p className="text-sm text-gray-600">Date of Birth</p>
                    {isEditing ? (
                        <input type="date" name="date_of_birth" value={user.date_of_birth ? new Date(user.date_of_birth).toISOString().split("T")[0] : ""} onChange={handleDateChange} className="w-full border-b border-gray-300 focus:outline-none" />
                    ) : (
                        <p className="text-base text-gray-800">{user.date_of_birth ? new Date(user.date_of_birth).toLocaleDateString() : "Not provided"}</p>
                    )}
                </div>
                <div>
                    <p className="text-sm text-gray-600">Gender</p>
                    {isEditing ? (
                        <select name="gender" value={user.gender} onChange={handleChange} className="w-full border-b border-gray-300 focus:outline-none">
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    ) : (
                        <p className="text-base text-gray-800">{user.gender || "Not provided"}</p>
                    )}
                </div>
                
            </div>
        </div>
    );
};

export default UserProfileDetails;
