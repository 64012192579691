import React, { useState, useEffect } from "react";
import axios from "axios";
import { Patient } from "../../../../api/healthcare/patientApi";
import { listUserFiles, downloadFile, deleteFile, FileMetadata } from "../../../../api/fileServiceApi";
import UploadFileModal from "./UploadFileModal"; 
import LoadingSpinner from '../../../../components/icons/LoadingSpinner';


interface FilesTabProps {
	patient: Patient;
	setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
}

const FilesTab: React.FC<FilesTabProps> = ({
	patient,
	setErrorMessages,
}) => {
	const [files, setFiles] = useState<FileMetadata[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

	useEffect(() => {
		const fetchFiles = async () => {
			setLoading(true);
			try {
				const response = await listUserFiles(patient.id);
				setFiles(response);
			} catch (error:unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages);
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); 
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} finally {
				setLoading(false);
			}
		};

		fetchFiles();
	}, [patient.id, setErrorMessages]);

	const handleOpenModal = () => {
		setShowUploadModal(true);
	};

	const handleCloseModal = () => {
		setShowUploadModal(false);
	};

	const handleFileUploaded = async (newFile: FileMetadata) => {
		const response = await listUserFiles(patient.id);
		setFiles(response);
		handleCloseModal();
	};

	const handleDeleteFile = async (fileId: string) => {
		try {
			await deleteFile(fileId, patient.id);
			const response = await listUserFiles(patient.id);
			setFiles(response);
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		}
	}

	const handleDownloadFile = async (fileId: string) => {
		try {
			await downloadFile(patient.id, fileId);
			

		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		}
	};

	const formatFileSize = (bytes: number) => {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return '0 Bytes';
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		const fileSize = (bytes / Math.pow(1024, i)).toFixed(2);
		return `${fileSize} ${sizes[i]}` as string;
	}

	return (
		<div className="grid grid-cols-1 relative p-4">
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-xl font-bold">Patient Files</h2>
				<button
					onClick={handleOpenModal}
					className="bg-blue-500 text-white px-4 py-2 rounded"
				>
					Upload File
				</button>
			</div>

			{loading ? (
				
				<LoadingSpinner size={60} color="#354396" />
			) : files  ? (
					<table className="min-w-full bg-white">
						<thead>
							<tr className='text-left'>
								<th className="py-2 px-4 border-b">File Name</th>
								<th className="py-2 px-4 border-b">Type</th>
								<th className="py-2 px-4 border-b">Date</th>
								<th className="py-2 px-4 border-b">Size</th>
								<th className="py-2 px-4 border-b">Actions</th>

							</tr>
						</thead>
						<tbody>
							{files.map((file) => (
								<tr
									key={patient.id}
									className="text-left cursor-pointer hover:bg-gray-100"
								// onClick={() => onEditClick(patient)}
								>
									<td className="py-2 px-4 border-b">{file.file_name}</td>
									<td className="py-2 px-4 border-b">{file.file_type}</td>
									<td className="py-2 px-4 border-b">{file.created_at instanceof Date ? file.created_at.toISOString().substring(0, 10) : file.created_at || ""}</td>
									<td className="py-2 px-4 border-b">{formatFileSize(file.file_size) }</td>
									<td className="py-2 px-4 border-b"><button
										onClick={() => handleDownloadFile(file.id)}
										className="bg-green-500 text-white px-3 py-1 rounded mr-2"
									>
										Download
									</button>
										<button
											onClick={() => handleDeleteFile(file.id)}
											className="bg-red-500 text-white px-3 py-1 rounded mr-2"
										>
											Delete
										</button></td>

								</tr>
							))}
						</tbody>
					</table>
			) : (
				<p>No files found for this patient.</p>
			)}

			{showUploadModal && (
				<UploadFileModal
					patientId={patient.id}
					onClose={handleCloseModal}
					onFileUploaded={handleFileUploaded}
				/>
			)}
		</div>
	);
};

export default FilesTab;
