import React from "react";
import { Company } from "../../../../api/admin/companyApi";

interface SidebarProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    company: Company;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, company }) => {
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    return (
        <div className="bg-gray-800 text-white w-64 min-h-full flex flex-col justify-between p-4">
            {/* Profile Section */}
            <div className="flex flex-col items-center mb-6">
                {/* 
				 This will be the logo of the company
				<img
                    className="w-50 h-50 rounded-full object-cover"
                    src={patient.profile_picture || "/assets/images/default-profile.png"}
                    alt={`${patient.first_name} ${patient.last_name}`}
                /> */}
                <span className="mt-2 font-medium">
                    {company.name}
                </span>
            </div>

            {/* Navigation Links */}
            <div className="flex-1">
                <button
                    onClick={() => setActiveTab("Details")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Details" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Company Info</span>
                </button>

				<button
                    onClick={() => setActiveTab("Staff")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Staff" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Staff</span>
                </button>

            </div>

            
        </div>
    );
};

export default Sidebar;
