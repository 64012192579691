import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import PatientTable from "../../components/healthcare/patient/PatientTable";
import { fetchPatients, Patient } from "../../api/healthcare/patientApi";
import Pagination from "../../components/Pagination";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import EditPatientModal from "../../components/healthcare/patient/EditPatientModal";
import PatientButtons from "../../components/healthcare/patient/PatientButtons";
import { SortingState } from "@tanstack/react-table";

const PatientManagementPage: React.FC = () => {
	const [patients, setPatients] = useState<Patient[]>([]);
	const [totalPatients, setTotalPatients] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const [sorting, setSorting] = useState<SortingState>([]);

	const PATIENTS_PER_PAGE = 30;

	useEffect(() => {
		loadPatients();
	}, [currentPage, sorting]); // Re-run when currentPage or sorting changes

	const loadPatients = async (search?: string) => {
		setIsLoading(true);
		try {
			const sortField = sorting[0]?.id;
			const sortDirection = sorting[0]?.desc ? 'desc' : 'asc';

			const { data, total } = await fetchPatients(
				currentPage,
				PATIENTS_PER_PAGE,
				search,
				sortField,
				sortDirection
			);
			setPatients(data);
			setTotalPatients(total);
			setError(null);
		} catch (err) {
			console.error("Error fetching patients:", err);
			setError("Failed to load patients.");
		} finally {
			setIsLoading(false);
		}
	};

	const SearchPatients = async (search: string) => {
		try {
			// When searching, reset to the first page?
			setCurrentPage(1);
			const sortField = sorting[0]?.id;
			const sortDirection = sorting[0]?.desc ? 'desc' : 'asc';
			const { data, total } = await fetchPatients(
				1,
				PATIENTS_PER_PAGE,
				search,
				sortField,
				sortDirection
			);
			setPatients(data);
			setTotalPatients(total);
			setError(null);
		} catch (err) {
			console.error("Error searching patients:", err);
			setError("Failed to search patients.");
		}
	};

	const handleEditClick = (patient: Patient) => {
		setSelectedPatient(patient);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedPatient(null);
		setIsModalOpen(false);
	};

	const handlePatientUpdate = async () => {
		await loadPatients();
	};

	return (
		<Layout>
			<h1 className="text-2xl font-bold mb-4">Patient Management</h1>
			{isLoading ? (
				<LoadingSpinner size={60} color="#354396" />
			) : (
				<>
					<PatientButtons searchPatients={SearchPatients} reloadPatients={() => loadPatients()} />
					<PatientTable
						patients={patients}
						onEditClick={handleEditClick}
						sorting={sorting}
						setSorting={setSorting}
					/>
					<Pagination
						currentPage={currentPage}
						totalItems={totalPatients}
						itemsPerPage={PATIENTS_PER_PAGE}
						onPageChange={setCurrentPage}
					/>
				</>
			)}
			{selectedPatient && (
				<EditPatientModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					patient={selectedPatient}
					onPatientUpdated={handlePatientUpdate}
				/>
			)}
		</Layout>
	);
};

export default PatientManagementPage;
