import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { fetchSample, fetchSamples, Sample } from "../../api/labiq/labiqApi";
import LoadingSpinner from '../../components/icons/LoadingSpinner';

import SampleTable from "../../components/labiq/sampleManagement/SampleTable";
import ActionButtons from "../../components/labiq/sampleManagement/SampleTrackingButtons";
import SampleModal from "../../components/labiq/sampleManagement/SampleModal";
import OmnibeadModal from "../../components/labiq/batchManagement/omnibeadUploadForm";
import Pagination from "../../components/Pagination";

const SampleTrackingPage: React.FC = () => {
    const [samples, setSamples] = useState<Sample[]>([]);
    const [totalSamples, setTotalSamples] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortBy, setSortBy] = useState<string>("id");
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
    const [selectedSamples, setSelectedSamples] = useState<number[]>([]);
    const [selectedSampleClientId, setSelectedSampleClientId] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const { id } = useParams<{ id: string }>();

    const SAMPLES_PER_PAGE = 30;

    // Fetch sample data and handle modal open logic
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (id) {
                    const sample = await fetchSample(id);
                    setSamples([sample]);
                    handleEditSample(sample); 
                } else {
                    await fetchSampleData(searchTerm);
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load samples.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, currentPage, sortBy, sortOrder]);

    const fetchSampleData = async (searchTerm: string) => {

        setError(null);
        try {
            const userInfo = JSON.parse(sessionStorage.getItem("user") || "{}");
            const company = userInfo.company;
            const { data, total } = await fetchSamples(currentPage, SAMPLES_PER_PAGE, company, sortBy, sortOrder, searchTerm);
            setSamples(data);
            setTotalSamples(total);
        } catch (err) {
            console.error("Error fetching samples:", err);
            setError("Failed to load samples.");
        } 
    };

    // Handle sorting of the page data
    const handleSort = (column: string) => {
        if (column === sortBy) {
            setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }
    };

    // Handle editing a sample when a row is clicked
    const handleEditSample = (sample: Sample) => {
        setSelectedSamples([sample.id]);
        setIsModalOpen(true);
    };

    // Handle opening the modal from action buttons or other sources
    const handleEditClick = (sampleId: number) => {
        setSelectedSamples([sampleId]);
        setIsModalOpen(true);
    };

    const handleNewSampleClick = () => {
        setSelectedSamples([0]); // Use 0 to indicate a new sample
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedSamples([]);
        setSelectedSampleClientId([]);
        setIsModalOpen(false);
        setIsUploadModalOpen(false);
    };

    const handleFormSubmission = () => {
        fetchSampleData("");
        handleModalClose();
    };

    const handleOmnibeadModalOpen = () => {
        setIsModalOpen(false);
        setIsUploadModalOpen(true);
    };

    return (
        <Layout>
            <div className="p-6">
                <h1 className="text-2xl font-bold mb-4">Sample Tracking</h1>
                {/* Action Buttons */}
                <ActionButtons
                    selectedSamples={selectedSamples}
                    selectedSampleClientId={selectedSampleClientId}
                    refreshSamples={fetchSampleData}
                    editClick={handleEditClick}
                    newSample={handleNewSampleClick}
                    omnibeadUpload={handleOmnibeadModalOpen}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
                {/* Sample Table */}
                {isLoading ? (
                    <LoadingSpinner size={60} color="#354396" />
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <>
                        <SampleTable
                            samples={samples}
                            selectedSamples={selectedSamples}
                            setSelectedSamples={setSelectedSamples}
                            setSelectedSampleClientId={setSelectedSampleClientId}
                            selectedSampleClientId={selectedSampleClientId}
                            onSort={handleSort}
                            sortBy={sortBy}
                            sortOrder={sortOrder}
                            onEditSample={handleEditSample} // Add this line
                        />
                        <Pagination currentPage={currentPage} totalItems={totalSamples} itemsPerPage={SAMPLES_PER_PAGE} onPageChange={setCurrentPage} />
                    </>
                )}

                {/* Show the modal to edit or create a sample */}
                {isModalOpen && selectedSamples.length === 1 && (
                    <SampleModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        sample={
                            selectedSamples[0] === 0
                                ? {
                                      id: 0,
                                      sampleID: "",
                                      client: "",
                                      created_at: new Date().toISOString(),
                                      updated_at: new Date().toISOString(),
                                      sampleOA: null,
                                      qsBases: [],
                                  }
                                : samples.find((s) => s.id === selectedSamples[0])!
                        }
                        onFormSubmission={handleFormSubmission}
                    />
                )}

                {/* Show the modal to upload an omnibead file */}
                {isUploadModalOpen && <OmnibeadModal isOpen={isUploadModalOpen} onClose={handleModalClose} onFormSubmission={handleFormSubmission} />}
            </div>
        </Layout>
    );
};

export default SampleTrackingPage;
