import React from "react";
import { User } from "../../api/admin/userApi";

interface UserProfileHeaderProps {
    user: User;
    isEditing: boolean;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileHeader: React.FC<UserProfileHeaderProps> = ({ user, isEditing, setUserData }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevUser) => ({ ...prevUser, [name]: value }));
    };

    return (
        <div className="flex items-center space-x-6">
            <img className="h-28 w-28 object-cover object-center rounded-full" src={user.profile_picture || "/assets/images/default-profile.png"} alt="User avatar" />
            <div>
                {isEditing ? (
                    <>
                        <input type="text" name="first_name" value={user.first_name} onChange={handleChange} className="text-2xl font-semibold text-gray-800 border-b border-gray-300 focus:outline-none" />
                        <input type="text" name="last_name" value={user.last_name} onChange={handleChange} className="text-2xl font-semibold text-gray-800 border-b border-gray-300 focus:outline-none" />
                        <input type="email" name="email" value={user.email} onChange={handleChange} className="text-sm text-gray-500 border-b border-gray-300 focus:outline-none" />
                        <input type="text" name="phone" value={user.phone} onChange={handleChange} className="text-sm text-gray-500 border-b border-gray-300 focus:outline-none" />
                    </>
                ) : (
                    <>
                        <p className="text-2xl font-semibold text-gray-800">{`${user.first_name} ${user.last_name}`}</p>
                        <p className="text-sm text-gray-500">{user.email}</p>
                        <p className="text-sm text-gray-500">{user.phone}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserProfileHeader;
