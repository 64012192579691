import { useEffect, useState } from "react";
import React from "react";
import { Company, getStaff } from "../../../../api/admin/companyApi";
import {User} from "../../../../api/admin/userApi";

import EditUserModal from "../../user/EditUserModal";

interface DetailsTabProps {
	company: Company;
	isSubmitting: boolean;
}

const StaffTab: React.FC<DetailsTabProps> = ({ company, isSubmitting }) => {
	const [staffList, setStaffList] = useState<User[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPagetotal] = useState<number>(30);
	const [totalStaff, setTotalStaff] = useState<number>(0);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

	useEffect(() => {
		loadStaff();
	},[])
	
	const loadStaff = async () => {
		try{
			const response = await getStaff(currentPage, pageTotal, company.name)
			setStaffList(response.data);
			setTotalStaff(response.total);
		}catch(err){
			console.error(err);
		}
	}

	const editStaffMember = async (staff: User) => {
		//open the editusermodal that is already built
		setSelectedUser(staff);
		setIsModalOpen(true);
	}

	const closeModal = async () => {
		setSelectedUser(null)
		setIsModalOpen(false);
	}

	const handleStaffUpdate = async (updatedStaff: User) => {
		setStaffList((prevStaff) => prevStaff.map((staff) => (staff.id === updatedStaff.id ? updatedStaff : staff)));
		closeModal();
	}

	const newUser = async () => {
		let user:User = {
			id: 0,
    		uuid: '',
    		username: '',
    		email: '',
    		password: '',
    		password_confirmation:  '',
    		first_name:'', 
    		last_name: '',
    		phone: '',
    		address: '',
    		city: '',
    		state: '',
    		country: 'Australia',
    		postal_code: '',
    		profile_picture: '',
    		date_of_birth: new Date(),
    		gender: '',
			sex: '',
    		preferences: '',
    		is_active: true,
    		created_at: new Date(),
    		updated_at: new Date(),
    		company: company.name,
    		panel_key: company.name,
    		company_code: company.name,
    		reagents: '', 
    		roles: []
		}
		setSelectedUser(user);
		setIsModalOpen(true);
	}


    return (
        <div className="grid grid-cols-1">
			{/* ADd a staff member button at the top of the page */}
			<div className="mb-4 flex justify-between">
                <button onClick={newUser} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                    Create Staff Member
                </button>
			</div>

			{/* List out the staf members (need to add cols to this list) */}
            {staffList.length > 0 ? (
                staffList.map((staff) => (
                    <div key={staff.id} className="cursor-pointer flex items-center space-x-4 p-2 border-b hover:bg-gray-100" onClick={() => editStaffMember(staff)}>
                        <label htmlFor={`patient-${staff.id}`} className="flex-1">
                            <span className="block text-sm font-medium">{staff.first_name} {staff.last_name}</span>
                            <span className="block text-xs text-gray-500">{staff.email}</span>
                        </label>
                    </div>
                ))
            ) : (
                <div>
                    <p>No staff found for this company</p>
                </div>
            )}
			{isModalOpen && selectedUser && <EditUserModal  isOpen={isModalOpen} onClose={closeModal} user={selectedUser} onUserUpdated={handleStaffUpdate}/>}
        </div>
    );
};

export default StaffTab;
