import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { updateApplication, createApplication, deleteApplication, Application } from "../../../api/admin/appApi";

interface EditAppModalProps {
    isOpen: boolean;
    onClose: () => void;
    app: Application;
    onAppUpdated: () => void;
}

const EditAppModal: React.FC<EditAppModalProps> = ({ isOpen, onClose, app, onAppUpdated }) => {
    const [formData, setFormData] = useState<Partial<Application>>({
        name: app.name,
        description: app.description,
        redirect_uris: app.redirect_uris,
        client_id: app.client_id,
        client_secret: app.client_secret,
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [activeTab, setActiveTab] = useState("Details");


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };


	// Delete application popup
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	const onDelete = async () => {
		try {
			await deleteApplication(app.id.toString());
			onAppUpdated();
		} catch (err) {
			console.error("Error deleting app:", err);
			setError("Failed to delete app.");
		}
	};

	const handleDeleteClick = () => {
		setShowConfirmDelete(true);
	};

	const handleConfirmDelete = async () => {
		setShowConfirmDelete(false);
		await onDelete();
	};

	const handleCancelDelete = () => {
		setShowConfirmDelete(false);
	};

	//handle submit of the form for either update or creation of an application
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const { name, description, redirect_uris, logo } = formData;
            const updateData = { name, description, redirect_uris, logo };
            let updatedApp;
            if (app.id !== 0) {
                updatedApp = await updateApplication(app.id.toString(), updateData);
            } else {
                updatedApp = await createApplication(updateData);
            }
            onAppUpdated();
        } catch (err) {
            console.error("Error updating app:", err);
            setError("Failed to update app.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className="flex items-center justify-center min-h-screen px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <div className="bg-white rounded p-6 z-20">
                            <DialogTitle className="text-xl font-semibold mb-4">Edit {formData.name}</DialogTitle>

                            <div className="flex space-x-4 mb-6 border-b">
                                <button onClick={() => setActiveTab("Details")} className={`pb-2 ${activeTab === "Details" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    App Details
                                </button>
                                <button onClick={() => setActiveTab("Secrets")} className={`pb-2 ${activeTab === "Secrets" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Secrets
                                </button>
								
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-4">
                                {activeTab === "Details" && (
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="name" className="block text-sm">
                                                App Name
                                            </label>
                                            <input type="text" id="name" name="name" value={formData.name || ""} onChange={handleChange} placeholder="Enter app name" className="w-full p-2 border rounded" />
                                        </div>
                                        <div>
                                            <label htmlFor="logo" className="block text-sm">
                                                Logo
                                            </label>
                                            <input type="file" id="logo" name="logo" onChange={handleChange} placeholder="Upload new logo" className="w-full p-2 border rounded" />
                                        </div>
                                        <div className="col-span-2">
                                            <label htmlFor="description" className="block text-sm">
                                                Description
                                            </label>
                                            <textarea id="description" name="description" value={formData.description || ""} onChange={handleChange} placeholder="Enter description" className="w-full p-2 border rounded" />
                                        </div>
                                    </div>
                                )}

                                {activeTab === "Secrets" && (
                                    <div>
                                        <label htmlFor="category" className="block text-sm">
                                            Secrets
                                        </label>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <label htmlFor="client_id" className="block text-sm">
                                                    Client ID
                                                </label>
                                                <p id="client_id" className="w-full p-2 border rounded bg-gray-100">
                                                    {formData.client_id}
                                                </p>
                                            </div>
                                            <div>
                                                <label htmlFor="client_secret" className="block text-sm">
                                                    Client Secret
                                                </label>
                                                <p id="client_secret" className="w-full p-2 border rounded bg-gray-100">
                                                    {formData.client_secret}
                                                </p>
                                            </div>
                                            <div className="col-span-2">
                                                <label htmlFor="redirect_uris" className="block text-sm">
                                                    Redirect URIs (one per line)
                                                </label>
                                                <textarea
                                                    id="redirect_uris"
                                                    name="redirect_uris"
                                                    value={formData.redirect_uris?.join("\n") || ""}
                                                    onChange={(e) => {
                                                        const uris = e.target.value.split("\n");
                                                        setFormData((prevFormData) => ({
                                                            ...prevFormData,
                                                            redirect_uris: uris,
                                                        }));
                                                    }}
                                                    placeholder="Enter redirect URIs, one per line"
                                                    className="w-full p-2 border rounded"
                                                    rows={5} // You can adjust the number of rows for desired height
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {error && <p className="text-red-500">{error}</p>}

                                <div className="flex justify-end space-x-2">
                                    <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        Cancel
                                    </button>
									<button type="button" onClick={handleDeleteClick} className="px-4 py-2 bg-red-400 rounded hover:bg-red-500">
                                        Delete
                                    </button>
                                    <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                        {isSubmitting ? "Saving..." : app.id !== 0 ? "Save Changes" : "Create App"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>

			{/* confirm deletion popup of an application */}
			{showConfirmDelete && (
				<Transition show={showConfirmDelete} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCancelDelete}>
						<div className="flex items-center justify-center min-h-screen px-4">
							<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
								<div className="fixed inset-0 bg-black opacity-30" />
							</Transition.Child>

							<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
								<div className="bg-white rounded p-6 z-20">
									<DialogTitle className="text-xl font-semibold mb-4">Confirm Delete</DialogTitle>
									<p className="mb-4">Are you sure you want to delete this application? This action cannot be undone.</p>
									<div className="flex justify-end space-x-2">
										<button type="button" onClick={handleCancelDelete} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
											Cancel
										</button>
										<button type="button" onClick={handleConfirmDelete} className="px-4 py-2 bg-red-400 rounded hover:bg-red-500">
											Confirm
										</button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition>
			)}
        </Transition>
    );
};

export default EditAppModal;
