import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { createRole, deleteRole, getApplicationsByRole, assignApplicationsToRole, removeApplicationFromRole, getUsersByRole, Role } from "../../../api/admin/rolesApi";
import { getApplications, Application } from "../../../api/admin/appApi";
import { User } from "../../../api/admin/userApi";

interface EditRoleModalProps {
    isOpen: boolean;
    onClose: () => void;
    role: Role;
    onRoleUpdated: () => void;
}

const EditRoleModal: React.FC<EditRoleModalProps> = ({ isOpen, onClose, role, onRoleUpdated }) => {
    const [formData, setFormData] = useState<Partial<Role>>({
        name: role.name,
        guard_name: role.guard_name,
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [activeTab, setActiveTab] = useState("Details");

    //applicaitons by role state
    const [applications, setApplications] = useState<Application[]>([]);
    const [assignedApplicationIds, setAssignedApplicationIds] = useState<number[]>([]);
    const [isApplicationsLoading, setIsApplicationsLoading] = useState<boolean>(false);
    const [originalAssignedApplicationIds, setOriginalAssignedApplicationIds] = useState<number[]>([]);

    // Users state
    const [users, setUsers] = useState<User[]>([]);
    const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
    const [usersError, setUsersError] = useState<string>("");

    useEffect(() => {
        if (activeTab === "Applications") {
            fetchApplicationsData();
        } else if (activeTab === "Users") {
            fetchUsersData();
        }
    }, [activeTab]);

    ///////////////////////////////////////// User functions
    const fetchUsersData = async () => {
        setIsUsersLoading(true);
        try {
            const assignedUsers = await getUsersByRole(role.id);
            setUsers(assignedUsers);
            setUsersError("");
        } catch (err) {
            console.error("Error fetching users:", err);
            setUsersError("Failed to load users.");
        } finally {
            setIsUsersLoading(false);
        }
    };

    ///////////////////////////////////////// Application functions
    const fetchApplicationsData = async () => {
        setIsApplicationsLoading(true);
        try {
            // Fetch all applications
            const { data: allApplications } = await getApplications(1, 1000);
            setApplications(allApplications);

            // Fetch applications assigned to this role
            const assignedAppsResponse = await getApplicationsByRole(role.id);
            const assignedApps = assignedAppsResponse.applications; // Access the 'applications' array

            const assignedIds = assignedApps.map((app: Application) => app.id);
            setAssignedApplicationIds(assignedIds);
            setOriginalAssignedApplicationIds(assignedIds); // Store the original assigned IDs
        } catch (err) {
            console.error("Error fetching applications:", err);
            setError("Failed to load applications.");
        } finally {
            setIsApplicationsLoading(false);
        }
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const appId = parseInt(e.target.value, 10);
        if (e.target.checked) {
            // Add the application ID to the assigned list
            setAssignedApplicationIds((prev) => [...prev, appId]);
        } else {
            // Remove the application ID from the assigned list
            setAssignedApplicationIds((prev) => prev.filter((id) => id !== appId));
        }
    };

    const assignApplications = async (roleId: number, applicationIds: number[]) => {
        try {
            await assignApplicationsToRole(roleId, applicationIds);
        } catch (error) {
            console.error("Error assigning applications to role:", error);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    // Handle deletion of a role
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const onDelete = async () => {
        try {
            await deleteRole(role.id.toString());
            onRoleUpdated(); // Refresh the data in the parent component
            onClose(); // Close the modal after deletion
        } catch (err) {
            console.error("Error deleting role:", err);
            setError("Failed to delete role.");
        }
    };

    const handleDeleteClick = () => {
        setShowConfirmDelete(true);
    };

    const handleConfirmDelete = async () => {
        try {
            // Fetch users assigned to the role
            const assignedUsers = await getUsersByRole(role.id);
            if (assignedUsers.length > 0) {
                // Prevent deletion and display an error message
                setError("Cannot delete role. There are users assigned to this role.");
                setShowConfirmDelete(false); // Close the confirmation dialog
                return;
            }

            // No users assigned, proceed with deletion
            setShowConfirmDelete(false);
            await onDelete();
        } catch (err) {
            console.error("Error checking assigned users:", err);
            setError("Failed to delete role.");
            setShowConfirmDelete(false);
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmDelete(false);
    };

    // handle submission of the form to assign applicaitons to a role
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            if (role.id === 0) {
                // Create a new role
                const newRole = await createRole(formData.name!);
				if (assignedApplicationIds !== null){
					await assignApplications(newRole.id, assignedApplicationIds);
				}
                
            } else {
                // Compare original and current assigned application IDs
                const applicationsToAssign = assignedApplicationIds.filter((id) => !originalAssignedApplicationIds.includes(id));
                const applicationsToUnassign = originalAssignedApplicationIds.filter((id) => !assignedApplicationIds.includes(id));

                // Assign new applications
				if (applicationsToAssign.length > 0){
					await assignApplicationsToRole(role.id, applicationsToAssign);
				}
                
                // Unassign applications
                for (const appId of applicationsToUnassign) {
                    await removeApplicationFromRole(role.id, appId);
                }
            }

            onRoleUpdated();
            onClose();
        } catch (err) {
            console.error("Error updating role:", err);
            setError("Failed to update role.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className="flex items-center justify-center min-h-screen px-4">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black opacity-30" />
                    </Transition.Child>

                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <div className="bg-white rounded p-6 z-20 max-w-md w-full max-h-screen overflow-y-auto">
                            <DialogTitle className="text-xl font-semibold mb-4">Edit {formData.name}</DialogTitle>

                            {/*  Navigation buttons for the modal */}
                            <div className="flex space-x-4 mb-6 border-b">
                                <button onClick={() => setActiveTab("Details")} className={`pb-2 ${activeTab === "Details" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Role Details
                                </button>
                                <button onClick={() => setActiveTab("Applications")} className={`pb-2 ${activeTab === "Applications" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Assigned Applications
                                </button>
                                <button onClick={() => setActiveTab("Users")} className={`pb-2 ${activeTab === "Users" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                                    Assigned Users
                                </button>
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-4">
                                {/*  Details tab */}
                                {activeTab === "Details" && (
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="name" className="block text-sm">
                                                Role Name
                                            </label>
                                            <input type="text" id="name" name="name" value={formData.name || ""} onChange={handleChange} className="w-full p-2 border rounded" />
                                        </div>
                                        <div className="col-span-2">
                                            <label htmlFor="guard_name" className="block text-sm">
                                                Guard Name
                                            </label>
                                            <p id="guard_name" className="w-full p-2 border rounded">
                                                {formData.guard_name}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                {/* Applications tab */}
                                {activeTab === "Applications" && (
                                    <div>
                                        {isApplicationsLoading ? (
                                            <p>Loading applications...</p>
                                        ) : error ? (
                                            <p className="text-red-500">{error}</p>
                                        ) : (
                                            <div className="space-y-2">
                                                {applications.map((app) => (
                                                    <div key={app.id} className="flex items-center">
                                                        <input type="checkbox" id={`app-${app.id}`} name="applications" value={app.id} checked={assignedApplicationIds.includes(app.id)} onChange={handleCheckboxChange} className="mr-2" />
                                                        <label htmlFor={`app-${app.id}`}>{app.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Users tab */}
                                <div className="max-h-64 overflow-y-auto">
                                    {activeTab === "Users" && (
                                        <div>
                                            {isUsersLoading ? (
                                                <p>Loading users...</p>
                                            ) : usersError ? (
                                                <p className="text-red-500">{usersError}</p>
                                            ) : (
                                                <>
                                                    <p className="font-semibold mb-2">Total Users Assigned: {users.length}</p>
                                                    <div className="max-h-64 overflow-y-auto">
                                                        <ul className="space-y-2">
                                                            {users.map((user) => (
                                                                <li key={user.id} className="border-b pb-2">
                                                                    <p className="font-medium">{user.username}</p>
                                                                    <p className="text-sm text-gray-600">{user.email}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {error && <p className="text-red-500">{error}</p>}

                                {/*  Buttons for the modal */}
                                <div className="flex justify-end space-x-2">
                                    <button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                        Cancel
                                    </button>
                                    <button type="button" onClick={handleDeleteClick} className="px-4 py-2 bg-red-400 rounded hover:bg-red-500">
                                        Delete
                                    </button>
                                    <button type="submit" disabled={isSubmitting} className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}>
                                        {isSubmitting ? "Saving..." : role.id !== 0 ? "Save Changes" : "Create Role"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>

            {showConfirmDelete && (
                <Transition show={showConfirmDelete} as={Fragment}>
                    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCancelDelete}>
                        <div className="flex items-center justify-center min-h-screen px-4">
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                                <div className="fixed inset-0 bg-black opacity-30" />
                            </Transition.Child>

                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                <div className="bg-white rounded p-6 z-20">
                                    <DialogTitle className="text-xl font-semibold mb-4">Confirm Delete</DialogTitle>
                                    <p className="mb-4">Are you sure you want to delete this role? This action cannot be undone.</p>
                                    <div className="flex justify-end space-x-2">
                                        <button type="button" onClick={handleCancelDelete} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
                                            Cancel
                                        </button>
                                        <button type="button" onClick={handleConfirmDelete} className="px-4 py-2 bg-red-400 rounded hover:bg-red-500">
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            )}
        </Transition>
    );
};

export default EditRoleModal;
