import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import {Company, updateCompany, createCompany} from "../../../api/admin/companyApi"
import Sidebar from "./editCompanyModalComponents/Sidebar";
import DetailsTab from "./editCompanyModalComponents/DetailsTab";
import StaffTab from "./editCompanyModalComponents/StaffTab";

interface EditCompanyModalProps {
	isOpen: boolean;
	onClose: () => void;
	company: any;
	onCompanyUpdated: () => void;
}

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({ isOpen, onClose, company, onCompanyUpdated }) => {
	const [updatedCompany, setUpdatedCompany] = useState<Company>({ ...company });
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [activeTab, setActiveTab] = useState("Details");

	useEffect(() => {
		setUpdatedCompany({ ...company });
	}, [company]);


	// Update patient information
	const updateCompanyInfo = async () => {
		setIsSubmitting(true);
		try {
			if (!updatedCompany.name ||  !updatedCompany.email) {
				setError("Name and email are required.");
				setIsSubmitting(false);
				return;
			}

			if (company.id !== 0) {
				await updateCompany(company.id, updatedCompany);

			} else {
				const newCompany = await createCompany(updatedCompany);
			}
			onCompanyUpdated();
		} catch (err) {
			console.error("Error updating patient:", err);
			setError("Failed to update patient.");
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-center justify-center min-h-screen px-4">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black opacity-60" />
					</Transition.Child>

					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="bg-white rounded z-20 w-[80vw] h-[80vh] flex">
							{/* Sidebar for NavButtons */}
							<div >
								<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} company={updatedCompany} />
							</div>

							{/* Main Content */}
							<div className="pl-4 flex flex-col p-6" style={{width : '-webkit-fill-available'}}>

								<div className="flex-1 overflow-y-auto">
									{/* Render tabs based on activeTab */}
									{activeTab === "Details" && (
										<DetailsTab company={updatedCompany} setUpdatedCompany={setUpdatedCompany} />
									)}
									{activeTab === "Staff" && (
										<StaffTab company={updatedCompany} isSubmitting={isSubmitting}/>
									)}

								</div>

								{error && <p className="text-red-500 mt-4">{error}</p>}

								<div className="flex justify-end space-x-2 mt-6">

									<button
										type="button"
										onClick={onClose}
										className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
									>
										Close
									</button>
									<button
										onClick={updateCompanyInfo}
										disabled={isSubmitting}
										className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
											}`}
									>
										{isSubmitting ? (company.id !== 0 ? "Saving..." : "Creating...") : "Save Changes"}
									</button>
								</div>
							</div>
						</div>

					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default EditCompanyModal;
