import React, { useState } from 'react';
import { User } from "../../../api/admin/userApi";
import {  Role } from "../../../api/admin/rolesApi";
import EditUserModal from "./EditUserModal";

const createEmptyUser = (): User => ({
    id: 0,
    uuid: "",
    username: "",
    email: "",
	password: "",
	password_confirmation: "",
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    profile_picture: "",
    date_of_birth: new Date(),
    gender: "",
	sex: "",
	company: "",
	panel_key: "",
    company_code: "",
    reagents: "",
    preferences: "",
    is_active: false,
    created_at: new Date(),
    updated_at: new Date(),
    roles: []
});

interface UserButtonsProps {
	searchUsers: (search: string, role: string) => void;
	roles: Role[];
}

const UserButtons: React.FC<UserButtonsProps> = ({searchUsers, roles}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newUser, setSelectedUser] = useState<User>(createEmptyUser());
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedRole, setSelectedRole] = useState('');

    const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const newRole = e.target.value;
		setSelectedRole(newRole);
		searchUsers(searchTerm, newRole);
	};

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = e.target.value;
		setSearchTerm(newSearchTerm);
		searchUsers(newSearchTerm, selectedRole); 
	};

	const reloadUsers = () => {
		setSearchTerm("");
		setSelectedRole("")
		searchUsers(searchTerm, selectedRole);
	}

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
        setIsModalOpen(false);
    };

	const handleCreateUser = () => {
		handleModalClose();
	};

	return (
        <div>
            <div className="mb-4 flex justify-between">
                <button onClick={handleOpenModal} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                    Create User
                </button>

                <div className="flex justify-end">
                    <div className='mr-4'>
                        {/* filter by role */}
                        <select id="roleSelect" value={selectedRole} onChange={handleRoleChange} className="border px-4 py-2 rounded">
                            <option value="" disabled>
                                Select a role
                            </option>
                            {roles.map((role) => (
                                <option key={role.id} value={role.name} className="flex items-center mb-2">
                                    {role.name}
                                </option>
                            ))}
                            
                        </select>
                    </div>
                    <div className="mr-4">
                        <input type="text" placeholder="Search users..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
                        
                    </div>
					<div >
						<button onClick={reloadUsers} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                            Reset
                        </button>
					</div>
				
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && <EditUserModal isOpen={isModalOpen} onClose={handleModalClose} user={newUser} onUserUpdated={handleCreateUser} />}
        </div>
    );
};

export default UserButtons;