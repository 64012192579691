import React, { useState } from 'react';
import Layout from "../components/Layout";
import { User, updateProfile } from "../api/admin/userApi";
import UserProfileHeader from "../components/profile/UserProfileHeader";
import UserProfileDetails from "../components/profile/UserProfileDetails";
import UserProfileAddress from "../components/profile/UserProfileAddress";
import UserProfileCompany from "../components/profile/UserProfileCompany";

const ProfilePage: React.FC = () => {
    // Mock user data
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    const [userData, setUserData] = useState<User>(user);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
		try{
			const updatedUser = await updateProfile(userData);
			sessionStorage.setItem("user", JSON.stringify(updatedUser));

		}catch(err){
			console.log(err);
		}
        setIsEditing(false);
    };

    return (
        <Layout>
            <div className="container mx-auto px-4 py-8">
                <div className="bg-white shadow rounded-lg p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-2xl font-semibold text-gray-800">Profile</h1>
                        {isEditing ? (
                            <button onClick={handleSave} className="bg-green-500 text-white px-4 py-2 rounded">
                                Save
                            </button>
                        ) : (
                            <button onClick={handleEditToggle} className="bg-blue-500 text-white px-4 py-2 rounded">
                                Edit
                            </button>
                        )}
                    </div>
                    <UserProfileHeader user={userData} isEditing={isEditing} setUserData={setUserData} />
                    <div className="border-t border-gray-200 my-4"></div>
                    <UserProfileDetails user={userData} isEditing={isEditing} setUserData={setUserData} />
                    <div className="border-t border-gray-200 my-4"></div>
                    <UserProfileAddress user={userData} isEditing={isEditing} setUserData={setUserData} />
                    <div className="border-t border-gray-200 my-4"></div>
                    <UserProfileCompany user={userData} isEditing={isEditing} setUserData={setUserData} />
                </div>
            </div>
        </Layout>
    );
};

export default ProfilePage;
