import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import axios from "axios";
import { submitBatch, updateBatch, createBatch, fetchReagentList, fetchBatch, Batch } from "../../../api/labiq/labiqApi";
import { fetchCompanies, Company } from "../../../api/admin/companyApi";

// Import PrimeReact components
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

//components for the modal
import Sidebar from "./batchModalComponents/Sidebar";
import BatchDetails from "./batchModalComponents/BatchDetails";
import BatchSamples from "./batchModalComponents/BatchSamples";

interface BatchModalProps {
	isOpen: boolean;
	onClose: () => void;
	batch_id: number;
	onFormSubmission: () => void;
}

const BatchModal: React.FC<BatchModalProps> = ({ isOpen, onClose, batch_id, onFormSubmission }) => {
	const [formData, setFormData] = useState<Partial<Batch>>({});
	const [companies, setCompanies] = useState<Company[]>([]);
	const [reagentList, setReagentList] = useState<string[]>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<any[]>([])
	const [activeTab, setActiveTab] = useState("Details");

	const toast = useRef<Toast>(null);

	//Init on the first render
	useEffect(() => {
		const getCompanies = async () => {
			try {
				//fixed call being the wrong way around
				const data = await fetchCompanies(1, 100, "");
				setCompanies(data.data);
			} catch (error: unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages); // State to store an array of error messages
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); // Fallback to general error message
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			}
		};
		const fetchReagents = async () => {
			try {
				const reagents = await fetchReagentList();
				setReagentList(reagents);
			} catch (error: unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages); // State to store an array of error messages
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); // Fallback to general error message
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			}
		};
		if (isOpen) {
			try {
				if (batch_id !== 0) {
					getBatchDetails();
				}
				const userCompany = JSON.parse(sessionStorage.getItem("user") || "{}").company;
				if (userCompany === "Mygene" || userCompany === "DNAIQ") {
					getCompanies();
				} else {
					setFormData((prev) => ({ ...prev, batch_company: userCompany }));
				}
				fetchReagents();
			} catch (error: unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages); // State to store an array of error messages
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); // Fallback to general error message
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			}
		}
	}, [isOpen, batch_id]);

	//get the batch details
	const getBatchDetails = async () => {
		try {
			const response = await fetchBatch(batch_id);
			const batchData: Partial<Batch> = {
				batch_id: response.batch_id,
				batch_client_id: response.batch_client_id,
				batch_delivery_option: response.batch_delivery_option,
				batch_tracking_no: response.batch_tracking_no,
				batch_vendor: response.batch_vendor,
				batch_create_date: response.batch_create_date,
				batch_submission: response.batch_submission,
				batch_user_id: response.batch_user_id,
				batch_company: response.batch_company,
				batch_sample_count: response.batch_sample_count,
				samples: response.samples,
			};
			setFormData(batchData);
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		}
	}

	//handle changes to the form
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	//handle changes to the select element
	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
			if (batch_id === 0) {
				await createBatch(formData as Batch);
				onFormSubmission();
				onClose();
			} else {
				if (formData.batch_delivery_option === 0) {
					delete formData.batch_tracking_no;
					delete formData.batch_vendor;
				}
				await updateBatch(batch_id, formData as Batch);
				onFormSubmission();
			}
		} catch (error: unknown) {
			handleApiError(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const submitBatchClick = async () => {
		setIsSubmitting(true);
		try {
			if (batch_id === 0) {
				await createBatch(formData as Batch);
			} else {
				if (formData.batch_delivery_option === 0) {
					delete formData.batch_tracking_no;
					delete formData.batch_vendor;
				}
				await updateBatch(batch_id, formData as Batch);
			}
			await submitBatch(batch_id);
			getBatchDetails();
			onFormSubmission();
			toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Batch submitted successfully', life: 3000 });
		} catch (error: unknown) {
			handleApiError(error);
		} finally {
			getBatchDetails();
			setIsSubmitting(false);
		}
	};

	const handleApiError = (error: unknown) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.data?.errors) {
				const errorMessages = Object.entries(error.response.data.errors)
					.flatMap(([field, messages]) => messages);
				setErrorMessages(errorMessages);
			} else if (error.response?.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message || "An unknown error occurred."]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	// Function to show the confirmation popup before submitting the batch
	const confirmBatchSubmission = (event: React.MouseEvent<HTMLButtonElement>) => {
		confirmPopup({
			target: event.currentTarget,
			message: 'Are you sure you want to submit this batch?',
			icon: 'pi pi-exclamation-triangle',
			style: { transform: 'translateY(-100%) translateY(-40px)' },
			footer: (props) => (
				<div className="flex mb-2 justify-center">
					<button
						onClick={props.accept}
						className="px-4 py-2 bg-green-200 rounded hover:bg-green-300 mr-2"

					>
						Yes
					</button>
					<button
						onClick={props.reject}
						className="px-4 py-2 bg-red-200 rounded hover:bg-red-300 mr-2"

					>
						No
					</button>
				</div>
			),
			accept: () => submitBatchClick(),
			reject: () => { /* do nothing on reject */ }
		});
	};



	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-center justify-center min-h-screen px-4">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black opacity-60" />
					</Transition.Child>

					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="bg-white rounded z-20 w-[80vw] h-[80vh] flex">

							{/* modal tabs, uses activetab useState react hook to transition state of the tab */}
							<div >
								<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} batch={formData as Batch} />
							</div>


							<form onSubmit={handleSubmit} className="pl-4 flex flex-col p-6" style={{ width: '-webkit-fill-available' }}>
								<div className="flex-1 overflow-y-auto">
									{/* Details info active tab */}
									{activeTab === "Details" && (
										<BatchDetails companies={companies} formData={formData} handleChange={handleChange} handleSelectChange={handleSelectChange} setErrorMessages={setErrorMessages} setFormData={setFormData} />
									)}

									{/* Samples active tab */}
									{activeTab === "Samples" && (

										<BatchSamples batch_id={batch_id} formData={formData} reagentList={reagentList} setFormData={setFormData} />
									)}
								</div>

								{/* error handling */}
								{/* Display errors to the user */}
								{errorMessages.length > 0 && (
									<div className="error-container">
										{errorMessages.map((msg, idx) => (
											<div key={idx} className="text-red-500">
												{msg}
											</div>
										))}
									</div>
								)}


								<div className="flex justify-end space-x-2">

									<Toast ref={toast} />
									<ConfirmPopup />
									<button type="button" onClick={onClose} className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300">
										Cancel
									</button>
									{batch_id !== 0 ? (
										<div className="space-x-2">
											{formData.batch_submission === 0 && (
												<div>
													<Button
														type="button"
														onClick={confirmBatchSubmission}
														disabled={isSubmitting}
														icon="pi pi-check"
														label={isSubmitting ? "Submitting..." : "Submit Batch"}
														className={`px-4 py-2 bg-green-200 rounded hover:bg-green-300 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
													/>
													<Button
														type="submit"
														disabled={isSubmitting}
														icon="pi pi-save"
														label={isSubmitting ? "Saving..." : "Save Changes"}
														className={`px-4 py-2 bg-blue-200 rounded hover:bg-blue-300 ml-2 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
													/>
												</div>
											)}

										</div>
									) : (
										<div>
											<Button
												type="submit"
												disabled={isSubmitting}
												icon="pi pi-plus"
												label={isSubmitting ? "Saving..." : "Create Batch"}
												className={`px-4 py-2 bg-green-200 rounded hover:bg-green-300 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
											/>
										</div>

									)}
								</div>
							</form>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default BatchModal;
