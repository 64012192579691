import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import {
	activateUser,
	deactivateUser,
	updateUser,
	assignUserRole,
	removeUserRole,
	User,
	createUser,
} from "../../../api/admin/userApi";
import { getAllRoles, Role } from "../../../api/admin/rolesApi";
import { fetchCompanies, Company } from "../../../api/admin/companyApi";

import Sidebar from "./editUserModalComponents/Sidebar";
import PersonalTab from "./editUserModalComponents/PersonalTab";
import CompanyTab from "./editUserModalComponents/CompanyTab";
import PreferencesTab from "./editUserModalComponents/PreferencesTab";
import RolesTab from "./editUserModalComponents/RolesTab";

interface EditUserModalProps {
	isOpen: boolean;
	onClose: () => void;
	user: User;
	onUserUpdated: (user: User) => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({ isOpen, onClose, user, onUserUpdated }) => {
	const [updatedUser, setUpdatedUser] = useState<User>({ ...user });
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [errorMessages, setErrorMessages] = useState<any[]>([])
	const [activeTab, setActiveTab] = useState("Personal");
	const [roles, setRoles] = useState<Role[]>([]);
	const [companies, setCompanies] = useState<Company[]>([]);

	// Fetch roles and companies on initial render
	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const rolesData = await getAllRoles();
				setRoles(rolesData);
			} catch (error) {
				console.error("Error fetching roles:", error);
			}
		};

		const fetchCompaniesList = async () => {
			try {
				const response = await fetchCompanies(1, 30, "");
				setCompanies(response.data || []);
			} catch (error) {
				console.error("Error fetching companies:", error);
				setCompanies([]);
			}
		};

		fetchRoles();
		fetchCompaniesList();
	}, []);

	// Activate or deactivate the user's account
	const onActiveClick = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);
		try {
			let updatedUserData = { ...updatedUser };
			if (!updatedUser.is_active) {
				await activateUser(updatedUser.id);
				updatedUserData.is_active = true;
			} else {
				await deactivateUser(updatedUser.id);
				updatedUserData.is_active = false;
			}
			setUpdatedUser(updatedUserData);
			onUserUpdated(updatedUserData);
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	// Handle form submission to update user details
	const handleSubmit = async () => {

		setIsSubmitting(true);
		try {
			let updatedUserData;
			if (updatedUser.id !== 0) {
				updatedUserData = await updateUser(updatedUser.id, updatedUser);
			} else {
				updatedUserData = await createUser(updatedUser);
			}
			setUpdatedUser(updatedUserData);
			onUserUpdated(updatedUserData);
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-center justify-center min-h-screen px-4">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black opacity-60" />
					</Transition.Child>

					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="bg-white rounded z-20 w-[80vw] h-[80vh] flex">
							{/* Sidebar */}
							<div>
								<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} user={user} />
							</div>
							{/* Form */}
							<div className="pl-4 flex flex-col p-6" style={{ width: '-webkit-fill-available' }}>

								<div className="flex-1 overflow-y-auto">
									{/* Personal Info Tab */}
									{activeTab === "Personal" && (
										<PersonalTab updatedUser={updatedUser} setUpdatedUser={setUpdatedUser} />
									)}

									{/* Company Info Tab */}
									{activeTab === "Company" && (
										<CompanyTab
											updatedUser={updatedUser}
											setUpdatedUser={setUpdatedUser}
											companies={companies}
										/>
									)}

									{/* Preferences Tab */}
									{activeTab === "Preferences" && (
										<PreferencesTab updatedUser={updatedUser} setUpdatedUser={setUpdatedUser} />
									)}

									{/* Roles Tab */}
									{activeTab === "Roles" && (
										<RolesTab
											updatedUser={updatedUser}
											setUpdatedUser={setUpdatedUser}
											roles={roles}
										/>
									)}

									{/* Subscriptions Tab */}
									{activeTab === "Subscriptions" && (
										<div className="">
											<p>Not active at the moment</p>
										</div>
									)}
								</div>

								{/* Display errors to the user */}
								{errorMessages.length > 0 && (
									<div className="error-container">
										{errorMessages.map((msg, idx) => (
											<div key={idx} className="text-red-500">
												{msg}
											</div>
										))}
									</div>
								)}


								<div className="flex justify-end space-x-2 mt-6">
									{/* Activate/Deactivate User */}
									<button
										type="button"
										onClick={onActiveClick}
										className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
									>
										{updatedUser.is_active ? "Deactivate" : "Activate"}
									</button>
									<button
										type="button"
										onClick={onClose}
										className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
									>
										Cancel
									</button>
									{updatedUser.id !== 0 ? (
										<button
											onClick={handleSubmit}
											disabled={isSubmitting}
											className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
												}`}
										>
											{isSubmitting ? "Saving..." : "Save Changes"}
										</button>
									) : (
										<button
											onClick={handleSubmit}
											disabled={isSubmitting}
											className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
												}`}
										>
											{isSubmitting ? "Saving..." : "Create User"}
										</button>
									)}
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default EditUserModal;
