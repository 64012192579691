// src/components/healthcare/practitioners/modalComponents/ProfileTab.tsx

import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";

interface ProfileTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

interface EmergencyContact {
	name: string;
	relationship: string;
	phone: string;
}

const ProfileTab: React.FC<ProfileTabProps> = ({ updatedPractitioner, setUpdatedPractitioner }) => {

	// Handler for simple input changes (User fields)
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setUpdatedPractitioner((prev) => ({ ...prev, [name]: value }));
	};

	// Handler for emergency contact fields
	const handleEmergencyContactChange = (
		field: keyof EmergencyContact,
		value: string
	) => {
		setUpdatedPractitioner((prev) => {
			const prevContact = prev.practitioner?.emergency_contact || {};
			const updatedContact = {
				...prevContact,
				[field]: value,
			};
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					emergency_contact: updatedContact,
				},
			};
		});
	};

	return (
		<div className="grid grid-cols-3">
			{/* Column 1 */}
			<div className="p-6">
				<div>
					<label htmlFor="first_name" className="block text-sm">
						First Name
					</label>
					<input
						type="text"
						id="first_name"
						name="first_name"
						value={updatedPractitioner.first_name || ""}
						onChange={handleChange}
						placeholder="Enter first name"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="last_name" className="block text-sm">
						Last Name
					</label>
					<input
						type="text"
						id="last_name"
						name="last_name"
						value={updatedPractitioner.last_name || ""}
						onChange={handleChange}
						placeholder="Enter last name"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="email" className="block text-sm">
						Email
					</label>
					<input
						type="email"
						id="email"
						name="email"
						value={updatedPractitioner.email || ""}
						onChange={handleChange}
						placeholder="Enter email"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="username" className="block text-sm">
						Username
					</label>
					<input
						type="text"
						id="username"
						name="username"
						value={updatedPractitioner.username || ""}
						onChange={handleChange}
						placeholder="Enter username"
						className="w-full p-2 border rounded"
					/>
				</div>
				{updatedPractitioner.id === 0 && (
					<div>
						<div>
							<label htmlFor="password" className="block text-sm">
								Password
							</label>
							<input
								type="password"
								id="password"
								name="password"
								value={updatedPractitioner.password || ""}
								onChange={handleChange}
								placeholder="Enter password"
								className="w-full p-2 border rounded"
							/>
						</div>
						<div>
							<label htmlFor="password_confirmation" className="block text-sm">
								Password Confirmation
							</label>
							<input
								type="password"
								id="password_confirmation"
								name="password_confirmation"
								value={updatedPractitioner.password_confirmation || ""}
								onChange={handleChange}
								placeholder="Confirm password"
								className="w-full p-2 border rounded"
							/>
						</div>
					</div>
				)}
			</div>

			{/* Column 2 */}
			<div className="p-6">
				<div>
					<label htmlFor="address" className="block text-sm">
						Address
					</label>
					<input
						type="text"
						id="address"
						name="address"
						value={updatedPractitioner.address || ""}
						onChange={handleChange}
						placeholder="Enter address"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="city" className="block text-sm">
						City
					</label>
					<input
						type="text"
						id="city"
						name="city"
						value={updatedPractitioner.city || ""}
						onChange={handleChange}
						placeholder="Enter city"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="state" className="block text-sm">
						State
					</label>
					<input
						type="text"
						id="state"
						name="state"
						value={updatedPractitioner.state || ""}
						onChange={handleChange}
						placeholder="Enter state"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="country" className="block text-sm">
						Country
					</label>
					<input
						type="text"
						id="country"
						name="country"
						value={updatedPractitioner.country || ""}
						onChange={handleChange}
						placeholder="Enter country"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="postal_code" className="block text-sm">
						Post Code
					</label>
					<input
						type="text"
						id="postal_code"
						name="postal_code"
						value={updatedPractitioner.postal_code || ""}
						onChange={handleChange}
						placeholder="Enter post code"
						className="w-full p-2 border rounded"
					/>
				</div>
			</div>

			{/* Column 3 */}
			<div className="p-6">
				<div>
					<label htmlFor="phone" className="block text-sm">
						Phone Number
					</label>
					<input
						type="text"
						id="phone"
						name="phone"
						value={updatedPractitioner.phone || ""}
						onChange={handleChange}
						placeholder="Enter phone number"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="date_of_birth" className="block text-sm">
						Date of Birth
					</label>
					<input
						type="date"
						id="date_of_birth"
						name="date_of_birth"
						value={
							updatedPractitioner.date_of_birth instanceof Date
								? updatedPractitioner.date_of_birth.toISOString().substring(0, 10)
								: updatedPractitioner.date_of_birth || ""
						}
						onChange={handleChange}
						placeholder="Enter date of birth"
						className="w-full p-2 border rounded"
					/>
				</div>
				<div>
					<label htmlFor="gender" className="block text-sm">
						Gender
					</label>
					<input
						type="text"
						id="gender"
						name="gender"
						value={updatedPractitioner.gender || ""}
						onChange={handleChange}
						placeholder="Enter gender"
						className="w-full p-2 border rounded"
					/>
				</div>

				{/* Sex */}
				<div>
                    <label htmlFor="sex" className="block text-sm">
                        Sex
                    </label>

                    <input type="text" id="sex" name="sex" value={updatedPractitioner.sex || ""} onChange={handleChange} placeholder="Enter sex (Male, Female)" className="w-full p-2 border rounded" />
                </div>

				{/* Emergency Contact */}
				<div className="mt-20">
					<label className="block text-sm font-medium text-gray-700">
						Emergency Contact
					</label>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">Name</label>
						<input
							type="text"
							name="name" // Ensure the name is only 'name', 'relationship', 'phone'
							value={updatedPractitioner.practitioner?.emergency_contact?.name || ""}
							onChange={(e) =>
								handleEmergencyContactChange("name", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">
							Relationship
						</label>
						<input
							type="text"
							name="relationship"
							value={
								updatedPractitioner.practitioner?.emergency_contact?.relationship ||
								""
							}
							onChange={(e) =>
								handleEmergencyContactChange("relationship", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">Phone</label>
						<input
							type="text"
							name="phone"
							value={
								updatedPractitioner.practitioner?.emergency_contact?.phone || ""
							}
							onChange={(e) =>
								handleEmergencyContactChange("phone", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

export default ProfileTab;
