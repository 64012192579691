import React, { Fragment, useState, useEffect } from "react";
import { Dialog, DialogTitle, Transition } from "@headlessui/react";

//API
import { updateSample, Sample, defaultSampleOA, SampleOA } from "../../../../api/labiq/labiqApi";
import { assignGeneCodes, activationOfSampleForPatient } from "../../../../api/healthcare/patientApi";
import { createOrUpdateSamplesPanels, getSamplesPanels, getPanels, Panel } from "../../../../api/labiq/geneCodesApi";

//components
import NavButtons from "./sampleModalComponents/Sidebar";
import SampleInfoTab from "./sampleModalComponents/SampleInfoTab";
import GeneCodesTab from "./sampleModalComponents/GeneCodesTab";
import SampleLabTab from "./sampleModalComponents/SampleLabTab";
import SampleOtherTab from "./sampleModalComponents/SampleOtherTab";

interface PatientSampleModalProps {
	isOpen: boolean;
	onClose: () => void;
	sample: Sample;
	patientId: number;
}

const PatientSampleModal: React.FC<PatientSampleModalProps> = ({ isOpen, onClose, sample, patientId,
}) => {
    const [activeTab, setActiveTab] = useState("Sample");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [updatedSample, setUpdatedSample] = useState<Sample>({
        ...sample,
        sampleOA: sample.sampleOA || defaultSampleOA,
    });
	const [selectedPanels, setSelectedPanels] = useState<number[]>([]);
	const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
        setUpdatedSample({ ...sample, sampleOA: sample.sampleOA || defaultSampleOA });
		if(sample.is_active){
			setIsActive(sample.is_active);
		}
		
	}, [sample]);

	//this will get turned into a call from the input changes and split so that the genecodes only triggers on gene coeds changes
    const updateSampleInfo = async () => {
        setIsSubmitting(true);
        try {
            await updateSample(sample.id, updatedSample);
			await updateGeneCodes();
            onClose();
        } catch (err) {
            console.error("Error updating sample:", err);
            // Optionally, set error state here
        } finally {
            setIsSubmitting(false);
        }
    };

	const updateGeneCodes = async () => {
        try {
            await createOrUpdateSamplesPanels(updatedSample.id, selectedPanels);
			if(updatedSample.authSampleId){
				await activationOfSampleForPatient(isActive, updatedSample.authSampleId);
			}

            if (isActive) {
                await assignGeneCodes(patientId);
            }

        } catch (err) {
            console.error(err);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		const val = value;
	
		setUpdatedSample((prevSample) => {
			const sampleOA = prevSample.sampleOA || { ...defaultSampleOA };
	
			if (["sampleID", "client"].includes(name)) {
				return { ...prevSample, [name]: val, sampleOA };
			} else {
				return {
					...prevSample,
					sampleOA: {
						...sampleOA,
						[name]: val,
					},
				};
			}
		});
	};
	

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-center justify-center min-h-screen px-4">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black opacity-60" />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="bg-white rounded z-20 w-[80vw] h-[80vh] flex">
							{/* Sidebar for NavButtons */}
							<div>
								<NavButtons activeTab={activeTab} setActiveTab={setActiveTab} sample={sample} />
							</div>

							{/* Main Content */}
							<div className="pl-4 flex flex-col p-6" style={{ width: '-webkit-fill-available' }}>
								<div className="flex-1 overflow-y-auto">
									{/* Render tabs based on activeTab */}
									{activeTab === "Sample" && (
										<SampleInfoTab
										sample={updatedSample}
										setSampleData={setUpdatedSample}
										handleInputChange={handleInputChange}
									/>
									)}
									{activeTab === "Gene.Codes" && (
										<GeneCodesTab
											sampleId={updatedSample.id}
											isActive={isActive}
											setIsActive={setIsActive}
											selectedPanels={selectedPanels}
											setSelectedPanels={setSelectedPanels}
										/>
									)}
									{activeTab === "Lab" && (
										<SampleLabTab sample={updatedSample} />
									)}
									{activeTab === "Other" && (
										<SampleOtherTab sample={updatedSample} />
									)}
								</div>

								<div className="flex justify-end space-x-2 mt-6">
									<button
										type="button"
										onClick={onClose}
										className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
									>
										Cancel
									</button>
									<button
										onClick={updateSampleInfo}
										disabled={isSubmitting}
										className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
											}`}
									>
										{isSubmitting ? (sample.id !== 0 ? "Saving..." : "Creating...") : "Save Changes"}
									</button>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default PatientSampleModal;
