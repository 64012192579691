import React, { ReactNode, useMemo, useRef } from "react";

//React table
import { useReactTable, ColumnDef, getCoreRowModel, flexRender } from "@tanstack/react-table";

//REact select dropdown
import Select, { MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';

//Icons
import { FaMinus, FaPlus } from 'react-icons/fa';

//Api routes and interfaces
import { Batch, BatchSample } from "../../../../api/labiq/labiqApi";



interface BatchSamplesProps {
	formData: Partial<Batch>;
	setFormData: React.Dispatch<React.SetStateAction<Partial<Batch>>>;
	reagentList: string[];
	batch_id: number;
}

interface OptionType {
	value: string;
	label: string;
}

const animatedComponents = makeAnimated();

const BatchSamples: React.FC<BatchSamplesProps> = ({ formData, setFormData, reagentList, batch_id }) => {
	const samples = formData.samples || [];

	const handleTextInputChange = (rowIndex: number, field: keyof BatchSample) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		// Directly update the state without re-rendering the whole table
		setFormData((prev) => {
			const updatedSamples = [...(prev.samples || [])];
			updatedSamples[rowIndex] = { ...updatedSamples[rowIndex], [field]: value };
			return { ...prev, samples: updatedSamples };
		});
	};

	const handleSelectChange = (rowIndex: number, field: keyof BatchSample) => (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		setFormData((prev) => {
			const updatedSamples = [...(prev.samples || [])];
			updatedSamples[rowIndex] = { ...updatedSamples[rowIndex], [field]: value };
			return { ...prev, samples: updatedSamples };
		});
	};

	const handleMultiSelectChange = (rowIndex: number) => (selectedOptions: MultiValue<OptionType>) => {
		// Convert the selected options back to an array of strings
		const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
		setFormData((prev) => {
			const updatedSamples = [...(prev.samples || [])];
			updatedSamples[rowIndex] = { ...updatedSamples[rowIndex], bs_test_type: values };
			return { ...prev, samples: updatedSamples };
		});
	};

	const handleRemoveSample = (index: number) => {
		setFormData((prev) => {
			const updatedSamples = [...(prev.samples || [])];
			updatedSamples.splice(index, 1);
			return { ...prev, samples: updatedSamples };
		});
	};

	const handleAddSample = () => {
		setFormData((prev) => ({
			...prev,
			samples: [
				...(prev.samples || []),
				{
					bs_id: 0,
					bs_batch_id: batch_id,
					bs_sample_id: "",
					bs_sequence: "",
					bs_test_type: [],
					bs_collection_type: "",
				},
			],
		}));
	};

	// Prepare options for react-select
	const reagentOptions: OptionType[] = reagentList.map(reagent => ({
		value: reagent,
		label: reagent,
	}));

	// Define columns
	const columns = useMemo<ColumnDef<BatchSample>[]>(
		() => [
			{
				accessorKey: "bs_sample_id",
				header: "Sample ID",
				cell: ({ row }) => {
					// Use row.original instead of samples[row.index]
					const { bs_sample_id } = row.original;
					const rowIndex = row.index;

					return (
						<input
							type="text"
							value={bs_sample_id || ""}
							onChange={handleTextInputChange(rowIndex, "bs_sample_id")}
							className="w-full p-2 border rounded"
						/>
					);
				},
			},
			{
				accessorKey: "bs_test_type",
				header: "Test Type",
				cell: ({ row }) => {
					const rowIndex = row.index;
					const { bs_test_type } = row.original; // Read directly from row.original
					const currentTestTypes = bs_test_type || [];
					const selectedOptions = reagentOptions.filter(option => currentTestTypes.includes(option.value));

					return (
						<Select
							components={animatedComponents}
							options={reagentOptions}
							isMulti
							value={selectedOptions}
							onChange={handleMultiSelectChange(rowIndex)}
							placeholder="Select test types..."
							className="w-full"
						/>
					);
				},
			},
			{
				accessorKey: "bs_collection_type",
				header: "Collection Type",
				cell: ({ row }) => {
					const rowIndex = row.index;
					const { bs_collection_type } = row.original; // Use row.original here
					return (
						<select
							value={bs_collection_type || ""}
							onChange={handleSelectChange(rowIndex, "bs_collection_type")}
							className="w-full p-2 border rounded"
						>
							<option value="" disabled>Select Collection Type</option>
							<option value="copan swab">Copan Swab</option>
							<option value="buccal swab">Buccal Swab</option>
							<option value="DNAtape">DNAtape</option>
							<option value="OraGene saliva">OraGene saliva</option>
						</select>
					);
				},
			},
			{
				id: "actions",
				header: "Actions",
				cell: ({ row }) => {
					const rowIndex = row.index;
					return (
						<div className="flex">
							<button
								type="button"
								onClick={() => handleRemoveSample(rowIndex)}
								className="flex items-center justify-center w-6 h-6 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
							>
								<FaMinus />
							</button>
							<button
								type="button"
								onClick={handleAddSample}
								className="flex items-center justify-center w-6 h-6 bg-green-500 text-white rounded-full ml-1 hover:bg-green-600 focus:outline-none"
							>
								<FaPlus />
							</button>
						</div>
					);
				},
			},
		],
		[reagentList]
	);

	const table = useReactTable({
		data: samples,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div>
			<table className="min-w-full bg-white border table-fixed">
				<thead className="border-b">
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id} className="text-left">
							{headerGroup.headers.map((header) => (
								<th
									key={header.id}
									className="py-2 px-4 border-b w-32"
								>
									{flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr key={row.id} className="hover:bg-gray-100">
							{row.getVisibleCells().map((cell) => (
								<td
									key={cell.id}
									className="py-2 px-4 border-b w-32"
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			{/* If there are no samples show this button to add the first sample */}
			{samples.length === 0 && (
				<div className="p-2">
					<button
						type="button"
						onClick={handleAddSample}
						className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
					>
						Add Sample
					</button>
				</div>
			)}
		</div>
	);
};

export default BatchSamples;
