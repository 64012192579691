import React from 'react';
import { Application } from '../../../api/admin/appApi';

interface AppTableProps {
	apps: Application[];
	onEditClick: (app: Application) => void;
}

const AppTable: React.FC<AppTableProps> = ({ apps, onEditClick }) => {
	// Add a check to handle empty or undefined apps
	if (!apps || apps.length === 0) {
		return <p>No apps available.</p>;
	}

	return (
		<div className="overflow-x-auto">
			<table className="min-w-full bg-white">
				<thead>
					<tr className=" text-align-left py-2 px-4 border-b">
						<th className="py-2 px-4 border-b">ID</th>
						<th className="py-2 px-4 border-b">Logo</th>
						<th className="py-2 px-4 border-b">Name</th>
						<th className="py-2 px-4 border-b">Description</th>
						{/* <th className="py-2 px-4 border-b">Is Active</th> */}
						<th className="py-2 px-4 border-b">Actions</th>
					</tr>
				</thead>
				<tbody>
					{apps.map((app) => (
						<tr key={app.id} className="">
							<td className="py-2 px-4 border-b">{app.id}</td>
							<td className="py-2 px-4 border-b"><img src={app.logo} alt={`${app.name} Logo`} /></td>
							<td className="py-2 px-4 border-b">{app.name}</td>
							<td className="py-2 px-4 border-b">{app.description}</td>
							{/* {app.is_active ? <td className="py-2 px-4 border-b" style={{backgroundColor : 'green'}}>True</td> : <td className="py-2 px-4 border-b" style={{backgroundColor : 'red'}}>False</td>} */}
							<td className="py-2 px-4 border-b">
								<button
									onClick={() => onEditClick(app)}
									className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
								>
									Edit
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default AppTable;
