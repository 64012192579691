import React from 'react';
import { Practitioner } from '../../../api/healthcare/practitionerApi';


interface ProfessionalsTableProps {
    professionals: Practitioner[];
    onEditClick: (practitioner: Practitioner) => void;
}

const ProfessionalsTable: React.FC<ProfessionalsTableProps> = ({ professionals, onEditClick }) => {
    // Add a check to handle empty or undefined patients
    if (!professionals || professionals.length === 0) {
        return <p>No patients available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
                <thead>
                    <tr className='text-left'>
                        <th className="py-2 px-4 border-b">First Name</th>
                        <th className="py-2 px-4 border-b">Last Name</th>
                        <th className="py-2 px-4 border-b">Phone</th>
                        <th className="py-2 px-4 border-b">Email</th>
						<th className="py-2 px-4 border-b">Specialties</th>
                    </tr>
                </thead>
                <tbody>
                    {professionals.map((practitioner) => (
                        <tr
                            key={practitioner.id}
                            className="text-left cursor-pointer hover:bg-gray-100"
                            onClick={() => onEditClick(practitioner)}
                        >
                            <td className="py-2 px-4 border-b">{practitioner.first_name}</td>
                            <td className="py-2 px-4 border-b">{practitioner.last_name}</td>
                            <td className="py-2 px-4 border-b">{practitioner.phone}</td>
                            <td className="py-2 px-4 border-b">{practitioner.email}</td>
							<td className="py-2 px-4 border-b">{practitioner.practitioner?.specialties}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProfessionalsTable;
