import React, { useState } from 'react';
import { Application } from "../../../api/admin/appApi";
import EditApplicationModal from "./EditAppModal";

const createEmptyApplication = (): Application => ({
	id: 0,
	name: "",
	client_id: "",
	client_secret: "",
	redirect_uris: [],
	logo: "",
	description: "",
	created_at: "",
	updated_at: "",
});

const AppButtons: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newApplication, setNewApplication] = useState<Application>(createEmptyApplication());

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCreateApplication = () => {
		handleModalClose();
	};

	return (
		<div>
			<button onClick={handleOpenModal} className='px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300'>Add Application</button>

			{/* Modal */}
			{isModalOpen && (
				<EditApplicationModal isOpen={isModalOpen} onClose={handleModalClose} app={newApplication} onAppUpdated={handleCreateApplication} />
			)}
		</div>
	);
};

export default AppButtons;