import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { fetchPatientSamples, removeSampleFromPatient, assignSampleToPatient, sampleCountOnPatient } from "../../../../api/healthcare/patientApi";
import { createOrUpdateSamplesPanels } from "../../../../api/labiq/geneCodesApi";
import { fetchSamples, fetchSample, Sample } from "../../../../api/labiq/labiqApi";

//loading icon
import LoadingSpinner from '../../../icons/LoadingSpinner';

//import modal components
import CreateSampleModal from "./CreateSampleSimpleModal";
import PatientSampleModal from "./PatientSampleModal"

interface SampleTabProps {
	patient: any;
	isSubmitting: boolean;
	setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
	setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
}

const SampleTab: React.FC<SampleTabProps> = ({ patient, isSubmitting, setIsSubmitting, setErrorMessages }) => {
	const [samples, setSamples] = useState<Sample[]>([]);
	const [isCreateSampleModalOpen, setIsCreateSampleModalOpen] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	//SampleModal
	const [sampleModalOpen, setSampleModalOpen] = useState<boolean>(false);
	const [selectedSample, setSelectedSample] = useState<Sample | null>();


	useEffect(() => {
		
		if (patient.id !== 0) {
			fetchPatientSample();
		}
		
	}, [patient.id]);

	//on load get the samples for the patient
	const fetchPatientSample = async () => {
		setIsLoading(true);
		try {
			const result = await fetchPatientSamples(patient.email);

			//now that we have all of the samples assigned toa patient, loop through and get all of the detailed information for them
			let samples: Sample[] = [];
			for (let i = 0; i < result.length; i++) {
				const tempSample = result[i];
				const result2: Sample = await fetchSample(tempSample.gene_sample_id);
				result2.is_active = tempSample.is_active;
				result2.authSampleId = tempSample.id;
				samples.push(result2);
			}

			setSamples(samples);
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		}
		setIsLoading(false);
	};

	
	const removePatientFromSample = async (sampleClientID: string, sampleId: number) => {
		setIsSubmitting(true);
		try {
			await removeSampleFromPatient(patient.email, sampleClientID);
			await createOrUpdateSamplesPanels(sampleId, []);

			//decrease the samplecount on patient profile
			await sampleCountOnPatient(patient.email, "gene", "decrement");

			//remove the stock assignment
			

			const updatedSamples = await fetchPatientSamples(patient.email);
			setSamples(updatedSamples);
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const assignSample = async (sampleClientId: string) => {
		try {
			await assignSampleToPatient(patient.email, sampleClientId)
			//increment the sample copunt for the user
			await sampleCountOnPatient(patient.email, "gene", "increment");

			//reload the patient list
			await fetchPatientSample();
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		}
	}

	const searchSamples = async (term: string) => {

		try {
			const userInfo = JSON.parse(sessionStorage.getItem("user") || "{}");
			const company = userInfo.company;

			const { data } = await fetchSamples(1, 20, company, "sampleID", "desc", term);
			console.log(data);
			setSearchResults(data); // Set the search results for the dropdown
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		}
	};

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const term = e.target.value;
		setSearchTerm(term);
		if (term) {
			searchSamples(term);
		} else {
			setSearchResults([]); // Clear results when input is empty
		}
	};

	//Patient Sample Modal
	const openSampleModal = async(sample:Sample) => {
		setSelectedSample(sample);
		setSampleModalOpen(true);
	}

	const closeSampleModal = async () => {
		setSelectedSample(null);
		setSampleModalOpen(false);
		fetchPatientSample();
	}

	return (
		<div className="grid grid-cols-1 relative">
			{isLoading ? (
				<LoadingSpinner size={60} color="#354396" />
			) : (
				<div>
					<div className="flex justify-end mb-4">
						<input type="text" placeholder="Search existing samples..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded mr-10" />
						{/* Search results dropdown */}
						{searchResults.length > 0 && (
							<div className="absolute mt-1 w-full max-w-xs bg-white border border-gray-200 rounded shadow-lg z-10" style={{ marginTop: "40px" }}>
								{searchResults.map((sample) => (
									<div
										key={sample.sampleID}
										onClick={() => {
											setSearchTerm("");
											setSearchResults([]);
											assignSample(sample.sampleID);
										}}
										className="p-2 hover:bg-gray-100 cursor-pointer"
									>
										<span>
											{sample.sampleID}, {new Date(sample.created_at).toLocaleDateString("en-GB")}, {sample.sampleOA?.status || "N/A"}
										</span>
									</div>
								))}
							</div>
						)}
						<button type="button" onClick={() => setIsCreateSampleModalOpen(true)} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
							Assign Kit
						</button>
					</div>
					<table className="min-w-full bg-white">
						<thead>
							<tr className='text-left'>
								<th className="py-2 px-4 border-b">SampleID</th>
								<th className="py-2 px-4 border-b">Logistics</th>
								<th className="py-2 px-4 border-b">Lab Status</th>
								<th className="py-2 px-4 border-b">SNP's</th>
								<th className="py-2 px-4 border-b">Actions</th>

							</tr>
						</thead>
						<tbody>
							{samples.length > 0 ? (
								samples.map((sample) => (
									<tr
										key={sample.sampleID}
										className="text-left cursor-pointer hover:bg-gray-100"
									    onClick={() => openSampleModal(sample)}
									>
										<td className="py-2 px-4 border-b">{sample.sampleID}</td>
										<td className="py-2 px-4 border-b">N/A</td>
										<td className="py-2 px-4 border-b">{sample.sampleOA?.status}</td>
										{/* need to return SNP's for the smaple with every sample */}
										<td className="py-2 px-4 border-b">Not yet implemented</td>
										<td className="py-2 px-4 border-b">

											
											<button
												type="button"
												onClick={() => removePatientFromSample(sample.sampleID || sample.sampleID, sample.id)}
												disabled={isSubmitting}
												className={`px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
											>
												{isSubmitting ? "Processing..." : "Remove"}
											</button></td>

									</tr>

								))
							) : (
								<tr>
									<td className="py-2 px-4 border-b">No samples found</td>
								</tr>
							)}
						</tbody>
					</table>


					{/* modals for the users samples */}
					{selectedSample && sampleModalOpen && <PatientSampleModal isOpen={sampleModalOpen} onClose={closeSampleModal} sample={selectedSample} patientId={patient.id}/>}
					<CreateSampleModal isOpen={isCreateSampleModalOpen} onClose={() => setIsCreateSampleModalOpen(false)} setSamples={setSamples} patient={patient} />
				</div>
				
			)}
		</div>

	);
};

export default SampleTab;
