import React, { ChangeEvent } from "react";
import {  Sample } from "../../../../../api/labiq/labiqApi";

interface SampleLabProps {
    sample: Sample;

}

const SampleLab: React.FC<SampleLabProps> = ({ sample }) => {
    return (
        <div className="grid grid-cols-2">
            {/* Column 1 */}
            <div className="p-6">
                {/* QC Date */}
                <div className="p-2">
                    <label htmlFor="QC_date" className="block text-sm">
                        QC Date
                    </label>
                    <input disabled type="date" id="QC_date" name="QC_date" value={sample.sampleOA?.QC_date || ""}  placeholder="Enter QC Date" className="w-full p-2 border rounded" />
                </div>
                {/* Concentration */}
                <div className="p-2">
                    <label htmlFor="concentration" className="block text-sm">
                        Concentration
                    </label>
                    <input disabled type="text" id="concentration" name="concentration" value={sample.sampleOA?.concentration || ""}  placeholder="Enter concentration" className="w-full p-2 border rounded" />
                </div>
                {/* OD */}
                <div className="p-2">
                    <label htmlFor="OD" className="block text-sm">
                        Absorbance 260/280
                    </label>
                    <input disabled type="text" id="OD" name="OD" value={sample.sampleOA?.OD || ""}  placeholder="Enter Absorbance 260/280" className="w-full p-2 border rounded" />
                </div>
                {/* OD2 */}
                <div className="p-2">
                    <label htmlFor="OD2" className="block text-sm">
                        Absorbance 260/230
                    </label>
                    <input disabled type="text" id="OD2" name="OD2" value={sample.sampleOA?.OD2 || ""}  placeholder="Enter Absorbance 260/230" className="w-full p-2 border rounded" />
                </div>
                {/* Plated Status */}
                <div className="p-2">
                    <label htmlFor="plated_state" className="block text-sm">
                        Plated Status
                    </label>
                    <select disabled id="plated_state" name="plated_state" value={sample.sampleOA?.plated_state || ""}  className="w-full p-2 border rounded">
                        <option value="" disabled>
                            Select Plated Status
                        </option>
                        <option value="plated">Plated</option>
                        <option value="next_run">Next Run</option>
                    </select>
                </div>
            </div>

            {/* Column 2 */}
            <div className="p-6">
                {/* Enhanced Protocol */}
                <div className="p-2">
                    <label htmlFor="eProtocol" className="block text-sm">
                        Enhanced Protocol
                    </label>
                    <select disabled id="eProtocol" name="eProtocol" value={sample.sampleOA?.eProtocol || ""}  className="w-full p-2 border rounded">
                        <option value="" disabled>
                            Select Protocol
                        </option>
                        <option value="concentration">Concentration</option>
                        <option value="dilution">Dilution</option>
                        <option value="purification">Purification</option>
                    </select>
                </div>
                {/* New Concentration */}
                <div className="p-2">
                    <label htmlFor="eP_concentration" className="block text-sm">
                        New Concentration
                    </label>
                    <input disabled type="text" id="eP_concentration" name="eP_concentration" value={sample.sampleOA?.eP_concentration || ""}  placeholder="Enter New Concentration" className="w-full p-2 border rounded" />
                </div>
                {/* New OD */}
                <div className="p-2">
                    <label htmlFor="eP_OD" className="block text-sm">
                        New Absorbance 260/280
                    </label>
                    <input disabled type="text" id="eP_OD" name="eP_OD" value={sample.sampleOA?.eP_OD || ""}  placeholder="Enter New Absorbance 260/280" className="w-full p-2 border rounded" />
                </div>
                {/* New OD2 */}
                <div className="p-2">
                    <label htmlFor="eP_OD2" className="block text-sm">
                        New Absorbance 260/230
                    </label>
                    <input disabled type="text" id="eP_OD2" name="eP_OD2" value={sample.sampleOA?.eP_OD2 || ""}  placeholder="Enter New Absorbance 260/230" className="w-full p-2 border rounded" />
                </div>
				{/* Job Sequence */}
				<div className="p-2">
					<label htmlFor="job_sequence" className="block text-sm">
						Sequence
					</label>
					<input
						disabled
						type="text"
						id="job_sequence"
						name="job_sequence"
						value={sample.sampleOA?.job_sequence || ""}

						placeholder="Enter sequence"
						className="w-full p-2 border rounded"
					/>
				</div>
            </div>
        </div>
    );
};

export default SampleLab;
