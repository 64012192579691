// src/components/Navbar.tsx
import React from "react";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2 flex justify-between items-center">
            <div className="text-xl font-semibold">MyGene Management System</div>
            
        </nav>
    );
};

export default Navbar;
