import React from "react";
import { User } from "../../api/admin/userApi";

interface UserProfileAddressProps {
    user: User;
    isEditing: boolean;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileAddress: React.FC<UserProfileAddressProps> = ({ user, isEditing, setUserData }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevUser) => ({ ...prevUser, [name]: value }));
    };

    return (
        <div>
            <h2 className="text-xl font-medium text-gray-700 mb-4">Address Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {["address", "city", "state", "country", "postal_code"].map((field) => (
                    <div key={field}>
                        <p className="text-sm text-gray-600">{field.replace("_", " ").toUpperCase()}</p>
                        {isEditing ? (
                            <input type="text" name={field} value={user[field as keyof User] as string} onChange={handleChange} className="w-full border-b border-gray-300 focus:outline-none" />
                        ) : (
                            <p className="text-base text-gray-800">{user[field as keyof User] as string}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserProfileAddress;
