// src/components/icons/HelixWithNumber.tsx

import React from 'react';
import { BiDna } from 'react-icons/bi'; // Updated icon import

// Define the props interface
interface HelixIconWithNumberProps {
	count: number;
}

// Define the component with typed props
const HelixIconWithNumber: React.FC<HelixIconWithNumberProps> = ({ count }) => {
	return (
		<div className="relative inline-block">

			{count > 0 ? (
				<div>
					<BiDna className="text-blue-500 w-6 h-6" aria-label="Gene Samples" />
					<span className="absolute top-0 right-0 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
						{count}
					</span>
				</div>
			) : (
				<BiDna className="text-grey-500 w-6 h-6" aria-label="Gene Samples" />
			)}
		</div>
	);
};

export default HelixIconWithNumber;
