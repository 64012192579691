import React, { useState } from 'react';

interface ProfessionalsButtonsProps {
	searchProfessionals: (search: string) => void;
	reloadProfessionals: () => void;
	handleNewPractitioner: () => void;
}

const ProfessionalsButtons: React.FC<ProfessionalsButtonsProps> = ({searchProfessionals, reloadProfessionals, handleNewPractitioner}) => {
	const [searchTerm, setSearchTerm] = useState("");

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        searchProfessionals(e.target.value);
    };

	const handleOpenModal = () => {
		handleNewPractitioner();
	};


	return (
        <div>
            <div className="mb-4 flex justify-between">
                <button onClick={handleOpenModal} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                    Create Practitioner
                </button>
                <div className="justify-end">
                    <input type="text" placeholder="Search practitioners..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
                    <button onClick={reloadProfessionals} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                        Reset
                    </button>
                </div>
            </div>

            </div>
    );
};

export default ProfessionalsButtons;