import React, { ChangeEvent } from "react";
import {  Sample } from "../../../../../api/labiq/labiqApi";

interface SampleOtherProps {
    sample: Sample;
    
}

const SampleOther: React.FC<SampleOtherProps> = ({ sample }) => {
    return (
        <div className="grid grid-cols-1">
            <div className="p-2">
                <label htmlFor="comments" className="block text-sm">
                    Comments
                </label>
                <textarea disabled id="comments" name="comments" value={sample.sampleOA?.comments || ""}  placeholder="Enter comments" className="w-full p-2 border rounded h-32" />
            </div>
            <div className="p-2">
                <label htmlFor="lab_location" className="block text-sm">
                    Lab Location
                </label>
                <input disabled type="text" id="lab_location" name="lab_location" value={sample.sampleOA?.lab_location || ""} placeholder="Enter lab location" className="w-full p-2 border rounded" />
            </div>
            <div className="p-2">
                <label htmlFor="available_material" className="block text-sm">
                    Available Material
                </label>
                <input disabled type="text" id="available_material" name="available_material" value={sample.sampleOA?.available_material || ""} placeholder="Enter available material" className="w-full p-2 border rounded" />
            </div>
        </div>
    );
};

export default SampleOther;
