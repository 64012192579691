import React, { ChangeEvent, useState, useEffect } from "react";
import axios from "axios";
import { fetchReagentList, SampleFormData } from "../../../../api/labiq/labiqApi";
import { fetchCompanies, Company } from "../../../../api/admin/companyApi";

interface SampleDetailsProps {
    formData: SampleFormData;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
    setFormData: React.Dispatch<React.SetStateAction<SampleFormData>>;
	companies: Company[];
}

const SampleDetails: React.FC<SampleDetailsProps> = ({
    formData,
    handleChange,
    handleSelectChange,
    setErrorMessages,
    setFormData,
	companies,
}) => {
    
    const [reagentList, setReagentList] = useState<string[]>([]);


    // Init on the first render
    useEffect(() => {
        const fetchReagents = async () => {
            try {
                const reagents = await fetchReagentList();
                setReagentList(reagents);
            } catch (error:unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages); // State to store an array of error messages
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); // Fallback to general error message
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			}
        };
        
        fetchReagents();
    }, [setErrorMessages]);

    return (
        <div className="grid grid-cols-2">
            {/* Column 1 */}
            <div className="p-6">
                {/* Client Sample ID */}
                <div className="p-2">
                    <label htmlFor="sampleID" className="block text-sm">
                        Sample ID
                    </label>
                    <input
                        type="text"
                        id="sampleID"
                        name="sampleID"
                        value={formData.sampleID || ""}
                        onChange={handleChange}
                        placeholder="Enter sample ID"
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
                {/* Client if the user is dnaiq or mhygene*/}
				{companies.length > 0 && (
					<div className="p-2">
                    <label htmlFor="client" className="block text-sm">
                        Client
                    </label>
                    <select
                        id="client"
                        name="client"
                        value={formData.client || ""}
                        onChange={handleSelectChange}
                        className="w-full p-2 border rounded"
                        required
                    >
                        <option value="" disabled>
                            Select Client
                        </option>
                        {companies.map((company) => (
                            <option key={company.name} value={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
				)}
                
                {/* Analysis Requested */}
                <div className="p-2">
                    <label htmlFor="analysis_requested" className="block text-sm">
                        Analysis Requested
                    </label>
                    {reagentList.map((reagent) => (
                        <label key={reagent} className="flex items-center mr-4 mb-2">
                            <input
                                type="checkbox"
                                name="analysis_requested"
                                value={reagent}
                                checked={formData.analysis_requested?.includes(reagent) || false}
                                onChange={(e) => {
                                    const { checked, value } = e.target;
                                    setFormData((prev) => {
                                        const analysisRequested = prev.analysis_requested
                                            ? [...prev.analysis_requested]
                                            : [];
                                        if (checked) {
                                            if (!analysisRequested.includes(value)) {
                                                analysisRequested.push(value);
                                            }
                                        } else {
                                            const idx = analysisRequested.indexOf(value);
                                            if (idx > -1) {
                                                analysisRequested.splice(idx, 1);
                                            }
                                        }
                                        return { ...prev, analysis_requested: analysisRequested };
                                    });
                                }}
                                className="mr-2"
                            />
                            {reagent}
                        </label>
                    ))}
                </div>
            </div>

            {/* Column 2 */}
            <div className="p-6">
                
                {/* Sample Type */}
                <div className="p-2">
                    <label htmlFor="sampleType" className="block text-sm">
                        Collection Type
                    </label>
                    <select
                        id="sampleType"
                        name="sampleType"
                        value={formData.sampleType || ""}
                        onChange={handleSelectChange}
                        className="w-full p-2 border rounded"
                    >
                        <option value="" disabled>
                            Select Collection Type
                        </option>
                        <option value="copan swab">Copan Swab</option>
                        <option value="buccal swab">Buccal Swab</option>
                        <option value="DNAtape">DNAtape</option>
                        <option value="OraGene saliva">OraGene saliva</option>
                    </select>
                </div>
                {/* Status */}
                <div className="p-2">
                    <label htmlFor="status" className="block text-sm">
                        Status
                    </label>
                    <select
                        id="status"
                        name="status"
                        value={formData.status || ""}
                        onChange={handleSelectChange}
                        className="w-full p-2 border rounded"
                    >
                        <option value="" disabled>
                            Select Status
                        </option>
                        <option value="submitted">Submitted</option>
                        <option value="received">Received</option>
                        <option value="allocated">Allocated</option>
                        <option value="hold">Hold</option>
                        <option value="pending">Pending</option>
                        <option value="rerun">Rerun</option>
                        <option value="recollect">Recollect</option>
                        <option value="complete">Complete</option>
                        <option value="destroy">Destroy</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default SampleDetails;
