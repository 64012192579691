// src/components/healthcare/practitioners/modalComponents/PractitionerInfoTab.tsx

import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";

interface EducationTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

const EducationTab: React.FC<EducationTabProps> = ({ updatedPractitioner, setUpdatedPractitioner, }) => {


	// Handler for education history
	const handleEducationChange = (index: number, field: keyof EducationEntry, value: string) => {
		setUpdatedPractitioner((prev) => {
			const prevEducation = prev.practitioner?.education_history || [];
			const updatedEducation = prevEducation.map((entry: EducationEntry, idx: number) => {
				if (idx === index) {
					return {
						...entry,
						[field]: value,
					};
				}
				return entry;
			});
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					education_history: updatedEducation,
				},
			};
		});
	};

	const addEducationEntry = () => {
		setUpdatedPractitioner((prev) => {
			const prevEducation = prev.practitioner?.education_history || [];
			const updatedEducation = [
				...prevEducation,
				{ degree: "", institution: "", year: "" },
			];
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					education_history: updatedEducation,
				},
			};
		});
	};

	const removeEducationEntry = (index: number) => {
		setUpdatedPractitioner((prev) => {
			const prevEducation = prev.practitioner?.education_history || [];
			const updatedEducation = prevEducation.filter((_: EducationEntry, idx: number) => idx !== index);
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					education_history: updatedEducation,
				},
			};
		});
	};

	// Types for nested objects
	interface EducationEntry {
		degree: string;
		institution: string;
		year: string;
	}


	return (
		<div className="grid grid-cols-1 gap-6 p-6">

			<div>
				{/* Education History */}
				<div className="mb-4">
					<label className="block text-sm font-medium text-gray-700">
						Education History
					</label>
					{updatedPractitioner.practitioner?.education_history?.map(
						(entry: EducationEntry, index: number) => (
							<div key={index} className="mb-2 border p-2 rounded-md">
								<div className="flex justify-between">
									<span className="font-semibold">Education {index + 1}</span>
									<button
										type="button"
										onClick={() => removeEducationEntry(index)}
										className="text-red-500 hover:text-red-700"
									>
										Remove
									</button>
								</div>
								<div className="mt-2">
									<label className="block text-sm font-medium text-gray-700">
										Degree
									</label>
									<input
										type="text"
										value={entry.degree}
										onChange={(e) =>
											handleEducationChange(index, "degree", e.target.value)
										}
										className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
									/>
								</div>
								<div className="mt-2">
									<label className="block text-sm font-medium text-gray-700">
										Institution
									</label>
									<input
										type="text"
										value={entry.institution}
										onChange={(e) =>
											handleEducationChange(index, "institution", e.target.value)
										}
										className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
									/>
								</div>
								<div className="mt-2">
									<label className="block text-sm font-medium text-gray-700">
										Year
									</label>
									<input
										type="text"
										value={entry.year}
										onChange={(e) =>
											handleEducationChange(index, "year", e.target.value)
										}
										className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
									/>
								</div>
							</div>
						)
					)}
					<button
						type="button"
						onClick={addEducationEntry}
						className="mt-2 px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
					>
						Add Education Entry
					</button>
				</div>

			</div>
		</div>
	);
};

export default EducationTab;
