import React, { useState } from 'react';


interface ActionButtonsProps {
	createCompany : () => void;
	searchCompanies: (search: string) => void;
	reloadCompanies: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({createCompany, searchCompanies, reloadCompanies}) => {

	const [searchTerm, setSearchTerm] = useState("");

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        searchCompanies(searchTerm);
    };

	const handleCreateCompany = () => {
		createCompany();
	};

	return (
        <div>
            <div className="mb-4 flex justify-between">
                <button onClick={handleCreateCompany} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                    Create Company
                </button>
                <div className="justify-end">
                    <input type="text" placeholder="Search companies..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
                    <button onClick={reloadCompanies} className="px-4 py-2 bg-gray-200 rounded opacity-100 hover:bg-gray-300">
                        Reset
                    </button>
                </div>
            </div>

        </div>
    );
};

export default ActionButtons;