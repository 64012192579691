// src/components/healthcare/practitioners/modalComponents/PractitionerInfoTab.tsx

import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";

interface PractitionerInfoTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

const PractitionerInfoTab: React.FC<PractitionerInfoTabProps> = ({ updatedPractitioner, setUpdatedPractitioner, }) => {
	// Handler for simple input changes
	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: value,
			},
		}));
	};

	// Handler for array inputs (comma-separated)
	const handleArrayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const arrayValues = value.split(",").map((item) => item.trim());
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: arrayValues,
			},
		}));
	};


	return (
		<div className="grid grid-cols-2 gap-6 p-6">
			{/* Column 1 */}
			<div>
				{/* Medical License Number */}
				<div className="mb-4">
					<label
						htmlFor="medical_license_number"
						className="block text-sm font-medium text-gray-700"
					>
						Medical License Number
					</label>
					<input
						type="text"
						id="medical_license_number"
						name="medical_license_number"
						value={updatedPractitioner.practitioner?.medical_license_number || ""}
						onChange={handleInputChange}
						placeholder="Enter medical license number"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* NPI Number */}
				<div className="mb-4">
					<label
						htmlFor="npi_number"
						className="block text-sm font-medium text-gray-700"
					>
						NPI Number
					</label>
					<input
						type="text"
						id="npi_number"
						name="npi_number"
						value={updatedPractitioner.practitioner?.npi_number || ""}
						onChange={handleInputChange}
						placeholder="Enter NPI number"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* DEA Number */}
				<div className="mb-4">
					<label
						htmlFor="dea_number"
						className="block text-sm font-medium text-gray-700"
					>
						DEA Number
					</label>
					<input
						type="text"
						id="dea_number"
						name="dea_number"
						value={updatedPractitioner.practitioner?.dea_number || ""}
						onChange={handleInputChange}
						placeholder="Enter DEA number"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Supervisor ID */}
				<div className="mb-4">
					<label
						htmlFor="supervisor_id"
						className="block text-sm font-medium text-gray-700"
					>
						Supervisor ID
					</label>
					<input
						type="number"
						id="supervisor_id"
						name="supervisor_id"
						value={updatedPractitioner.practitioner?.supervisor_id || ""}
						onChange={handleInputChange}
						placeholder="Enter supervisor ID"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
						min="0"
					/>
				</div>

				{/* Taxonomy Code */}
				<div className="mb-4">
					<label
						htmlFor="taxonomy_code"
						className="block text-sm font-medium text-gray-700"
					>
						Taxonomy Code
					</label>
					<input
						type="text"
						id="taxonomy_code"
						name="taxonomy_code"
						value={updatedPractitioner.practitioner?.taxonomy_code || ""}
						onChange={handleInputChange}
						placeholder="Enter taxonomy code"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Specialties */}
				{/* Turn this into a dropdown based off of the practitioiner typoes db */}
				<div className="mb-4">
					<label
						htmlFor="specialties"
						className="block text-sm font-medium text-gray-700"
					>
						Specialties
					</label>
					<input
						type="text"
						id="specialties"
						name="specialties"
						value={updatedPractitioner.practitioner?.specialties?.join(", ") || ""}
						onChange={handleArrayChange}
						placeholder="e.g., Cardiology, Pediatrics"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Years of Experience */}
				<div className="mb-4">
					<label
						htmlFor="years_of_experience"
						className="block text-sm font-medium text-gray-700"
					>
						Years of Experience
					</label>
					<input
						type="number"
						id="years_of_experience"
						name="years_of_experience"
						value={updatedPractitioner.practitioner?.years_of_experience || 0}
						onChange={handleInputChange}
						placeholder="Enter years of experience"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
						min="0"
					/>
				</div>

				
			</div>

			{/* Column 2 */}
			<div>
				{/* Linkage */}
				<div className="mb-4">
					<label
						htmlFor="linkage"
						className="block text-sm font-medium text-gray-700"
					>
						Linkage
					</label>
					<input
						type="text"
						id="linkage"
						name="linkage"
						value={updatedPractitioner.practitioner?.linkage || ""}
						onChange={handleInputChange}
						placeholder="e.g., MD, PhD"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>
				{/* Qualifications */}
				<div className="mb-4">
					<label
						htmlFor="qualifications"
						className="block text-sm font-medium text-gray-700"
					>
						Qualifications
					</label>
					<input
						type="text"
						id="qualifications"
						name="qualifications"
						value={updatedPractitioner.practitioner?.qualifications?.join(", ") || ""}
						onChange={handleArrayChange}
						placeholder="e.g., MD, PhD"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Certifications */}
				<div className="mb-4">
					<label
						htmlFor="certifications"
						className="block text-sm font-medium text-gray-700"
					>
						Certifications
					</label>
					<input
						type="text"
						id="certifications"
						name="certifications"
						value={updatedPractitioner.practitioner?.certifications?.join(", ") || ""}
						onChange={handleArrayChange}
						placeholder="e.g., Board Certified, Fellow"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Professional Memberships */}
				<div className="mb-4">
					<label
						htmlFor="professional_memberships"
						className="block text-sm font-medium text-gray-700"
					>
						Professional Memberships
					</label>
					<input
						type="text"
						id="professional_memberships"
						name="professional_memberships"
						value={updatedPractitioner.practitioner?.professional_memberships?.join(", ") || ""}
						onChange={handleArrayChange}
						placeholder="e.g., AMA, AHA"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Biography */}
				<div className="mb-4">
					<label
						htmlFor="biography"
						className="block text-sm font-medium text-gray-700"
					>
						Biography
					</label>
					<textarea
						id="biography"
						name="biography"
						value={updatedPractitioner.practitioner?.biography || ""}
						onChange={handleInputChange}
						placeholder="Enter biography"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
						rows={4}
					/>
				</div>

				{/* Languages Spoken */}
				<div className="mb-4">
					<label
						htmlFor="languages_spoken"
						className="block text-sm font-medium text-gray-700"
					>
						Languages Spoken
					</label>
					<input
						type="text"
						id="languages_spoken"
						name="languages_spoken"
						value={updatedPractitioner.practitioner?.languages_spoken?.join(", ") || ""}
						onChange={handleArrayChange}
						placeholder="e.g., English, Spanish"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>
			</div>
		</div>
	);
};

export default PractitionerInfoTab;
