import React, { ChangeEvent, useState, useEffect } from "react";
import { fetchReagentList, Sample, defaultSampleOA } from "../../../../../api/labiq/labiqApi";
import { fetchCompanies, Company } from "../../../../../api/admin/companyApi";

interface SampleInfoTabProps {
    sample: Sample;
    setSampleData: React.Dispatch<React.SetStateAction<Sample>>;
    handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const SampleInfoTab: React.FC<SampleInfoTabProps> = ({ sample, setSampleData, handleInputChange }) => {
	const [companies, setCompanies] = useState<Company[]>([]);
	const [reagentList, setReagentList] = useState<string[]>([]);

	// Init on the first render
	useEffect(() => {
		const fetchReagents = async () => {
			try {
				const reagents = await fetchReagentList();
				setReagentList(reagents);
			} catch (err) {
				console.error("Error fetching reagents:", err);
			}
		};
		const getCompanies = async () => {
			try {
				const data = await fetchCompanies(1, 100, "");
				setCompanies(data.data);
			} catch (error) {
				console.error("Error fetching companies:", error);
			}
		};

		//find out if the client can see all companies or just their own?
		const user = JSON.parse(sessionStorage.getItem("user") || "{}")
		if(user.company == "Mygene" || user.company == "DNAIQ" ){
			getCompanies();
		}else{
			let company: Company[] = [{
				id: 0, 
				name: user.company,
				address: null,
				email: '', 
				phone: null,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString()
			  }];
			setCompanies(company);
		}
		fetchReagents();
	}, []);

	return (
        <div className="grid grid-cols-2">
            {/* Column 1 */}
            <div className="p-6">
                {/* Client Sample ID */}
                <div className="p-2">
                    <label htmlFor="sampleID" className="block text-sm">
                        Sample ID
                    </label>
                    <input
                        type="text"
                        id="sampleID"
                        name="sampleID"
                        value={sample.sampleID || ""}
                        onChange={handleInputChange}
                        placeholder="Enter sample ID"
                        className="w-full p-2 border rounded"
                        required
                    />
                </div>
                {/* Client */}
                <div className="p-2">
                    <label htmlFor="client" className="block text-sm">
                        Client
                    </label>
                    <select
                        id="client"
                        name="client"
                        value={sample.client || ""}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded"
                        required
                    >
                        <option value="" disabled>
                            Select Client
                        </option>
                        {companies.map((company) => (
                            <option key={company.name} value={company.name}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
                {/* Analysis Requested */}
                <div className="p-2">
                    <label htmlFor="analysis_requested" className="block text-sm">
                        Analysis Requested
                    </label>
                    {reagentList.map((reagent) => (
                        <label key={reagent} className="flex items-center mr-4 mb-2">
                            <input
                                type="checkbox"
                                name="analysis_requested"
                                value={reagent}
                                checked={sample.sampleOA?.analysis_requested?.includes(reagent) || false}
                                onChange={(e) => {
									const { checked, value } = e.target;
									setSampleData((prevSample) => {
										const sampleOA = prevSample.sampleOA || { ...defaultSampleOA };
										const prevAnalysisRequested = sampleOA.analysis_requested || [];
										let newAnalysisRequested;
								
										if (checked) {
											newAnalysisRequested = [...prevAnalysisRequested, value];
										} else {
											newAnalysisRequested = prevAnalysisRequested.filter(
												(item) => item !== value
											);
										}
								
										return {
											...prevSample,
											sampleOA: {
												...sampleOA,
												analysis_requested: newAnalysisRequested,
											},
										};
									});
								}}
                                className="mr-2"
                            />
                            {reagent}
                        </label>
                    ))}
                </div>
            </div>

            {/* Column 2 */}
            <div className="p-6">
                {/* Sample Type */}
                <div className="p-2">
                    <label htmlFor="sampleType" className="block text-sm">
                        Collection Type
                    </label>
                    <select
                        id="sampleType"
                        name="sampleType"
                        value={sample.sampleOA?.sampleType || ""}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded"
                    >
                        <option value="" disabled>
                            Select Collection Type
                        </option>
                        <option value="copan swab">Copan Swab</option>
                        <option value="buccal swab">Buccal Swab</option>
                        <option value="DNAtape">DNAtape</option>
                        <option value="OraGene saliva">OraGene saliva</option>
                    </select>
                </div>
                {/* Status */}
                <div className="p-2">
                    <label htmlFor="status" className="block text-sm">
                        Lab Status
                    </label>
                    <select
                        id="status"
                        name="status"
                        value={sample.sampleOA?.status || ""}
                        onChange={handleInputChange}
                        className="w-full p-2 border rounded"
                    >
                        <option value="" disabled>
                            Select Status
                        </option>
                        <option value="submitted">Submitted</option>
                        <option value="received">Received</option>
                        <option value="allocated">Allocated</option>
                        <option value="hold">Hold</option>
                        <option value="pending">Pending</option>
                        <option value="rerun">Rerun</option>
                        <option value="recollect">Recollect</option>
                        <option value="complete">Complete</option>
                        <option value="destroy">Destroy</option>
                    </select>
                </div>
            </div>
        </div>
    );
};
export default SampleInfoTab;
