import React from "react";
import { Patient } from "../../../../api/healthcare/patientApi";

interface SidebarProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    patient: Patient;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, patient }) => {
    const user = JSON.parse(sessionStorage.getItem("user") || "{}");

    return (
        <div className="bg-gray-800 text-white w-64 min-h-full flex flex-col justify-between p-4">
            {/* Profile Section */}
            <div className="flex flex-col items-center mb-6">
                <img
                    className="w-40 h-40 rounded-full object-cover"
                    src={patient.profile_picture || "/assets/images/default-profile.png"}
                    alt={`${patient.first_name} ${patient.last_name}`}
                />
                <span className="mt-2 font-medium">
                    {patient.first_name} {patient.last_name}
                </span>
            </div>

            {/* Navigation Links */}
            <div className="flex-1">
                <button
                    onClick={() => setActiveTab("Personal")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Personal" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Personal Info</span>
                </button>

				<button
                    onClick={() => setActiveTab("Samples")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Samples" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Genetic Samples</span>
                </button>

                <button
                    onClick={() => setActiveTab("Practitioners")}
                    className={`flex items-center px-4 py-2 text-left rounded ${
                        activeTab === "Practitioners" ? "bg-gray-700" : "hover:bg-gray-700"
                    }`} style={{width: '-webkit-fill-available'}}
                >
                    <span className="ml-3">Healthcare Practitioners</span>
                </button>
                

                {user.company === "Mygene" && (
                    <button
                        onClick={() => setActiveTab("MygeneProgramStatus")}
                        className={`flex items-center px-4 py-2 text-left rounded ${
                            activeTab === "MygeneProgramStatus" ? "bg-gray-700" : "hover:bg-gray-700"
                        }`} style={{width: '-webkit-fill-available'}}
                    >
                        <span className="ml-3">Mygene Program Status</span>
                    </button>
				)}

				<button
					onClick={() => setActiveTab("Files")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Files" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Files</span>
				</button>
            </div>

            
        </div>
    );
};

export default Sidebar;
