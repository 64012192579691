import React from 'react';
import { Role } from '../../../api/admin/rolesApi';

interface RoleTableProps {
	roles: Role[];
	onEditClick: (role: Role) => void;
}

const RoleTable: React.FC<RoleTableProps> = ({ roles, onEditClick }) => {
	// Add a check to handle empty or undefined roles
	if (!roles || roles.length === 0) {
		return <p>No roles available.</p>;
	}

	return (
		<div className="overflow-x-auto">
			<table className="min-w-full bg-white">
				<thead>
					<tr>
						<th className="py-2 px-4 border-b">ID</th>
						<th className="py-2 px-4 border-b">Name</th>
						<th className="py-2 px-4 border-b">Guard</th>
					</tr>
				</thead>
				<tbody>
					{roles.map((role) => (
						<tr
						key={role.id}
						className="cursor-pointer hover:bg-gray-100"
						onClick={() => onEditClick(role)}
					>
							<td className="py-2 px-4 border-b">{role.id}</td>
							<td className="py-2 px-4 border-b">{role.name}</td>
							<td className="py-2 px-4 border-b">{role.guard_name}</td>

						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default RoleTable;

