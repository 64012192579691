import React from "react";
import { Sample } from "../../../../api/labiq/labiqApi";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	sample: Sample
}

const Sidebar: React.FC<SidebarProps> = ({ sample, activeTab, setActiveTab }) => {
	return (
		<div className="bg-gray-800 text-white w-64 min-h-full flex flex-col justify-between p-4">
			{/* top Section */}
			<div className="flex flex-col items-center mb-6">

				<span className="mt-2 font-medium">
					{sample.sampleID}
				</span>
			</div>

			<div className="flex-1">

				<button
					onClick={() => setActiveTab("Details")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Details" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Sample Details</span>
				</button>

				{JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Lab Management") && (
					<div>
						<button
							onClick={() => setActiveTab("Lab")}
							className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Lab" ? "bg-gray-700" : "hover:bg-gray-700"
								}`} style={{ width: '-webkit-fill-available' }}
						>
							<span className="ml-3">Lab</span>
						</button>

						<button
							onClick={() => setActiveTab("Other")}
							className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Other" ? "bg-gray-700" : "hover:bg-gray-700"
								}`} style={{ width: '-webkit-fill-available' }}
						>
							<span className="ml-3">Other</span>
						</button>


					</div>
				)}


				{/* this section isnt working at the moment, will need to re design the way it works when i have time
			
			{JSON.parse(sessionStorage.getItem("user") || "{}").roles?.some((role: any) => role.name === "Practitioner") && (
                <button onClick={() => getPatients()} className={`pb-2 ${activeTab === "Patient" ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"} border-b-2 font-medium`}>
                    Patient Management
                </button>
            )} */}
			</div>
		</div>
	);
};

export default Sidebar;
