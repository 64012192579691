import React, { useState, useEffect } from "react";
import { getSamplesPanels, getPanels, Panel } from "../../../../../api/labiq/geneCodesApi";

interface GeneCodesModalProps {
	sampleId: number;
	isActive: boolean;
	setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
	selectedPanels: number[];
	setSelectedPanels: React.Dispatch<React.SetStateAction<number[]>>;
}

const GeneCodesModal: React.FC<GeneCodesModalProps> = ({ sampleId, isActive, setIsActive, selectedPanels, setSelectedPanels }) => {
	const [panelList, setPanelList] = useState<Panel[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const panels = await getPanels();
				setPanelList(panels);

				const samplePanels = await getSamplesPanels(sampleId);
				setSelectedPanels(samplePanels.flat());
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [sampleId]);

	const setStatus = () => {
		setIsActive(!isActive);
	};

	const handleCheckboxChange = (panelId: number, isChecked: boolean) => {
		setSelectedPanels((prevSelectedPanels) =>
			isChecked ? [...prevSelectedPanels, panelId] : prevSelectedPanels.filter((id) => id !== panelId)
		);
	};

	const handleSelectAll = () => {
		if (selectedPanels.length === panelList.length) {
			setSelectedPanels([]);
		} else {
			setSelectedPanels(panelList.map(panel => panel.id_panels));
		}
	};




	return (
		<div className="grid grid-cols-1">
			<div className="p-6">
				<div className="pb-5">
					<label htmlFor="activation" className="block text-sm">
						Activation Status
					</label>
					<button onClick={setStatus} className={`${isActive ? "bg-blue-500" : "bg-gray-300"} relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-300`}>
						<span className={`${isActive ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-300`} />
					</button>
				</div>
				<div>
					<label htmlFor="categories" className="block text-sm pb-3">
						Available Categories
					</label>
					<button onClick={handleSelectAll} className="mb-2 bg-gray-300 text-gray-700 px-2 py-1 rounded">
						{selectedPanels.length === panelList.length ? "Deselect All" : "Select All"}
					</button>
					<div className="grid grid-cols-3">
						{panelList.map((panel) => (
							<div key={panel.id_panels} className="flex items-center mb-2">
								<input
									type="checkbox"
									id={panel.name_panel}
									value={panel.name_panel}
									checked={selectedPanels.includes(panel.id_panels)}
									onChange={(e) => handleCheckboxChange(panel.id_panels, e.target.checked)}
									className="mr-2"
								/>
								<label htmlFor={panel.name_panel} className="text-gray-700">
									{panel.name_panel}
								</label>
							</div>
						))}
					</div>
				</div>

			</div>
		</div>
	);
};

export default GeneCodesModal;
