import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	practitioner: Practitioner;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, practitioner }) => {

	return (
		<div className="bg-gray-800 text-white w-64 min-h-full flex flex-col justify-between p-4">
			{/* Profile Section */}
			<div className="flex flex-col items-center mb-6">
				<img
					className="w-40 h-40 rounded-full object-cover"
					src={practitioner.profile_picture || "/assets/images/default-profile.png"}
					alt={`${practitioner.first_name} ${practitioner.last_name}`}
				/>
				<span className="mt-2 font-medium">
					{practitioner.first_name} {practitioner.last_name}
				</span>
			</div>

			{/* Navigation Links */}
			<div className="flex-1">
				<button
					onClick={() => setActiveTab("Personal")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Personal" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Personal Info</span>
				</button>

				<button
					onClick={() => setActiveTab("Practitioner")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Practitioner" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Practitioner Info</span>
				</button>

				<button
					onClick={() => setActiveTab("Education")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Education" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Education</span>
				</button>

				<button
					onClick={() => setActiveTab("Availability")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Availability" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Availability & Billing</span>
				</button>





			</div>


		</div>
	);
};

export default Sidebar;
