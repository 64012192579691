import React from "react";
import { Sample, Batch } from "../../../../api/labiq/labiqApi";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	batch: Batch;
}

const Sidebar: React.FC<SidebarProps> = ({ batch, activeTab, setActiveTab }) => {
	return (
		<div className="bg-gray-800 text-white w-64 min-h-full flex flex-col justify-between p-4">
			{/* top Section */}
			<div className="flex flex-col items-center mb-6">

				<span className="mt-2 font-medium">
					{batch.batch_client_id}
				</span>
			</div>

			<div className="flex-1">
		{/* Batch details */}
				<button
					onClick={() => setActiveTab("Details")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Details" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Batch Details</span>
				</button>

				{/* Batch Samples */}
				<button
					onClick={() => setActiveTab("Samples")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Samples" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Batch Samples</span>
				</button>

			</div>
		</div>
	);
};

export default Sidebar;
