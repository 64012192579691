import React from "react";
import { User } from "../../../../api/admin/userApi";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	user: User;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, user }) => {

	return (
		<div className="bg-gray-800 text-white w-64 min-h-full flex flex-col justify-between p-4">
			{/* Profile Section */}
			<div className="flex flex-col items-center mb-6">
				
				<img
                    className="w-50 h-50 rounded-full object-cover"
                    src={user.profile_picture || "/assets/images/default-profile.png"}
                    alt={`${user.first_name} ${user.last_name}`}
                />
				<span className="mt-2 font-medium">
					{user.first_name} {user.last_name}
				</span>
			</div>

			{/* Navigation Links */}
			<div className="flex-1">
				<button
					onClick={() => setActiveTab("Personal")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Personal" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Personal Info</span>
				</button>

				<button
					onClick={() => setActiveTab("Company")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Company" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Company Info</span>
				</button>

				<button
					onClick={() => setActiveTab("Preferences")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Preferences" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Preferences</span>
				</button>

				<button
					onClick={() => setActiveTab("Roles")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Roles" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Roles</span>
				</button>

				<button
					onClick={() => setActiveTab("Subscriptions")}
					className={`flex items-center px-4 py-2 text-left rounded ${activeTab === "Subscriptions" ? "bg-gray-700" : "hover:bg-gray-700"
						}`} style={{ width: '-webkit-fill-available' }}
				>
					<span className="ml-3">Subscriptions</span>
				</button>

			</div>


		</div>
	);
};

export default Sidebar;
