// src/components/healthcare/practitioners/modalComponents/PractitionerInfoTab.tsx

import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";

interface AvailabilityTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

const AvailabilityTab: React.FC<AvailabilityTabProps> = ({ updatedPractitioner, setUpdatedPractitioner, }) => {
	const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

	// Handler for simple input changes
	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: value,
			},
		}));
	};

	// Handler for array inputs (comma-separated)
	const handleArrayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const arrayValues = value.split(",").map((item) => item.trim());
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: arrayValues,
			},
		}));
	};

	// Handler for availability schedule change
	const handleAvailabilityChange = (day: string, field: 'start' | 'end', value: string) => {
		setUpdatedPractitioner((prev) => {
			const prevSchedule = prev.practitioner?.availability_schedule || {};
			const daySchedule = prevSchedule[day] || {};
			const updatedDaySchedule = {
				...daySchedule,
				[field]: value,
			};
			const updatedSchedule = {
				...prevSchedule,
				[day]: updatedDaySchedule,
			};
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					availability_schedule: updatedSchedule,
				},
			};
		});
	};

	// Handler for billing information
	const handleBillingChange = (
		field: keyof BillingInformation,
		value: string
	) => {
		setUpdatedPractitioner((prev) => {
			const prevBilling = prev.practitioner?.billing_information || {};
			const updatedBilling = {
				...prevBilling,
				[field]: value,
			};
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					billing_information: updatedBilling,
				},
			};
		});
	};


	// Handler for malpractice insurance details
	const handleMalpracticeChange = (
		field: keyof MalpracticeInsuranceDetails,
		value: string
	) => {
		setUpdatedPractitioner((prev) => {
			const prevDetails = prev.practitioner?.malpractice_insurance_details || {};
			const updatedDetails = {
				...prevDetails,
				[field]: value,
			};
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					malpractice_insurance_details: updatedDetails,
				},
			};
		});
	};

	interface BillingInformation {
		account_number: string;
		billing_address: string;
	}


	interface MalpracticeInsuranceDetails {
		provider: string;
		policy_number: string;
		coverage_amount: string;
	}


	return (
		<div className="grid grid-cols-2 gap-6 p-6">


			{/* Column 1 */}
			<div>

				{/* Practicing Locations */}
				<div className="mb-4">
					<label
						htmlFor="practicing_locations"
						className="block text-sm font-medium text-gray-700"
					>
						Practicing Locations
					</label>
					<input
						type="text"
						id="practicing_locations"
						name="practicing_locations"
						value={updatedPractitioner.practitioner?.practicing_locations?.join(", ") || ""}
						onChange={handleArrayChange}
						placeholder="e.g., New York, Los Angeles"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
					/>
				</div>

				{/* Availability Schedule */}
				<div className="mb-4">
					<label className="block text-sm font-medium text-gray-700">
						Availability Schedule
					</label>
					{daysOfWeek.map((day) => {
						const daySchedule =
							updatedPractitioner.practitioner?.availability_schedule?.[day] || {};
						return (
							<div key={day} className="mb-2">
								<div className="font-semibold">{day}</div>
								<div className="flex space-x-2">
									<input
										type="time"
										value={daySchedule.start || ""}
										onChange={(e) =>
											handleAvailabilityChange(day, "start", e.target.value)
										}
										className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
									/>
									<span>to</span>
									<input
										type="time"
										value={daySchedule.end || ""}
										onChange={(e) =>
											handleAvailabilityChange(day, "end", e.target.value)
										}
										className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
									/>
								</div>
							</div>
						);
					})}
				</div>





			</div>

			<div>
				{/* Consultation Fee */}
				<div className="mb-4">
					<label
						htmlFor="consultation_fee"
						className="block text-sm font-medium text-gray-700"
					>
						Consultation Fee ($)
					</label>
					<input
						type="number"
						id="consultation_fee"
						name="consultation_fee"
						value={updatedPractitioner.practitioner?.consultation_fee || 0}
						onChange={handleInputChange}
						placeholder="Enter consultation fee"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
						min="0"
						step="0.01"
					/>
				</div>

				{/* Billing Information */}
				<div className="mb-4">
					<label className="block text-sm font-medium text-gray-700">
						Billing Information
					</label>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">
							Account Number
						</label>
						<input
							type="text"
							value={
								updatedPractitioner.practitioner?.billing_information?.account_number ||
								""
							}
							onChange={(e) =>
								handleBillingChange("account_number", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">
							Billing Address
						</label>
						<input
							type="text"
							value={
								updatedPractitioner.practitioner?.billing_information
									?.billing_address || ""
							}
							onChange={(e) =>
								handleBillingChange("billing_address", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
				</div>

				{/* Malpractice Insurance Details */}
				<div className="mb-4">
					<label className="block text-sm font-medium text-gray-700">
						Insurance Details
					</label>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">
							Provider
						</label>
						<input
							type="text"
							value={
								updatedPractitioner.practitioner?.malpractice_insurance_details
									?.provider || ""
							}
							onChange={(e) =>
								handleMalpracticeChange("provider", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">
							Policy Number
						</label>
						<input
							type="text"
							value={
								updatedPractitioner.practitioner?.malpractice_insurance_details
									?.policy_number || ""
							}
							onChange={(e) =>
								handleMalpracticeChange("policy_number", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
					<div className="mt-2">
						<label className="block text-sm font-medium text-gray-700">
							Coverage Amount
						</label>
						<input
							type="text"
							value={
								updatedPractitioner.practitioner?.malpractice_insurance_details
									?.coverage_amount || ""
							}
							onChange={(e) =>
								handleMalpracticeChange("coverage_amount", e.target.value)
							}
							className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						/>
					</div>
				</div>
			</div>

		</div>
	);
};

export default AvailabilityTab;
