import React, { useState } from "react";
import { User, assignUserRole, removeUserRole } from "../../../../api/admin/userApi";
import { Role } from "../../../../api/admin/rolesApi";
import {updatePractitioner, Practitioner, defaultPractitionerInfo } from "../../../../api/healthcare/practitionerApi";

interface RolesTabProps {
    updatedUser: User;
    setUpdatedUser: React.Dispatch<React.SetStateAction<User>>;
    roles: Role[];
}

const RolesTab: React.FC<RolesTabProps> = ({ updatedUser, setUpdatedUser, roles }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleRoleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setIsSubmitting(true);
	
		const isChecked = e.target.checked;

		try {
			let updatedUserData = { ...updatedUser };

			if (isChecked) {
				// Assign the role
				const selectedRole = roles?.find((role) => role.name === e.target.name);
				if (selectedRole) {
					await assignUserRole(updatedUser.id, selectedRole.name);
					updatedUserData.roles = [...updatedUser.roles, selectedRole];

					//if the role selected is a practitioner then send an update practitioner api call to create the practitioner info for the user in the db, to avoid errors down the line of code 
					// (will probs do tthis for more roles moving forward when more data relations are defined )
					if (selectedRole.name === "Practitioner") {
						// Prepare the updated practitioner object
						let updatedPractitioner:Practitioner = updatedUser as Practitioner;
						updatedPractitioner = {
							...updatedUser,
							practitioner: updatedPractitioner?.practitioner || defaultPractitionerInfo,
						};

						// Update practitioner information
						await updatePractitioner(updatedUser.id, updatedPractitioner);
					}
				}
			} else {
				// Remove the role
				const updatedRoles = updatedUser.roles.filter((role) => role.name !== e.target.name);
				await removeUserRole(updatedUser.id, e.target.name);
				updatedUserData.roles = updatedRoles;
			}
			setUpdatedUser(updatedUserData);
		} catch (err) {
			console.error("Error updating user:", err);
			setError("Failed to update user.");
		} finally {
			setIsSubmitting(false);
		}
	};
	

    return (
        <div className="grid grid-cols-1">
            {roles?.map((role) => (
                <div key={role.id} className="">
                    <input
                        type="checkbox"
                        id={role.id.toString()}
                        name={role.name}
                        onChange={handleRoleChange}
                        checked={updatedUser.roles.some((userRole) => userRole.name === role.name)}
                    />
                    <label className="p-4" htmlFor={role.id.toString()}>
                        {role.name} ({role.guard_name})
                    </label>
                </div>
            ))}
            {error && <p className="text-red-500">{error}</p>}
        </div>
    );
};

export default RolesTab;
