import React from 'react';
import { Company } from '../../../api/admin/companyApi';


interface CompantTableProps {
    companies: Company[];
    onEditClick: (company: Company) => void;
}

const CompanyTable: React.FC<CompantTableProps> = ({ companies, onEditClick }) => {

    if (!companies || companies.length === 0) {
        return <p>No companies available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Address</th>
                        <th className="py-2 px-4 border-b">Phone</th>
                        <th className="py-2 px-4 border-b">Email</th>
                    </tr>
                </thead>
                <tbody>
                    {companies.map((company) => (
                        <tr
                            key={company.id}
                            className="cursor-pointer hover:bg-gray-100"
                            onClick={() => onEditClick(company)}
                        >
                            <td className="py-2 px-4 border-b">{company.name}</td>
                            <td className="py-2 px-4 border-b">{company.address}</td>
                            <td className="py-2 px-4 border-b">{company.phone}</td>
                            <td className="py-2 px-4 border-b">{company.email}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CompanyTable;
