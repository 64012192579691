import React, { useState } from "react";
import { uploadFile, FileMetadata } from "../../../../api/fileServiceApi";

interface UploadFileModalProps {
	patientId: number;
	onClose: () => void;
	onFileUploaded: (file: FileMetadata) => void;
}

const UploadFileModal: React.FC<UploadFileModalProps> = ({
	patientId,
	onClose,
	onFileUploaded,
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [isUploading, setIsUploading] = useState<boolean>(false);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
		}
	};

	const handleUpload = async () => {
		if (!file) return;
		setIsUploading(true);
		try {
			const response = await uploadFile(file, patientId);
			onFileUploaded(response.data);
		} catch (error) {
			// Handle error (e.g., display a message)
		} finally {
			setIsUploading(false);
		}
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white p-6 rounded shadow-md w-1/3">
				<h2 className="text-xl font-bold mb-4">Upload File</h2>
				<input
					type="file"
					onChange={handleFileChange}
					className="mb-4"
				/>

				<div className="flex justify-end">
					<button
						onClick={onClose}
						className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
					>
						Cancel
					</button>
					<button
						onClick={handleUpload}
						className="bg-blue-500 text-white px-4 py-2 rounded"
						disabled={isUploading}
					>
						{isUploading ? "Uploading..." : "Upload"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default UploadFileModal;
