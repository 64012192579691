import React from 'react';
import { User } from '../../../api/admin/userApi';

interface UserTableProps {
	users: User[];
	onEditClick: (user: User) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, onEditClick }) => {
	// Add a check to handle empty or undefined users
	if (!users || users.length === 0) {
		return <p>No users available.</p>;
	}

	return (
		<div className="overflow-x-auto">
			<table className="min-w-full bg-white">
				<thead>
					<tr>
						<th className="py-2 px-4 border-b">ID</th>
						<th className="py-2 px-4 border-b">Username</th>
						<th className="py-2 px-4 border-b">Email</th>
						<th className="py-2 px-4 border-b">First Name</th>
						<th className="py-2 px-4 border-b">Last Name</th>
						<th className="py-2 px-4 border-b">Is Active</th>

					</tr>
				</thead>
				<tbody>
					{users.map((user) => (
						<tr
							key={user.id}
							className="cursor-pointer hover:bg-gray-100"
							onClick={() => onEditClick(user)}
						>
							<td className="py-2 px-4 border-b">{user.id}</td>
							<td className="py-2 px-4 border-b">{user.username}</td>
							<td className="py-2 px-4 border-b">{user.email}</td>
							<td className="py-2 px-4 border-b">{user.first_name}</td>
							<td className="py-2 px-4 border-b">{user.last_name}</td>
							{user.is_active ? (
								<span className="text-green-500 font-bold">✔</span>
							) : (
								<span className="text-red-500 font-bold">✖</span>
							)}

						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default UserTable;
