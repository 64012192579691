import React from "react";
import {
    useReactTable,
    ColumnDef,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
} from "@tanstack/react-table";
import { Sample } from "../../../api/labiq/labiqApi";

interface SampleTableProps {
    samples: Sample[];
    selectedSamples: number[];
    setSelectedSamples: React.Dispatch<React.SetStateAction<number[]>>;
    onSort: (column: string) => void;
    sortBy: string;
    sortOrder: string;
    setSelectedSampleClientId: React.Dispatch<React.SetStateAction<string[]>>;
    selectedSampleClientId: string[];
    onEditSample: (sample: Sample) => void; // New prop
}

const SampleTable: React.FC<SampleTableProps> = ({
    samples,
    selectedSamples,
    setSelectedSamples,
    onSort,
    sortBy,
    sortOrder,
    setSelectedSampleClientId,
    selectedSampleClientId,
    onEditSample,
}) => {
    const columns = React.useMemo<ColumnDef<Sample>[]>(
        () => [
            {
                id: "select",
                header: ({ table }) => (
                    <input
                        type="checkbox"
                        checked={selectedSamples.length === samples.length}
                        onChange={() => {
                            if (selectedSamples.length === samples.length) {
                                setSelectedSamples([]);
                                setSelectedSampleClientId([]);
                            } else {
                                setSelectedSamples(samples.map((sample) => sample.id));
                                setSelectedSampleClientId(
                                    samples.map((sample) => sample.sampleOA?.client_sampleID || "")
                                );
                            }
                        }}
                        aria-label="Select all samples"
                        onClick={(e) => e.stopPropagation()}
                    />
                ),
                cell: ({ row }) => (
                    <input
                        type="checkbox"
                        checked={selectedSamples.includes(row.original.id)}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleSelectSample(row.original);
                        }}
                        aria-label={`Select sample ${row.original.sampleID}`}
                    />
                ),
            },
            { accessorKey: "id", header: "ID" },
            {
                id: "sampleID",
                header: "Sample ID",
                accessorFn: (sample) => sample.sampleID || "",
            },
            {
                id: "batch_id",
                header: "Batch ID",
                accessorFn: (sample) => sample.sampleOA?.batch?.batch_client_id || "",
            },
            { accessorKey: "client", header: "Client" },
            {
                id: "sampleType",
                header: "Collection",
                accessorFn: (sample) => sample.sampleOA?.sampleType || "",
            },
            {
                id: "QC_date",
                header: "QC Date",
                accessorFn: (sample) => sample.sampleOA?.QC_date || "",
            },
            {
                id: "concentration",
                header: "ng/μl",
                accessorFn: (sample) => sample.sampleOA?.concentration || "",
            },
            {
                id: "OD",
                header: "260/280",
                accessorFn: (sample) => sample.sampleOA?.OD || "",
            },
            {
                id: "OD2",
                header: "260/230",
                accessorFn: (sample) => sample.sampleOA?.OD2 || "",
            },
            {
                id: "eProtocol",
                header: "eP",
                accessorFn: (sample) => sample.sampleOA?.eProtocol || "",
            },
            {
                id: "status",
                header: "Status",
                accessorFn: (sample) => sample.sampleOA?.status || "",
            },
            {
                id: "comments",
                header: "Comments",
                accessorFn: (sample) => sample.sampleOA?.comments || "",
            },
        ],
        [selectedSamples, samples, setSelectedSamples, setSelectedSampleClientId]
    );

    const [sorting, setSorting] = React.useState<SortingState>([]);

    const table = useReactTable({
        data: samples,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: { sorting },
        onSortingChange: setSorting,
    });

    const handleSelectSample = (sample: Sample) => {
        const sampleId = sample.id;
        const sampleClientId = sample.sampleOA?.client_sampleID || "";
        if (selectedSamples.includes(sampleId)) {
            setSelectedSamples(selectedSamples.filter((id) => id !== sampleId));
            setSelectedSampleClientId(
                selectedSampleClientId.filter((id) => id !== sampleClientId)
            );
        } else {
            setSelectedSamples([...selectedSamples, sampleId]);
            setSelectedSampleClientId([...selectedSampleClientId, sampleClientId]);
        }
    };

    return (
        <table className="min-w-full bg-white border">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                className="py-2 px-3 border-b text-left cursor-pointer"
                                onClick={() => onSort(header.column.id)}
                            >
                                <>
                                    {header.column.columnDef.header as React.ReactNode}
                                    {sortBy === header.column.id ? (
                                        <span className="ml-2">
                                            {sortOrder === "asc" ? "🔼" : "🔽"}
                                        </span>
                                    ) : null}
                                </>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                            if (selectedSamples.length === 0) {
                                onEditSample(row.original);
                            }
                        }}
                    >
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="py-2 px-3 border-b">
                                {cell.column.id === "select" ? (
                                    <input
                                        type="checkbox"
                                        checked={selectedSamples.includes(row.original.id)}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            handleSelectSample(row.original);
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        aria-label={`Select sample ${row.original.sampleID}`}
                                    />
                                ) : (
                                    cell.renderValue() as React.ReactNode
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SampleTable;
