import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

import { Dialog, DialogTitle, Transition } from "@headlessui/react";
import { activatePatient, deactivatePatient, updatePatient, Patient, createPatient, MygeneProgramStatus, fetchPatientProgramStatus, updatePatientProgramStatus, createPatientProgramStatus } from "../../../api/healthcare/patientApi";
import NavButtons from "./modalComponents/Sidebar";
import ProfileTab from "./modalComponents/ProfileTab";
import SampleTab from "./modalComponents/SampleTab";
import MygeneProgramStatusTab from "./modalComponents/MygeneProgramStatusTab";
import FilesTab from "./modalComponents/FilesTab";
import PractitionersTab from "./modalComponents/PractitionersTab";

interface EditPatientModalProps {
	isOpen: boolean;
	onClose: () => void;
	patient: Patient;
	onPatientUpdated: () => void;
}

const EditPatientModal: React.FC<EditPatientModalProps> = ({ isOpen, onClose, patient, onPatientUpdated }) => {
	const [updatedPatient, setUpdatedPatient] = useState<Patient>({ ...patient });
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<any[]>([])
	const [activeTab, setActiveTab] = useState("Personal");
	const [status, setStatus] = useState<MygeneProgramStatus>();

	useEffect(() => {
		setUpdatedPatient({ ...patient });
		if (patient.company === "") {
			const userCompany = JSON.parse(sessionStorage.getItem("user") || "{}").company;
			setUpdatedPatient((prevPatient) => ({
				...prevPatient,
				company: userCompany,
				company_code: userCompany,
			}));
		}

		if (patient.id !== 0) {
			const fetchStatus = async () => {
				const response = await fetchPatientProgramStatus(patient.id);
				setStatus(response.data);
			};
			fetchStatus();
		} else {
			setStatus({
				user_id: 0,
				program_elected: "NA",
				swab_kit_sent: false,
				swab_kit_returned: false,
				questionnaire_sent: false,
				questionnaire_completed: false,
				questionnaire_sent_admin: false,
				pedigree_chart_filled: false,
				pathology_consult_completed: false,
				pathology_forms_sent: false,
				initial_blood_test_completed: false,
				genetic_results_ready: false,
				consult_90_minute_booked: false,
				consult_90_minute_completed: false,
				date_90_minute_consult: null,
				initial_blood_consult_completed: false,
				date_initial_blood_consult: null,
				follow_up_blood_test_due: null,
				follow_up_blood_test_completed: false,
				follow_up_blood_consult_completed: false,
			});
		}
	}, [patient]);

	// Activate or deactivate patient account
	const onActiveClick = async () => {
		setIsSubmitting(true);
		try {
			let updatedPatientStatus = { ...updatedPatient };
			if (!updatedPatient.is_active) {
				await activatePatient(patient.id);
				updatedPatientStatus.is_active = true;
			} else {
				await deactivatePatient(patient.id);
				updatedPatientStatus.is_active = false;
			}
			setUpdatedPatient(updatedPatientStatus);
			onPatientUpdated();
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	// Update patient information
	const updatePatientInfo = async () => {
		setIsSubmitting(true);
		try {
			if (!updatedPatient.first_name || !updatedPatient.last_name || !updatedPatient.email || !updatedPatient.username) {
				setErrorMessages(["First name, last name, email, and username are required."]);
				setIsSubmitting(false);
				return;
			}

			if (patient.id !== 0) {
				await updatePatient(patient.id, updatedPatient);
				await updatePatientProgramStatus(patient.id, status as MygeneProgramStatus);
			} else {
				const newPatient = await createPatient(updatedPatient);
				setStatus((prevStatus) => {
					const updatedStatus = {
						...prevStatus!,
						user_id: newPatient.id,
					};
					createPatientProgramStatus(updatedStatus);
					return updatedStatus;
				});
			}
			onPatientUpdated();
		} catch (error:unknown) {
			if (axios.isAxiosError(error)) {
				if (error.response?.data?.errors) {
					const errorMessages = Object.entries(error.response.data.errors)
						.flatMap(([field, messages]) => messages);
					setErrorMessages(errorMessages); // State to store an array of error messages
				} else if (error.response?.data?.message) {
					setErrorMessages([error.response.data.message]); // Fallback to general error message
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			} else if (error instanceof Error) {
				setErrorMessages([error.message || "An unknown error occurred."]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
				<div className="flex items-center justify-center min-h-screen px-4">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black opacity-60" />
					</Transition.Child>

					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="bg-white rounded z-20 w-[80vw] h-[80vh] flex">
							{/* Sidebar for NavButtons */}
							<div >
								<NavButtons activeTab={activeTab} setActiveTab={setActiveTab} patient={updatedPatient} />
							</div>

							{/* Main Content */}
							<div className="pl-4 flex flex-col p-6" style={{ width: '-webkit-fill-available' }}>

								<div className="flex-1 overflow-y-auto">
									{/* Render tabs based on activeTab */}
									{activeTab === "Personal" && (
										<ProfileTab updatedPatient={updatedPatient} setUpdatedPatient={setUpdatedPatient} />
									)}
									{activeTab === "Samples" && (
										<SampleTab patient={patient} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} setErrorMessages={setErrorMessages} />
									)}
									{activeTab === "MygeneProgramStatus" && status && (
										<MygeneProgramStatusTab status={status} setStatus={setStatus} />
									)}
									{activeTab === "Files" && (
										<FilesTab patient={patient} setErrorMessages={setErrorMessages} />
									)}
									{activeTab === "Practitioners" && (
										<PractitionersTab patientId={patient.id} />
									)}
								</div>

								{/* Display errors to the user */}
								{errorMessages.length > 0 && (
									<div className="error-container">
										{errorMessages.map((msg, idx) => (
											<div key={idx} className="text-red-500">
												{msg}
											</div>
										))}
									</div>
								)}
								<div className="flex justify-end space-x-2 mt-6">
									{activeTab === "Personal" && (
										<button
											type="button"
											onClick={onActiveClick}
											className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
										>
											{updatedPatient.is_active ? "Deactivate" : "Activate"}
										</button>
									)}

									<button
										type="button"
										onClick={onClose}
										className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
									>
										Cancel
									</button>
									<button
										onClick={updatePatientInfo}
										disabled={isSubmitting}
										className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
											}`}
									>
										{isSubmitting ? (patient.id !== 0 ? "Saving..." : "Creating...") : "Save Changes"}
									</button>
								</div>
							</div>
						</div>

					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default EditPatientModal;
